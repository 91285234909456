import React from "react";
import loader from "../../images/loader.png";
import "./index.sass"
function Loader() {
  return (
    <div className="loaderContainer">
      <img className="loaderItem" src={loader} alt="Loading" />
    </div>
  );
}

export default Loader;