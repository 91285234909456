import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import StyledButton from "../../components/Button/StyledButton";
import {
  getEmailOtp,
  postOtpForEmail,
  getEmpDetails,
} from "../../services/actionSlice";
import { AppDispatch } from "../../storage/store";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import OtpInput from "react-otp-input";
import { CleaningServices } from "@mui/icons-material";
interface Props {
  setLandingPage: any;
  setEmpId: any;
  empId: any;
  setCustomerEmail: any;
  customerEmail: any;
  setOtpEmail: any;
  otpEmail: any;
  setOtpEmailVerified: any;
  setAgentId: any;
  agentId: any;
  setCustomerNumber: any;
  customerNumber: any;
}

const LandingPage: React.FC<Props> = ({
  setLandingPage,
  setEmpId,
  empId,
  setCustomerEmail,
  customerEmail,
  setOtpEmail,
  otpEmail,
  setOtpEmailVerified,
  setAgentId,
  agentId,
  setCustomerNumber,
  customerNumber,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );
  const [edit, setEdit] = useState<boolean>(true);

  const [seconds, setSecond] = useState<any>("");
  const [otpMode, setOtpMode] = useState<any>("email");
  const verifyEmail = () => {
    setEdit(false);
    if (
      otpMode === "email" &&
      centreData.custom_user_requirement_for === "RSTAR"
    ) {
      dispatch(getEmailOtp(`?email=${customerEmail}&agent_code=${agentId}`));
    } else if (
      otpMode === "mobile" &&
      centreData.custom_user_requirement_for === "RSTAR"
    ) {
      dispatch(getEmailOtp(`?mobile=${customerNumber}&agent_code=${agentId}`));
    } else {
      dispatch(getEmailOtp(`?email=${customerEmail}&emp_id=${empId}`));
    }
  };
  useEffect(() => {
    if (String(otpEmail).length === 6) {
      if (otpMode === "email") {
        dispatch(postOtpForEmail(`?otp=${otpEmail}&email=${customerEmail}`));
      } else {
        dispatch(postOtpForEmail(`?otp=${otpEmail}&mobile=${customerNumber}`));
      }
    }
  }, [otpEmail]);
  useEffect(() => {
    if (verifiedOtpEmail.token) {
      setOtpEmailVerified(true);
      setLandingPage(false);
      dispatch(getEmpDetails(verifiedOtpEmail.token));
    }
  }, [verifiedOtpEmail]);
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  return (
    <Grid className="content" id="collectionSlot">
      <Grid container spacing={2} className="dialogContent">
        <Grid item xs={12} textAlign="center">
          <img
            src={centreData?.logo_image}
            alt="LOGO"
            style={{
              padding: "5px 0px 0px",
              margin: "70px auto",
              width: "250px",
              height: "auto",
            }}
          />
        </Grid>

        {centreData.custom_user_requirement_for === "RSTAR" ? (
          <>
            <Grid item xs={12} textAlign="center">
              <TextField
                className="input"
                variant="outlined"
                label="Agent ID"
                placeholder="Enter your agent ID"
                required
                type="text"
                fullWidth
                value={agentId}
                onChange={(e) => setAgentId(String(e.target.value).trim())}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Login via
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={otpMode}
                  onChange={(e) => setOtpMode(e.target.value)}
                  
                >
                  <FormControlLabel
                    value="email"
                    control={<Radio />}
                    label="Email"
                    disabled={!edit}
                  />
                  <FormControlLabel
                    value="mobile"
                    control={<Radio />}
                    label="Mobile"
                    disabled={!edit}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} textAlign="center">
            <TextField
              className="input"
              variant="outlined"
              label="Employee ID"
              placeholder="Enter your employee ID"
              required
              type="text"
              fullWidth
              value={empId}
              onChange={(e) => setEmpId(String(e.target.value).trim())}
            />
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          {otpMode === "email" ? (
            <TextField
              className="input"
              variant="outlined"
              label="Email Address"
              placeholder="Enter your email address"
              required
              disabled={!edit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Button onClick={() => setEdit(true)}>Edit</Button>
                  </InputAdornment>
                ),
              }}
              type="text"
              fullWidth
              value={customerEmail}
              onChange={(e) => setCustomerEmail(String(e.target.value).trim())}
            />
          ) : (
            <TextField
              key={String(customerNumber)?.length === 10 ? "0" : "1"}
              type="number"
              label="Mobile Number"
              disabled={!edit}
              value={customerNumber}
              variant="outlined"
              onWheel={(e: any) => e.target.blur()}
              onInput={(e: any) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              fullWidth
              required
              onChange={(e) => setCustomerNumber(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Button onClick={() => setEdit(true)}>Edit</Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="left">
          {emailSent?.message === "Otp sent!" && !edit ? (
            <>
              <Grid item xs={12}>
                <Typography component="p" color="green">
                  OTP has been shared on your {otpMode}.{" "}
                </Typography>
                <Typography component="p">Enter OTP</Typography>
              </Grid>
              <Grid item xs={12}>
                <OtpInput
                  value={otpEmail}
                  isInputNum={true}
                  numInputs={6}
                  onChange={(e: any) => setOtpEmail(e)}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 0.4rem",
                    fontSize: "14px",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                    background: "#e7e7e7",
                    color: "#000",
                  }}
                />
              </Grid>
              {verifiedOtpEmail?.errors ? (
                <Grid item xs={12}>
                  <Typography component="p" color="red">
                    <CancelOutlinedIcon
                      style={{
                        color: "red",
                        fontSize: 18,
                        marginBottom: "-3px",
                      }}
                    />{" "}
                    Incorrect OTP!
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                {seconds !== 0 ? (
                  <p>
                    Resend OTP in
                    <span style={{ color: "#5866E0" }}>
                      {" "}
                      00:{seconds < 10 ? "0" + seconds : seconds}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span
                      style={{ color: "#5866E0", cursor: "pointer" }}
                      onClick={() => {
                        verifyEmail();
                        setSecond(30);
                      }}
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </Grid>
            </>
          ) : (
            <StyledButton
              disabled={
                otpMode === "email" &&
                centreData.custom_user_requirement_for === "RSTAR"
                  ? !customerEmail || !agentId
                  : otpMode === "mobile" &&
                    centreData.custom_user_requirement_for === "RSTAR"
                  ? !customerNumber && !agentId
                  : !customerEmail || !empId
              }
              onClick={() => {
                verifyEmail();
                setSecond(30);
              }}
            >
              Verify {otpMode}
            </StyledButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
