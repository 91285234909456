import React from "react";
import { Container, Grid, TextField } from "@mui/material";
import StyledButton from "../Button/StyledButton";

export default function UpdateEmail(props: any) {
  const { submitEmail, bookingData, customerEmail,setCustomerEmail,loading } = props;
 
  return (
    <Container className="dialogContent2">
          <form style={{ width: "100%",margin:"15px 0px" }} onSubmit={submitEmail}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <b>Email your Reports</b>
              </Grid>
              <Grid item xs={12}>
                <TextField
                id={String(bookingData?.pk)}
                  key={bookingData?.customer_email}
                  className="input"
     
                  variant="outlined"
                  label="Enter your email address"
                  required
                  type="email"
                  fullWidth
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledButton disabled={loading} type="submit">
                  SUBMIT
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Container>
  );
}
