import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import "./index.sass";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {  useSelector } from "react-redux";
interface Props {
  name: string;
  age: any;
  gender: string;
  packages: Array<any>;
  member: number;
  variant: string;
  onClick: any;
}

const PatientInfo: React.FC<Props> = ({
  name,
  age,
  gender,
  packages,
  member,
  variant,
  onClick,
}) => {
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  return variant === "outlined" ? (
    <div className="borderBox1" onClick={onClick}>
      <label className="topLabel">Member {member}</label>
      <Grid container>
        <Grid item xs={1}>
          <AccountCircleRoundedIcon
            style={
              gender === "female" ? { color: "#E7298A" } : { color: "#009688" }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography component="p">{name}</Typography>
        </Grid>
        <Grid item xs={5} textAlign="right">
          <Typography component="p">
            <sub>
              {age} Years | {gender}
            </sub>
          </Typography>
        </Grid>
        {packages &&
          packages.length > 0 &&
          packages.map((val: any, index: any) => {
            return (
              <>
                {" "}
                <Grid item xs={9}>
                  <Typography component="p" className="p14">
                    {index + 1}. {val.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="right">
                  {
                    centreData.show_package_golive&&
                    <Typography component="p" className="p14">
                    ₹{" "}
                    {val?.package_center_prices
                      ? val?.package_center_prices?.city_offer_price
                        ? val?.package_center_prices?.city_offer_price
                        : val?.package_center_prices?.offer_price
                      : val.offer_price}
                  </Typography>
                  }
               
                </Grid>
              </>
            );
          })}
      </Grid>
    </div>
  ) : (
    <Card sx={{ borderRadius: "16px", m: 1 }}>
      <Grid container spacing={2} className="dialogContent">
        <Grid item xs={1}>
          <AccountCircleRoundedIcon
            style={
              gender === "female" ? { color: "#E7298A" } : { color: "#009688" }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography component="p">{name}</Typography>
        </Grid>
        <Grid item xs={5} textAlign="right">
          <Typography component="p">
            <sub>
              {age} Years | {gender}
            </sub>
          </Typography>
        </Grid>
        {packages &&
          packages.length > 0 &&
          packages.map((val: any, index: any) => {
            return (
              <>
                {" "}
                <Grid item xs={9}>
                  <Typography component="p" className="p14">
                    {index + 1}. {val.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="right">
                  {/* <Typography component="p" className="p14">
                    ₹{" "}
                    {val?.offer_price
                      ? val?.offer_price
                      : val?.package_center_prices?.city_offer_price
                      ? val?.package_center_prices?.city_offer_price
                      : val?.package_center_prices?.offer_price}
                  </Typography> */}
                </Grid>
              </>
            );
          })}
      </Grid>
    </Card>
  );
};

export default PatientInfo;
