import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import HomeIcon from '@mui/icons-material/Home';
import "./index.css";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
const useStyles = makeStyles({
  HeaderStyle: {
    background: "#FFFFFF",
  },
});
export default function Header(props: any) {
  const { isFilter, setOpenFilter, addressInput, setLocationOpen, isSupport,setOpenSlotModal,setOpenCouponModal ,showHome} =
    props;
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const classes = useStyles();
  const openWhatsapp = () => {
    window.open(
      `https://wa.me/${
        centreData?.support_contact&&centreData?.support_enabled_using==="CLIENT" ? centreData.support_contact : 918700163152
      }`,
      "_blank"
    );
  };
  const openCall = () => {
    window.open(
      `tel:${
        centreData?.support_contact&&centreData?.support_enabled_using==="CLIENT" ? centreData.support_contact : 919654677563
      }`
    );
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className={classes.HeaderStyle}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={centreData?.logo_image}
              alt="LOGO"
              style={{
                padding: "5px 0px 0px",
                maxHeight: "44px",
                maxWidth: "200px",
              }}
            />
          </Typography>
          {isFilter === true ? (
            <TuneOutlinedIcon
              style={{
                color: "grey",
                background: "#EEEEEE",
                padding: 6,
                borderRadius: "50%",
              }}
              onClick={() => setOpenFilter(true)}
            />
          ) : addressInput ? (
            <Chip
              icon={<PlaceOutlinedIcon style={{ fontSize: 20 }} />}
              label={String(addressInput).slice(0, 13) + "..."}
              onClick={() => setLocationOpen(true)}
            />
          ) : isSupport === true ? (
            <Chip
              icon={
                centreData?.support_medium === "WHATSAP" ? (
                  <WhatsAppIcon style={{ fontSize: 20, color: "#25d366" }} />
                ) : (
                  <CallRoundedIcon style={{ fontSize: 20, color: "#2584ff" }} />
                )
              }
              label={ centreData?.support_medium === "WHATSAP"?"WhatsApp Us":"Call Us"}
              onClick={() => centreData?.support_medium === "WHATSAP"?openWhatsapp():openCall()}
            />
          ):(centreData.custom_user_requirement_for === "ACCENTURE"||centreData.custom_user_requirement_for === "BOB")&&showHome?(
            <Chip
            icon={
             < HomeIcon />
            }
            label={"Home"}
            onClick={() =>{setOpenSlotModal(false);  setOpenCouponModal(false); }}
          />
          )
           : (
            ""
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
