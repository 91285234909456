import { css } from 'styled-components';

export const buttonStyles = css`
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none;
  border-radius: 10px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  background:#E06358;
  color: #ffffff;
  width:100%;
  height:45px;
  border-width:0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  &:active {
    background: #d25449;
    color: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 7%), 0px 1px 3px 0px rgb(0 0 0 / 6%);
  }
`;
export const buttonStylesDisabeld = css`
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none;
  border-radius: 10px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  background:#EEEEEE;
  color: #000;
  width:100%;
  height:45px;
  border-width:0px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;
export const buttonStylesLight = css`
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none;
  border-radius: 10px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  background:#fff;
  color: #E06358;
  width:100%;
  border:1px solid #E06358;
  height:45px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  &:active {
    background: #00000005;
    color: #E06358;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 7%), 0px 1px 3px 0px rgb(0 0 0 / 6%);
  }
`;
export const buttonStylesLightDisabled = css`
  display: inline-block;
  padding: 13px 15px;
  text-decoration: none;
  border-radius: 10px;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  background:#EEEEEE;
  color: #000;
  width:100%;
  border:1px solid #000;
  height:45px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;