type ApiData = {
    api?: string;
    api1?: string;
}

const Prefix: ApiData = {
    api: 'api/v1',
    api1:'api'
};

export default Prefix;