import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import BillPdf from "../../components/Bill/billPdf";
import TimelineDot from "@mui/lab/TimelineDot";

import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Checkbox,
  Card,
  Box,
  Typography,
  Container,
  FormControlLabel,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import moment from "moment";
import Header from "../../components/Header";
import orderBooked from "../../images/OrderBooked.svg";
import StyledButton from "../../components/Button/StyledButton";
import phlebo from "../../images/PhleboAssigned.svg";
import phleboStarted from "../../images/PhleboStarted.svg";
import vac from "../../images/vac.svg";
import temp from "../../images/temp.svg";
import hyg from "../../images/hyg.svg";
import SampleCollected from "../../images/SampleCollected.svg";
import SampleRecieved from "../../images/SampleRecieved.svg";
import Report from "../../images/Groupreport.svg";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
import Rating from "@mui/material/Rating";
import Loader from "../../components/Loader";
import { submitReview, updateBookingEmail } from "../../services/actionSlice";
import { AppDispatch } from "../../storage/store";
import "./index.sass";
import UpdateEmail from "../../components/Email";

interface Props {
  setOpenTracking: any;
  bookingData: any;
  loading: any;
  setReschedule: any;
  reschedule: any;
  setOpenSlotModal: any;
  openSlotModal: any;
  setCollectionDate: any;
  setCollectionSlot: any;
  setLatitude: any;
  setLongitude: any;
  setCancel: any;
  partnerKey: any;
  setViewOrderSummary: any;
  setOpenCouponModal: any;
  areaServicable: any;
  setOpenCancel: any;
}

const TrackStatus: React.FC<Props> = ({
  setOpenTracking,
  bookingData,
  loading,
  setReschedule,
  reschedule,
  setOpenSlotModal,
  openSlotModal,
  setCollectionDate,
  setCollectionSlot,
  setLatitude,
  setLongitude,
  setCancel,
  partnerKey,
  setViewOrderSummary,
  setOpenCouponModal,
  areaServicable,
  setOpenCancel,
}) => {
  const [rating, setRating] = useState<any>("");
  const dispatch = useDispatch<AppDispatch>();
  const [selectedProblems, setSelectedProblems] = useState<any>([]);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<any>(
    bookingData?.feedback_submitted
  );
  const [feedbackOpen, setFeedbackOpen] = useState<any>(false);
  const [billOpen, setBillOpen] = useState<any>(false);
  const [emailOpen, setEmailOpen] = useState<any>(false);
  const [reportFor, setReportFor] = useState<any>("");
  const [isGood, setIsGood] = useState<any>(false);
  const [isOther, setIsOther] = useState<any>(false);
  const [isDownload, setIsDownload] = useState<any>(false);
  const [feedback, setFeedback] = useState<any>("");
  const [whatLacks, setWhatLacks] = useState<any>("");
  const [link, setLink] = useState<string>("");
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const [customerEmail, setCustomerEmail] = useState<string>(
    bookingData?.customer_email === "customer.reports@redcliffelabs.com"
      ? ""
      : bookingData?.customer_email || ""
  );
  useEffect(() => {
    if (bookingData.pk) {
      setFeedbackSubmitted(bookingData?.feedback_submitted || "");
      setCustomerEmail(
        bookingData?.customer_email === "customer.reports@redcliffelabs.com"
          ? ""
          : bookingData?.customer_email || ""
      );
      //@ts-ignore
      gtag("event", "pv_track_status", {
        booking_id: bookingData.pk,
        collection_date: bookingData?.collection_date,
        collection_slot: bookingData?.collection_slot.slot,
        booking_status: bookingData?.booking_status,
        partner_name: bookingData?.center?.name,
      });
    }
  }, [bookingData]);

  const handleCheck = (checked: any, value: any) => {
    let arr = [...selectedProblems];
    if (checked) {
      arr.push(value);
      if (value === "OTHR") {
        setIsOther(true);
      }
    } else if (!checked) {
      if (value === "OTHR") {
        setIsOther(false);
      }
      arr.map((val: any, index: any) => {
        if (val === value) {
          arr.splice(index, 1);
        }
      });
    }
    setSelectedProblems(arr);
  };
  const handleReschedule = () => {
    setReschedule(true);
    setCollectionDate(bookingData?.collection_date);
    setCollectionSlot({
      id: bookingData?.collection_slot?.id,
      slot: bookingData?.collection_slot?.slot,
    });
    setLatitude(bookingData?.customer_latitude);
    setLongitude(bookingData?.customer_longitude);
    setOpenTracking(false);
    setOpenSlotModal(true);

    //@ts-ignore
    gtag("event", "track_status_reschedule_btn", {
      booking_id: bookingData.pk,
      collection_date: bookingData?.collection_date,
      collection_slot: bookingData?.collection_slot.slot,
      booking_status: bookingData?.booking_status,
      partner_name: bookingData?.center?.name,
    });
  };
  const cancelBooking = () => {
    setOpenCancel(true);
    setCancel(true);
  };
  const handleCancel = () => {
    setCancel(true);
    setCollectionDate(bookingData?.collection_date);
    setCollectionSlot({
      id: bookingData?.collection_slot?.id,
      slot: bookingData?.collection_slot?.slot,
    });
    setLatitude(bookingData?.customer_latitude);
    setLongitude(bookingData?.customer_longitude);
    setOpenTracking(false);
    setOpenSlotModal(true);
    //@ts-ignore
    gtag("event", "track_status_cancel_btn", {
      booking_id: bookingData.pk,
      collection_date: bookingData?.collection_date,
      collection_slot: bookingData?.collection_slot.slot,
      booking_status: bookingData?.booking_status,
      partner_name: bookingData?.center?.name,
    });
  };
  const handleClose = () => {
    setFeedbackOpen(false);
    setIsGood("");
    setFeedback("");
    setWhatLacks("");
    setSelectedProblems([]);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      booking: bookingData.pk,
      what_it_lack: selectedProblems,
      other_text: whatLacks,
      suggestion: feedback,
      happy_or_not: isGood,
    };
    await dispatch(submitReview({ data: data, key: partnerKey?.center_key }));
    setFeedbackSubmitted(true);
     //@ts-ignore
     gtag("event", "nps_rating", {
      booking_id: bookingData.pk,
      collection_date: bookingData?.collection_date,
      collection_slot: bookingData?.collection_slot.slot,
      booking_status: bookingData?.booking_status,
      partner_name: bookingData?.center?.name,
      number_of_star:rating,
    });
    handleClose();
  };
  useEffect(() => {
    if (rating <= 3 && rating !== "") {
      setFeedbackOpen(true);
    }
  }, [rating]);
  useEffect(() => {
    setLink("");
    bookingData?.booking_report &&
      bookingData?.booking_report.length > 0 &&
      bookingData?.booking_report.map((val: any, index: any) => {
        if (String(reportFor) === String(val?.booking_or_additional?.id)) {
          setLink(val.report);
        }
      });
  }, [reportFor]);
  const submitEmail = async (e: any) => {
    e.preventDefault();
    const data = {
      customer_email: customerEmail,
    };
    await dispatch(
      updateBookingEmail({
        key: partnerKey?.center_key,
        data: data,
        id: bookingData?.pk,
      })
    );
    setEmailOpen(false);
  };
  const showOrderSummary = () => {
    setViewOrderSummary(true);
    setCollectionDate(bookingData?.collection_date);
    setCollectionSlot({
      id: bookingData?.collection_slot?.id,
      slot: bookingData?.collection_slot?.slot,
    });
    setOpenTracking(false);
    setOpenCouponModal(true);
  };

  return (
    <Grid className="content2">
      <Header isSupport={true} />

      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 40 }}>
        <Container style={{ marginTop: 20 }}>
          <b>TRACK STATUS</b>
        </Container>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          {loading ? (
            <Loader />
          ) : (
            <Timeline style={{ marginLeft: "-80%" }}>
              {/* order booked */}
              <TimelineItem position="right">
                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                  <TimelineDot>
                    <img width="30px" src={orderBooked} />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="h6"
                    color={
                      bookingData?.booking_status === "pending"
                        ? "#7B7C7E"
                        : "#E06358"
                    }
                  >
                    {bookingData?.booking_status === "cancelled" ? (
                      <div style={{ color: "red" }}>Booking Cancelled</div>
                    ) : (
                      "Order Booked"
                    )}
                  </Typography>
                  <Typography>
                    Collection slot:{" "}
                    <b>
                      {moment(bookingData?.collection_date).format("DD-MMM")} |{" "}
                      {bookingData?.collection_slot?.slot}
                    </b>
                  </Typography>
                  {bookingData?.report_status === "consolidate" ||
                  bookingData?.booking_status === "cancelled" ||
                  bookingData?.pickup_status === "confirmed" ? (
                    ""
                  ) : (
                    <Grid container>
                      {(bookingData?.booking_status === "order booked" ||
                        bookingData?.booking_status === "confirmed") &&
                      centreData.can_reschedule ? (
                        <Grid item>
                          <Button
                            color="secondary"
                            onClick={() => handleReschedule()}
                          >
                            RESCHEDULE
                          </Button>
                        </Grid>
                      ) : (
                        ""
                      )}

                      {centreData.can_cancel && (
                        <Grid item>
                          {(bookingData?.booking_status === "order booked" ||
                            bookingData?.booking_status === "confirmed") &&
                          centreData.can_reschedule ? (
                            <Button
                              color="secondary"
                              onClick={() => handleCancel()}
                            >
                              CANCEL
                            </Button>
                          ) : (
                            <Button
                              color="secondary"
                              onClick={() => cancelBooking()}
                            >
                              CANCEL
                            </Button>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )}
                </TimelineContent>
              </TimelineItem>
              {centreData.custom_user_requirement_for === "ACCENTURE" &&
              !areaServicable ? (
                ""
              ) : (
                <>
                  {" "}
                  {/*  Phlebo Assigned*/}
                  <TimelineItem position="right">
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                      <TimelineDot>
                        <img width="30px" src={phlebo} />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="h6"
                        color={
                          bookingData?.phlebo_details?.phlebo_name === undefined
                            ? "#7B7C7E"
                            : "#E06358"
                        }
                      >
                        Phlebo Assigned
                      </Typography>
                      {bookingData?.phlebo_details?.phlebo_name ===
                      undefined ? (
                        ""
                      ) : (
                        <Typography>
                          Phlebo name:{" "}
                          <b>{bookingData?.phlebo_details?.phlebo_name}</b>
                        </Typography>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                  {/* Phlebo Started */}
                  <TimelineItem position="right">
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                      <TimelineDot>
                        <img width="30px" src={phleboStarted} />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="h6"
                        color={
                          bookingData?.booking_status === "phlebo started" ||
                          bookingData?.booking_status === "phlebo reached" ||
                          bookingData?.pickup_status === "confirmed"
                            ? "#E06358"
                            : "#7B7C7E"
                        }
                      >
                        Phlebo Started
                      </Typography>
                      {bookingData?.booking_status === "phlebo started" ||
                      bookingData?.booking_status === "phlebo reached" ||
                      bookingData?.pickup_status === "confirmed" ? (
                        <>
                          <Typography>
                            Phlebo Number:{" "}
                            <b>{bookingData?.phlebo_details?.phlebo_contact}</b>
                          </Typography>

                          <Grid container>
                            <Grid item xs={4}>
                              <img width="80px" src={vac} />
                            </Grid>
                            <Grid item xs={4}>
                              <img width="80px" src={temp} />
                            </Grid>
                            <Grid item xs={4}>
                              <img width="80px" src={hyg} />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </TimelineContent>
                  </TimelineItem>
                  {/* Sample collected */}
                  <TimelineItem position="right">
                    <TimelineSeparator>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                      <TimelineDot>
                        <img width="30px" src={SampleCollected} />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        variant="h6"
                        component="h6"
                        color={
                          bookingData?.pickup_status === "confirmed"
                            ? "#E06358"
                            : "#7B7C7E"
                        }
                      >
                        Sample Collected
                      </Typography>
                      {bookingData?.pickup_status === "confirmed" ? (
                        <Typography>
                          Report will be shared within <b>24-48 hours</b>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </TimelineContent>
                  </TimelineItem>
                </>
              )}

              {/* Sample Received in Lab */}
              <TimelineItem position="right">
                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                  <TimelineDot>
                    <img width="30px" src={SampleRecieved} />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="h6"
                    color={bookingData?.sample_recieved ? "#E06358" : "#7B7C7E"}
                  >
                    Sample Received in Lab
                  </Typography>
                  {bookingData?.sample_recieved ? (
                    <Typography>
                      Our lab technician will process the handovered sample
                    </Typography>
                  ) : (
                    ""
                  )}
                </TimelineContent>
              </TimelineItem>
              {/*  Report Generated */}
              <TimelineItem position="right">
                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: "primary.main" }} />
                  <TimelineDot>
                    <img width="30px" src={Report} />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="h6"
                    color={
                      bookingData?.report_status === "consolidate"
                        ? "#E06358"
                        : "#7B7C7E"
                    }
                  >
                    Report Generated
                  </Typography>
                  {bookingData?.report_status === "consolidate" ? (
                    <Typography>
                      Status: {bookingData?.report_status}
                    </Typography>
                  ) : (
                    ""
                  )}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          )}
        </Card>
        {bookingData?.report_status === "consolidate" ? (
          <Card sx={{ borderRadius: "16px", m: 1 }}>
            <Grid container spacing={2} className="dialogContent">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Report for"
                  value={reportFor}
                  onChange={(e) => setReportFor(e.target.value)}
                >
                  <MenuItem value={bookingData?.pk}>
                    {bookingData?.customer_name}
                  </MenuItem>
                  {bookingData?.additional_members &&
                    bookingData?.additional_members.length > 0 &&
                    bookingData?.additional_members.map((val: any) => {
                      return (
                        <MenuItem value={val?.id}>
                          {val?.customer_name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <a href={link} target="blank">
                  <StyledButtonLight
                    disabled={!link}
                    onClick={() =>
                      //@ts-ignore
                      gtag("event", "download_report", {
                        booking_id: bookingData.pk,
                        collection_date: bookingData?.collection_date,
                        collection_slot: bookingData?.collection_slot.slot,
                        booking_status: bookingData?.booking_status,
                        partner_name: bookingData?.center?.name,
                      })
                    }
                  >
                    Download
                  </StyledButtonLight>
                </a>
              </Grid>
            </Grid>
          </Card>
        ) : (
          ""
        )}

        {feedbackSubmitted ||
        bookingData?.report_status !== "consolidate" ||
        centreData.nps_rating === false ? (
          ""
        ) : (
          <Card sx={{ borderRadius: "16px", m: 1 }} className="gradiant1">
            <Grid container spacing={2} className="dialogContent">
              <Grid item xs={12} textAlign="center">
                Rate Us
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  size="large"
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Box>
      <Container className="gradiant2">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledButtonLight onClick={showOrderSummary}>
              ORDER DETAILS
            </StyledButtonLight>
          </Grid>
          <Grid item xs={6}>
            {centreData?.payment_mode === "POST" ||
            centreData?.has_partner_user_detail ||
            centreData.custom_user_requirement_for === "ACCENTURE" ||centreData.custom_user_requirement_for === "BOB"? (
              ""
            ) : (
              <StyledButtonLight
                onClick={() =>
                  bookingData?.pickup_status === "confirmed"
                    ? setBillOpen(true)
                    : alert(
                        "Receipt will be generated once the sample get collected"
                      )
                }
              >
                RECEIPT
              </StyledButtonLight>
            )}
          </Grid>
          {bookingData?.report_status !== "consolidate" &&
          bookingData?.booking_status !== "cancelled" &&
          (bookingData?.customer_email ===
            "customer.reports@redcliffelabs.com" ||
            bookingData?.customer_email === null) ? (
            <Grid item xs={12}>
              <StyledButton onClick={() => setEmailOpen(true)}>
                EMAIL YOUR REPORTS
              </StyledButton>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Container>
      <Dialog disableEscapeKeyDown={true}
        open={emailOpen}
        onClose={() => setEmailOpen(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <UpdateEmail
          submitEmail={submitEmail}
          bookingData={bookingData}
          customerEmail={customerEmail}
          setCustomerEmail={setCustomerEmail}
          loading={loading}
        />
      </Dialog>
      <Dialog disableEscapeKeyDown={true}
        open={billOpen}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <div className="dialogContent2">
          <BillPdf
            bookingOnline={bookingData}
            isDownload={isDownload}
            setIsDownload={setIsDownload}
          />
        </div>
        <Grid container spacing={2} style={{ padding: 15 }}>
          <Grid item xs={6}>
            <StyledButtonLight onClick={() => setBillOpen(false)}>
              CLOSE
            </StyledButtonLight>
          </Grid>
          <Grid item xs={6}>
            <StyledButton onClick={() => setIsDownload(true)}>
              DOWNLOAD
            </StyledButton>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog disableEscapeKeyDown={true}
        open={feedbackOpen}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottom"
      >
        <form onSubmit={handleSubmit} style={{ maxWidth: "500px" }}>
          <DialogContent>
            {!isGood ? (
              <>
                <h5>
                  What was the missing or disappointing in your experience with
                  us?
                </h5>
                <DialogContentText>
                  <FormControlLabel
                    value="NSWA"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheck(e.target.checked, e.target.value)
                        }
                        icon={<CheckCircleOutlineOutlinedIcon />}
                        checkedIcon={
                          <CheckCircleIcon style={{ color: "green" }} />
                        }
                      />
                    }
                    label="Not satisfied with the report value accuracy"
                    labelPlacement="end"
                  />
                </DialogContentText>
                <DialogContentText>
                  <FormControlLabel
                    value="PCLA"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheck(e.target.checked, e.target.value)
                        }
                        icon={<CheckCircleOutlineOutlinedIcon />}
                        checkedIcon={
                          <CheckCircleIcon style={{ color: "green" }} />
                        }
                      />
                    }
                    label="Phelebomist came late to collect my sample"
                    labelPlacement="end"
                  />
                </DialogContentText>
                <DialogContentText>
                  {" "}
                  <FormControlLabel
                    value="PDAL"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheck(e.target.checked, e.target.value)
                        }
                        icon={<CheckCircleOutlineOutlinedIcon />}
                        checkedIcon={
                          <CheckCircleIcon style={{ color: "green" }} />
                        }
                      />
                    }
                    label="Phelebomist has not agreed to come on location"
                    labelPlacement="end"
                  />
                </DialogContentText>
                <DialogContentText>
                  {" "}
                  <FormControlLabel
                    value="RNOT"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheck(e.target.checked, e.target.value)
                        }
                        icon={<CheckCircleOutlineOutlinedIcon />}
                        checkedIcon={
                          <CheckCircleIcon style={{ color: "green" }} />
                        }
                      />
                    }
                    label="Report did not come on time"
                    labelPlacement="end"
                  />
                </DialogContentText>

                <DialogContentText>
                  <FormControlLabel
                    value="OTHR"
                    control={
                      <Checkbox
                        onChange={(e) =>
                          handleCheck(e.target.checked, e.target.value)
                        }
                        icon={<CheckCircleOutlineOutlinedIcon />}
                        checkedIcon={
                          <CheckCircleIcon style={{ color: "green" }} />
                        }
                      />
                    }
                    label="Other"
                    labelPlacement="end"
                  />
                </DialogContentText>
                {isOther ? (
                  <>
                    <textarea
                      required
                      rows={4}
                      placeholder="Start typing here"
                      style={{ width: "100%" }}
                      value={whatLacks}
                      onChange={(e) => setWhatLacks(e.target.value)}
                    />
                    <sub style={{ color: "red" }}>
                      Ensure this field has no more than 160 characters.
                    </sub>
                  </>
                ) : (
                  ""
                )}

                <p></p>
              </>
            ) : (
              ""
            )}

            <h5>
              What feedback or suggestion you want to Share with us to enhance
              the experience?{isGood ? "(required)" : "(optional)"}
            </h5>
            <textarea
              placeholder="Start typing here"
              style={{ width: "100%" }}
              rows={4}
              value={feedback}
              required={isGood}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <sub style={{ color: "red" }}>
              Ensure this field has no more than 160 characters.
            </sub>
          </DialogContent>
          <DialogActions>
            <StyledButton
              type="submit"
              disabled={!isGood && selectedProblems.length === 0}
            >
              Submit
            </StyledButton>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
};

export default TrackStatus;
