import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SecureStorage from "../../config/SecureStorage";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import StyledButton from "../../components/Button/StyledButton";
import {
  getEmailOtp,
  getEmpDetails,
  postOtpForEmail,
  postVerifyOtp,
} from "../../services/actionSlice";
import { AppDispatch } from "../../storage/store";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import OtpInput from "react-otp-input";
import moment from "moment";
// import ReactGA from "react-ga";
interface Props {
  setAccOpenPackageModal: any;
  setRadLandingPage: any;
  setCustomerEmail: any;
  customerEmail: any;
  setOtpEmail: any;
  otpEmail: any;
  setOtpEmailVerified: any;
  otpEmailVerified: any;
  setLocationOpen: any;
  customerNumber: any;
  setCustomerNumber: any;
  mobileValidation: any;
  otp: any;
  setOtp: any;
  PostOtp: any;
}

const LandingPage: React.FC<Props> = ({
  setRadLandingPage,
  setCustomerEmail,
  customerEmail,
  setOtpEmail,
  otpEmail,
  setOtpEmailVerified,
  otpEmailVerified,
  setAccOpenPackageModal,
  setLocationOpen,
  customerNumber,
  setCustomerNumber,
  mobileValidation,
  otp,
  setOtp,
  PostOtp,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const verifiedOtp = useSelector((state: any) => state.webAppSlice.verifyOtp);
  const mobileForOtp = useSelector(
    (state: any) => state.webAppSlice.mobileForOtp
  );
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const company = centreData.custom_user_requirement_for;
  function getSubstring(str: any, start: any, end: any) {
    let char1 = str.indexOf(start) + 1;
    let char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  }
  const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );

  const [email, setEmail] = useState<any>("");
  const [edit, setEdit] = useState<boolean>(true);
  const [sentEmail, setSentEmail] = useState<any>("");
  const [seconds, setSecond] = useState<any>("");
  const [key, setKey] = useState<any>("1");
  const verifyEmail = () => {
    setEdit(false);
    setSentEmail(email);
    //@ts-ignore done
    gtag("event", "verify_email_btn", {
      user_id: customerEmail,
      customer_email: centreData?.collection_date,
      device_name: getSubstring(navigator.userAgent, "(", ";"),
    });
    dispatch(
      getEmailOtp(
        `?email=${customerEmail}&emp_id=accn${
          !centreData?.allow_upload ? "&force=true" : ""
        }`
      )
    );
  };

  useEffect(() => {
    if (String(otpEmail).length === 6) {
      dispatch(postOtpForEmail(`?otp=${otpEmail}&email=${customerEmail}`));
    }
  }, [otpEmail]);
  useEffect(() => {
    if (verifiedOtpEmail.token) {
      const tokenIframe = {
        email: customerEmail,
        time: moment().format("YYYY-MM-DDTHH:mm:ss"),
        name: "",
        number: "",
        verifiedOtptoken: verifiedOtpEmail.token,
      };
      //@ts-ignore
      window.dataLayer.push({
        email: customerEmail,
        verifiedOtptoken: verifiedOtpEmail?.token,
      });
      SecureStorage.setItem("tokenIframe", JSON.stringify(tokenIframe));

      setOtpEmailVerified(true);
      setRadLandingPage(false);
      setLocationOpen(false);
      setAccOpenPackageModal(true);
    }
    if (verifiedOtpEmail.errors) {
      setKey(otpEmail);
    }
  }, [verifiedOtpEmail]);
  useEffect(() => {
    let data = SecureStorage.getItem("tokenIframe")
      ? JSON.parse(SecureStorage.getItem("tokenIframe"))
      : "";
    if (data?.verifiedOtptoken) {
      dispatch(getEmpDetails(data?.verifiedOtptoken));
    }
  }, [otpEmailVerified]);
  useEffect(() => {
    if (verifiedOtp?.verified === true) {
      const tokenIframe = {
        email: "",
        time: moment().format("YYYY-MM-DDTHH:mm:ss"),
        name: "",
        number: customerNumber,
      };

      SecureStorage.setItem("tokenIframe", JSON.stringify(tokenIframe));
      setOtpEmailVerified(true);
      setRadLandingPage(false);
      setLocationOpen(false);
      setAccOpenPackageModal(true);
    }
    if (verifiedOtp.errors) {
      setKey(otp);
    }
  }, [verifiedOtp]);
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  useEffect(() => {
    if (String(otp).length === 6) {
      dispatch(
        postVerifyOtp({
          phone_number: customerNumber,
          otp: otp,
        })
      );
    }
  }, [otp]);
  // console.log(emailSent,"emailSent")
  return (
    <Grid className="content" id="collectionSlot">
      <Grid container spacing={2} className="dialogContent">
        <Grid item xs={12} textAlign="center">
          <img
            src={centreData?.logo_image}
            alt="LOGO"
            style={{
              padding: "5px 0px 0px",
              margin: "70px auto",
              width: "250px",
              height: "auto",
            }}
          />
        </Grid>
        {centreData?.login_via === "EM" ? (
          <>
            {" "}
            <Grid item xs={12} textAlign="center">
              <TextField
                className="input"
                variant="outlined"
                label="E-mail"
                placeholder="Enter your email address"
                required
                type="email"
                disabled={!edit}
                fullWidth
                value={email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <span>
                        {`${
                          (process.env.REACT_APP_ENV === "production" &&
                            (email === "RCL2847" ||
                              email === "RCL1424" ||
                              email === "RCL3284" ||
                              email === "RCL542" ||
                              email === "RCL265" ||
                              email === "RCL703" ||
                              email === "RCL2173")) ||
                          process.env.REACT_APP_ENV === "development"
                            ? "@redcliffelabs.com"
                            : `${
                                centreData?.domain_name
                                  ? centreData?.domain_name
                                  : ""
                              }`
                        }`}
                      </span>
                      {email && emailSent?.message === "Otp sent!" && !edit ? (
                        <Button onClick={() => setEdit(true)}>Edit</Button>
                      ) : (
                        ""
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setEmail(
                    centreData?.domain_name
                      ? String(e.target.value).replace("@", "").trim()
                      : String(e.target.value).trim()
                  );
                  setCustomerEmail(
                    `${
                         process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL1424"
                        ? "jayraj@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL2173"
                        ? "sachin.sharma@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL3284"
                        ? "abhishek@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL542"
                        ? "suraj.das@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL265"
                        ? "deepak.c@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production" &&
                          String(e.target.value) === "RCL703"
                        ? "manoj.kumar@redcliffelabs.com"
                        : process.env.REACT_APP_ENV === "production"
                        ? 
                          `${
                            centreData?.domain_name
                              ? String(e.target.value).replace("@", "").trim() +
                                centreData?.domain_name
                              : String(e.target.value).trim()
                          }`
                        : String(e.target.value).replace("@", "").trim() +
                          "@redcliffelabs.com"
                    }`
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="left">
              {emailSent?.message === "Otp sent!" &&
              sentEmail &&
              sentEmail === email &&
              !edit ? (
                <>
                  <Grid item xs={12}>
                    <Typography component="p" color="green">
                      OTP has been shared on your Email.{" "}
                    </Typography>
                    <Typography component="p">Enter OTP</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OtpInput
                      value={otpEmail}
                      isInputNum={true}
                      key={key}
                      numInputs={6}
                      onChange={(e: any) => {
                        setOtpEmail(e);
                      }}
                      inputStyle={{
                        width: "2rem",
                        height: "2rem",
                        margin: "0 0.4rem",
                        fontSize: "14px",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,0.3)",
                        background: "#e7e7e7",
                        color: "#000",
                      }}
                    />
                  </Grid>
                  {verifiedOtpEmail?.errors && key === otpEmail ? (
                    <Grid item xs={12}>
                      <Typography component="p" color="red">
                        <CancelOutlinedIcon
                          style={{
                            color: "red",
                            fontSize: 18,
                            marginBottom: "-3px",
                          }}
                        />{" "}
                        Incorrect OTP!
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12}>
                    {seconds !== 0 ? (
                      <p>
                        Resend OTP in
                        <span style={{ color: "#5866E0" }}>
                          {" "}
                          00:{seconds < 10 ? "0" + seconds : seconds}
                        </span>
                      </p>
                    ) : (
                      <p>
                        <span
                          style={{ color: "#5866E0", cursor: "pointer" }}
                          onClick={() => {
                            verifyEmail();
                            setSecond(30);
                            setOtpEmail("");
                            setKey("0");
                          }}
                        >
                          Resend OTP
                        </span>
                      </p>
                    )}
                  </Grid>
                </>
              ) : (
                <StyledButton
                  id="verifyEmail"
                  disabled={!customerEmail}
                  onClick={() => {
                    verifyEmail();
                    setSecond(30);
                    //@ts-ignore
                    // window.dataLayer.push({
                    //   email: customerEmail,
                    // });
                  }}
                >
                  Verify Email
                </StyledButton>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  key={String(customerNumber)?.length === 10 ? "0" : "1"}
                  type="number"
                  label="Mobile Number"
                  value={customerNumber}
                  variant="outlined"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  fullWidth
                  required
                  onChange={(e) => setCustomerNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
                <sub style={{ color: "red" }}>
                  {!mobileValidation ? "Please enter valid mobile number" : ""}
                </sub>
              </Grid>
            </Grid>
            {String(customerNumber).length === 10 &&
            mobileForOtp?.phone_number ? (
              <>
                <Grid item xs={12}>
                  <Typography component="p" color="green">
                    OTP has been shared on your mobile.{" "}
                  </Typography>
                  <Typography component="p">Enter OTP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <OtpInput
                    value={otp}
                    isInputNum={true}
                    key={key}
                    numInputs={6}
                    onChange={(e: any) => setOtp(e)}
                    inputStyle={{
                      width: "2rem",
                      height: "2rem",
                      margin: "0 0.4rem",
                      fontSize: "14px",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)",
                      background: "#e7e7e7",
                      color: "#000",
                    }}
                  />
                </Grid>
                {verifiedOtp?.errors ? (
                  <Grid item xs={12}>
                    <Typography component="p" color="red">
                      <CancelOutlinedIcon
                        style={{
                          color: "red",
                          fontSize: 18,
                          marginBottom: "-3px",
                        }}
                      />{" "}
                      Incorrect OTP!
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {seconds !== 0 ? (
                    <p>
                      Resend OTP in
                      <span style={{ color: "#5866E0" }}>
                        {" "}
                        00:{seconds < 10 ? "0" + seconds : seconds}
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span
                        style={{ color: "#5866E0", cursor: "pointer" }}
                        onClick={() => {
                          PostOtp(customerNumber);
                          setSecond(30);
                          //@ts-ignore
                          gtag("event", "trigger_resend_otp", {
                            is_mobile_valid: true,
                            mobile_number: customerNumber,
                            user_pk: customerNumber,
                            device_name: getSubstring(
                              navigator.userAgent,
                              "(",
                              ";"
                            ),
                          });
                        }}
                      >
                        Resend OTP
                      </span>
                    </p>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <StyledButton
                  id="verifyEmail"
                  disabled={customerNumber.length !== 10}
                  onClick={() => {
                    PostOtp(customerNumber);
                    setSecond(30);
                  }}
                >
                  Verify Mobile
                </StyledButton>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} textAlign={"center"}>
          <hr style={{ border: "1px solid #ececec" }} />
          <sub>
            {centreData?.allow_support_email === true ||
            centreData?.allow_support_contact === true
              ? "For any queries/concerns. please contact us at "
              : ""}
          </sub>
          {centreData?.allow_support_email === true ? (
            <sub>
              <a href={`mailto:${centreData?.support_email}}`}>
                <b>{centreData?.support_email}</b>
              </a>
            </sub>
          ) : (
            ""
          )}
          {centreData?.allow_support_contact === true ? (
            <sub>
              <br />
              <a href={`tel:${centreData?.support_contact}`}>
                <b>{centreData?.support_contact}</b>
              </a>
            </sub>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
