import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import SecureStorage from "../../config/SecureStorage";
import "./index.sass";
import StyledButton from "../../components/Button/StyledButton";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
import OtpInput from "react-otp-input";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Divider from "@mui/material/Divider";
import infoImg from "../../images/info.svg";
import cityImg from "../../images/cityImg.svg";
import {
  postMobileForOtp,
  getCentreByKey,
  postVerifyOtp,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  getPartnerKey,
  getLocationrRevGeoCode,
  createBooking,
  getValidLocation,
  getBooking,
  updateBooking,
  resetState,
  getZone,
  getPrevBooking,
  createLead,
  updateLead,
  removeEmpToken,
  getPackagesData,
  getPrevLead,
  uploadPrescription,
  getAliasCities,
  createBookingRad,
  getEmpDetails,
} from "../../services/actionSlice";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Package from "../Packages/index";
import RadiologyPackage from "../Packages/radiologyPkgs";
import AccPackage from "../Packages/splPartnerPkgs";
import CollectionSlotPage from "../CollectionSlot";
import OrderSummary from "../OrderSummary";
import ConfirmationPage from "../Confirmation";
import LandingPage from "../LandingPage/index";
import AccLandingPage from "../LandingPage/splPartnerVerify";
import RadLandingPage from "../LandingPage/radiologyVerify";
import Radiology from "./splRadiologyBooking";
import RadiologyBooking from "./radiologyBooking";
import moment from "moment";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined"; // import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  TextField,
  Grid,
  Dialog,
  Slide,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  CircularProgress,
  FormGroup,
} from "@mui/material";
import { AppDispatch } from "../../storage/store";
import { TransitionProps } from "@mui/material/transitions";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PatientInfo from "../../components/PatientInfo";
import Loader from "../../components/Loader";
import SnackBar from "../../components/SnackBar/snackBarPopPup";
import TrackStatus from "../TrackStatus";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#E06358",
    },
    secondary: {
      // Purple and green play nicely together.
      main: "#2677F0",
    },
  },
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Main() {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);
  const [otpOpen, setOtpOpen] = React.useState(false);
  const [openTracking, setOpenTracking] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [reschedule, setReschedule] = useState<boolean>(false);
  const [landingPage, setLandingPage] = useState<boolean>(false);
  const [accLandingPage, setAccLandingPage] = useState<boolean>(false);
  const [radLandingPage, setRadLandingPage] = useState<boolean>(false);
  const [cancelRemarks, setCancelRemarks] = useState<any>("");
  const [leadId, setLeadId] = useState<any>("");
  const [isOther, setIsOther] = useState<any>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const [type, setType] = useState<string>("path");
  const [otp, setOtp] = React.useState("");
  const [openPackageModal, setOpenPackageModal] = React.useState(false);
  const [accOpenPackageModal, setAccOpenPackageModal] = React.useState(false);
  const [allEmpDetails, setAllEmpDetails] = useState<any>([]);
  const [locationOpen, setLocationOpen] = React.useState(false);
  const [latitude, setLatitude] = React.useState<any>("");
  const [longitude, setLongitude] = React.useState<any>("");
  const [addrLoading, setAddrLoading] = useState<boolean>(false);
  const [showMain, setShowMain] = useState<boolean>(false);
  const [openCouponModal, setOpenCouponModal] = useState<boolean>(false);
  const [openSlotModal, setOpenSlotModal] = useState<boolean>(false);
  const [openConfirmedModal, setOpenConfirmedModal] = useState<boolean>(false);
  const [viewOrderSummary, setViewOrderSummary] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<string>("");
  const [refData, setRefData] = useState<any>("");
  const [relation, setRelation] = useState<any>("");
  const [myself, setMyself] = useState<any>(false);
  const [areaServicable, setAreaServicable] = useState<any>(true);
  const [customerName, setCustomerName] = useState<any>("");
  const [fullName, setFullName] = useState<any>("");
  const [customerNumber, setCustomerNumber] = useState<any>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [empId, setEmpId] = useState<string>("");
  const [agentId, setAgentId] = useState<string>("");
  const [empPackages, setEmpPackages] = useState<any>([]);
  const [otpEmail, setOtpEmail] = useState<string>("");
  const [otpEmailVerified, setOtpEmailVerified] = useState<boolean>(false);
  const [customerAge, setCustomerAge] = useState<any>("");
  const [customerGender, setCustomerGender] = useState<any>("");
  const [addIndex, setAddIndex] = useState<any>("");
  const [customerPackages, setCustomerPackages] = useState<any>([]);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [customerAddress, setCustomerAddress] = useState<any>("");
  const [addressInput, setAddressInput] = useState<any>("");
  const [customerCity, setCustomerCity] = useState<any>("");
  const [cityName, setCityName] = useState<any>("");
  const [customerHouseNo, setCustomerHouseNo] = useState<any>("");
  const [direction, setDirection] = useState<any>("");
  const [customerRoad, setCustomerRoad] = useState<any>("");
  const [collectionDate, setCollectionDate] = useState<any>(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [collectionSlot, setCollectionSlot] = useState<any>("");
  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [expand, setExpand] = useState<any>(true);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [genderValidation, setGenderValidation] = useState<any>(true);
  const [isAddnPkg, setIsAddnPkg] = useState<any>(false);
  const [coupon, setCoupon] = useState<any>("");
  const [couponCode, setCouponCode] = useState<any>("");
  const [couponPackage, setCouponPackage] = useState<any>([]);
  const [clientType, setClientType] = useState<any>("");
  const [isAdditional, setIsAdditional] = useState<boolean>(true);
  const [customerZone, setCustomerZone] = useState<any>(0);
  const [seconds, setSecond] = useState<any>(30);
  const [pincode, setPincode] = useState<any>("");
  const [trackId, setTrackId] = useState<any>("");
  const [centreName, setCentreName] = useState<any>({
    center: { name: "" },
    address: "",
  });

  function getSubstring(str: any, start: any, end: any) {
    let char1 = str.indexOf(start) + 1;
    let char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  }
  const [remarks, setRemarks] = useState<any>("");
  //link queries
  const cancelReason = [
    { id: 1, value: "Will not be available on scheduled date" },
    { id: 2, value: "Will not be available for scheduled time slot" },
    { id: 3, value: "I won’t be on fasting on scheduled date" },
    { id: 4, value: "Test have been done with other Lab" },
  ];
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const partner_id = params.get("pid");
    const track_id = params.get("tid");
    if (track_id) {
      setTrackId(track_id);
      setOtpOpen(false);
      setLandingPage(false);
      setOpenTracking(true);
    }

    if (partner_id) {
      setRefData(partner_id);
    }

    const fullname = params.get("fullname");
    if (fullname) {
      setCustomerName(fullname);
      setFullName(fullname);
    }
    const mobile = params.get("mobile");
    if (mobile) {
      setCustomerNumber(mobile);
      setFullName(fullname);
    }
    const gender = params.get("gender");
    if (gender) {
      setCustomerGender(gender);
    }
    const email = params.get("email");
    if (email) {
      setCustomerEmail(email);
    }
    const age = params.get("age");
    if (age) {
      setCustomerAge(age);
    }
    const code = params.get("code");
    if (code) {
      setCouponCode(code);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (refData) {
      dispatch(getCentreByKey(refData));
    }
  }, [refData]);
  const [accessToken, setAccessToken] = useState<string>("");
  //data
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const verifiedOtp = useSelector((state: any) => state.webAppSlice.verifyOtp);
  const aliasCities = useSelector(
    (state: any) => state.webAppSlice.aliasCities
  );
  useEffect(() => {
    if (aliasCities && aliasCities.results && aliasCities.results.length > 0) {
      setCityName(
        aliasCities?.results.map((val: any) => val?.city && val?.city?.id)
      );
      handleCloseLocation();
    } else if (
      aliasCities &&
      aliasCities.results &&
      aliasCities.results.length === 0
    ) {
      setCityName(false);
    }
  }, [aliasCities]);

  const employeeDetails = useSelector(
    (state: any) => state.webAppSlice.empDetails
  );
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );
  useEffect(() => {
    const data: any = employeeDetails?.data;
    const pack: any = employeeDetails?.package_details || [];
    const addn: any = employeeDetails?.pwei_data || [];
    if (addn.length > 0) {
      setAllEmpDetails(addn);
    }
    if (data) {
      setCustomerName(data.customer_name || "");
      setCustomerAge(data.customer_age || "");
      setCustomerGender(String(data.customer_gender).toLowerCase() || "");
      setFullName(data.customer_name || "");
      setCustomerNumber(data?.phonenumber || "");
      (centreData.custom_user_requirement_for !== "ACCENTURE" ||
        centreData.custom_user_requirement_for !== "BOB" ||
        centreData.custom_user_requirement_for !== "HITACHI" ||
        centreData.custom_user_requirement_for !== "EISAI" ||
        centreData.custom_user_requirement_for !== "RECI" ||
        centreData.custom_user_requirement_for !== "AON" ||
        centreData.custom_user_requirement_for !== "EVAHEL") &&
        setIsAdditional(
          centreData?.has_addtional_member_data
            ? true
            : data.has_additional_member
        );
      if (
        (centreData.custom_user_requirement_for === "ACCENTURE" ||
          centreData.custom_user_requirement_for === "BOB" ||
          centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData.custom_user_requirement_for === "AON" ||
          centreData.custom_user_requirement_for === "EVAHEL") &&
        customerEmail &&
        verifiedOtpEmail.token
      ) {
        let data = SecureStorage.getItem("tokenIframe")
          ? JSON.parse(SecureStorage.getItem("tokenIframe"))
          : "";

        const tokenIframe = {
          email: customerEmail,
          time: data?.time
            ? data?.time
            : moment().format("YYYY-MM-DDTHH:mm:ss"),
          name: data.customer_name,
          number: customerNumber,
          verifiedOtptoken: verifiedOtpEmail?.token,
        };
        SecureStorage.setItem("tokenIframe", JSON.stringify(tokenIframe));
      }
    }
    // if (addn.length > 1 && addn[0].package.length > 0 && pack.length > 0) {
    //   let parr: any = [];
    //   pack.map((val: any) => {
    //     if (addn[0].package[0] === val.id) {
    //       if (parr.length === 0) {
    //         parr.push(val);
    //       } else if (
    //         parr.length > 0 &&
    //         !parr.find((v1: any) => v1.id === val.id)
    //       ) {
    //         parr.push(val);
    //       }

    //       setCustomerPackages(parr);
    //     }
    //   });
    // } else
    if (
      (pack.length > 0 && centreData.custom_user_requirement_for === "EISAI") ||
      ((centreData.custom_user_requirement_for
        ? centreData.custom_user_requirement_for === "REDCLIFFELABS" ||
          centreData.custom_user_requirement_for === "RSTAR"
        : true) &&
        !centreData?.has_addtional_member_data)
    ) {
      setEmpPackages(pack);
      setCustomerPackages(pack);
    }
  }, [employeeDetails]);
  const packages = useSelector((state: any) => state.webAppSlice.packageParams);
  const prevBookingData = useSelector(
    (state: any) => state.webAppSlice.prevBookingData
  );
  const prevLeadData = useSelector(
    (state: any) => state.webAppSlice.prevLeadData
  );
  const partnerKey = useSelector((state: any) => state.webAppSlice.partnerKey);
  const loading = useSelector((state: any) => state.webAppSlice.loading);
  const validLocation = useSelector(
    (state: any) => state.webAppSlice.validLocation
  );
  const geoFencesMapMyIndia = useSelector(
    (state: any) => state.webAppSlice.geoFencesMapMyIndia
  );
  const mapmyindiaAccessToken = useSelector(
    (state: any) => state.webAppSlice.mapmyindiaAccessToken
  );
  const snackData = useSelector((state: any) => state.webAppSlice.snackbarData);
  const zone = useSelector((state: any) => state.webAppSlice.zone);
  const bookingCreated = useSelector(
    (state: any) => state.webAppSlice.bookingCreated
  );
  const [subTotal, setSubTotal] = useState<number>(0);
  const [pkgTotal, setPkgTotal] = useState<number>(0);

  useEffect(() => {
    let total = 0;
    let radtotal = 0;
    let pkTotal = customerPackages.length;

    customerPackages.length > 0 &&
      customerPackages.map(
        (val: any) =>
          (total =
            total +
            (val?.package_center_prices?.city_offer_price
              ? val?.package_center_prices?.city_offer_price
              : val?.package_center_prices?.offer_price))
      );
    customerPackages.length > 0 &&
      customerPackages.map(
        (val: any) => (radtotal = radtotal + val?.offer_price)
      );
    customerDetails.length > 0 &&
      customerDetails.map(
        (data: any) =>
          data.packages.length > 0 &&
          data.packages.map((val: any, index: any) => {
            pkTotal = pkTotal + index + 1;

            total =
              total +
              (val?.package_center_prices?.city_offer_price
                ? val?.package_center_prices?.city_offer_price
                : val?.package_center_prices?.offer_price);
          })
      );
    if (type === "rad") {
      setSubTotal(radtotal);
    } else {
      setSubTotal(total);
    }
    setPkgTotal(pkTotal);
  }, [customerPackages, customerDetails]);
  useEffect(() => {
    if (bookingCreated?.id || bookingCreated?.pk) {
      dispatch(
        getBooking({
          url: `?booking_id=${bookingCreated?.id || bookingCreated?.pk}`,
          key: partnerKey?.center_key,
        })
      );
      if (verifiedOtpEmail.token) {
        dispatch(removeEmpToken(verifiedOtpEmail.token));
      }
    }
    if (bookingCreated?.lead && file) {
      submitPrescription(bookingCreated?.lead);
    }
  }, [bookingCreated]);
  useEffect(() => {
    if (partnerKey?.center_key) {
      if (
        !(
          centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData.custom_user_requirement_for === "AON" ||
          centreData.custom_user_requirement_for === "EVAHEL"
        ) &&
        centreData.tab_type !== "RADI"
      ) {
        dispatch(
          getPackagesData({
            url: `?center_3p_config_id=${centreData.id}`,
            key: partnerKey?.center_key,
          })
        );
      }
    }
  }, [partnerKey]);
  useEffect(() => {
    if (packages?.data && packages?.data.length === 1) {
      setEmpPackages(packages?.data[0]);
    } else {
      setEmpPackages([]);
    }
  }, [packages]);
  const bookingData = useSelector(
    (state: any) => state.webAppSlice.bookingData
  );
  const revGeoLoc = useSelector((state: any) => state.webAppSlice.revGeoCode);
  useEffect(() => {
    if (
      centreData.has_partner_user_detail &&
      !otpEmailVerified &&
      clientType &&
      !trackId
    ) {
      setLandingPage(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setPageLoaded(true);
      }, 500);
    } else if (
      (centreData.custom_user_requirement_for === "ACCENTURE" ||
        centreData.custom_user_requirement_for === "BOB" ||
        centreData.custom_user_requirement_for === "HITACHI" ||
        centreData.custom_user_requirement_for === "EISAI" ||
        centreData.custom_user_requirement_for === "RECI" ||
        centreData.custom_user_requirement_for === "AON" ||
        centreData.custom_user_requirement_for === "EVAHEL") &&
      !otpEmailVerified &&
      clientType &&
      !trackId
    ) {
      let today = moment().format("YYYY-MM-DDTHH:mm:ss");
      let data = SecureStorage.getItem("tokenIframe")
        ? JSON.parse(SecureStorage.getItem("tokenIframe"))
        : "";
      if (
        data &&
        moment(today).isSameOrBefore(moment(data?.time).add(30, "minute"))
      ) {
        if (
          centreData.custom_user_requirement_for === "EVAHEL" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData?.has_addtional_member_data
        ) {
          dispatch(getEmpDetails(data?.verifiedOtptoken));
        }
        setOtpEmailVerified(true);
        setCustomerEmail(data?.email || "");
        setFullName(data?.name || "");
        setCustomerNumber(data?.number || "");
        setLocationOpen(false);
        setAccOpenPackageModal(true);
      } else {
        SecureStorage.removeItem("tokenIframe");
        setAccLandingPage(true);
      }

      // setLocationOpen(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setPageLoaded(true);
      }, 500);
    } else if (
      (centreData.tab_type === "RADI" || centreData.tab_type === "BOTH") &&
      !otpEmailVerified &&
      clientType &&
      !trackId
    ) {
      let today = moment().format("YYYY-MM-DDTHH:mm:ss");
      let data = SecureStorage.getItem("tokenIframe")
        ? JSON.parse(SecureStorage.getItem("tokenIframe"))
        : "";
      if (
        data &&
        moment(today).isSameOrBefore(moment(data?.time).add(30, "minute"))
      ) {
        setOtpEmailVerified(true);
        setCustomerEmail(data?.email || "");
        setFullName(data?.name || "");
        setCustomerNumber(data?.number || "");
        setLocationOpen(false);
        setAccOpenPackageModal(true);
      } else {
        SecureStorage.removeItem("tokenIframe");
        setRadLandingPage(true);
      }

      // setLocationOpen(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setPageLoaded(true);
      }, 500);
    } else {
      if (clientType === "PA") {
        setOtpOpen(true);
      } else if (
        clientType === "AA" &&
        (!centreData.custom_user_requirement_for ||
          centreData.custom_user_requirement_for === "REDCLIFFELABS" ||
          centreData.custom_user_requirement_for === "RSTAR") &&
        centreData.tab_type !== "RADI" &&
        centreData.tab_type !== "BOTH"
      ) {
        setLocationOpen(true);
      } else if (
        clientType === "NV" &&
        (!centreData.custom_user_requirement_for ||
          centreData.custom_user_requirement_for === "REDCLIFFELABS" ||
          centreData.custom_user_requirement_for === "RSTAR") &&
        centreData.tab_type !== "RADI" &&
        centreData.tab_type !== "BOTH"
      ) {
        setLocationOpen(true);
        setExpand(true);
      }

      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setPageLoaded(true);
      }, 500);
    }
    if (
      centreData.custom_user_requirement_for === "HITACHI" ||
      centreData.custom_user_requirement_for === "EISAI" ||
      centreData.custom_user_requirement_for === "RECI" ||
      centreData.custom_user_requirement_for === "AON" ||
      centreData.custom_user_requirement_for === "EVAHEL" ||
      centreData.tab_type === "RADI"
    ) {
      setType("rad");
    }
  }, [clientType, centreData, otpEmailVerified]);
  useEffect(() => {
    if (
      revGeoLoc?.results &&
      revGeoLoc?.results.length > 0 &&
      validLocation?.valid === true
    ) {
      setAddressInput(revGeoLoc?.results[0].formatted_address);
      setCentreName({
        center: { name: "" },
        address: "",
      });
      setPincode(revGeoLoc?.results[0].pincode);
      setCustomerCity(revGeoLoc?.results[0].city);
      if (revGeoLoc?.results[0].city === "") {
        setCityName(false);
      }
      setCollectionDate(
        moment()
          .add(
            type === "rad" &&
              (centreData.tab_type === "RADI" || centreData.tab_type === "BOTH")
              ? 2
              : type === "rad" &&
                centreData.custom_user_requirement_for !== "EISAI"
              ? 3
              : 1,
            "days"
          )
          .format("YYYY-MM-DD")
      );
      setAreaServicable(true);
      setAddrLoading(false);
      //@ts-ignore
      gtag("event", "curr_location_btn", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        user_pk: customerNumber || customerEmail,
        is_location_enable: true,
        is_serviceable: true,
        locality_name: revGeoLoc?.results[0].formatted_address,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
        pincode: revGeoLoc?.results[0].pincode,
      });
      type !== "rad" && handleCloseLocation();
    } else if (validLocation?.valid === false) {
      //@ts-ignore
      gtag("event", "curr_location_btn", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        user_pk: customerNumber || customerEmail,
        is_location_enable: true,
        is_serviceable: false,
        locality_name: revGeoLoc?.results[0].formatted_address,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
        pincode: revGeoLoc?.results[0].pincode,
      });
      setCustomerCity(revGeoLoc?.results[0].city);
      if (revGeoLoc?.results[0].city === "") {
        setCityName(false);
      }
      if (
        (centreData.custom_user_requirement_for === "ACCENTURE" ||
          centreData.custom_user_requirement_for === "BOB" ||
          centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData.custom_user_requirement_for === "AON" ||
          centreData.custom_user_requirement_for === "EVAHEL") &&
        centreData?.force_service === true
      ) {
        setAddressInput(revGeoLoc?.results[0].formatted_address);
        setCentreName({
          center: { name: "" },
          address: "",
        });
        setPincode(revGeoLoc?.results[0].pincode || "201301");
        setCustomerZone(
          process.env.REACT_APP_ENV === "production" ? 1151 : 1188
        );
        setCollectionDate(
          moment()
            .add(
              type === "rad" &&
                (centreData.tab_type === "RADI" ||
                  centreData.tab_type === "BOTH")
                ? 2
                : centreData.custom_user_requirement_for !== "EISAI"
                ? 3
                : 1,
              "days"
            )
            .format("YYYY-MM-DD")
        );
        type !== "rad" && handleCloseLocation();
      }
      setAreaServicable(false);

      setAddrLoading(false);
    }
  }, [revGeoLoc, validLocation]);
  useEffect(() => {
    if (customerCity) {
      dispatch(
        getAliasCities({
          url: `?name=${customerCity}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [customerCity]);

  useEffect(() => {
    if (type === "rad") {
      setCollectionDate(
        moment()
          .add(
            type === "rad" &&
              (centreData.tab_type === "RADI" || centreData.tab_type === "BOTH")
              ? 2
              : centreData.custom_user_requirement_for !== "EISAI"
              ? 3
              : 1,
            "days"
          )
          .format("YYYY-MM-DD")
      );
    } else if (
      type === "path" &&
      centreData.custom_user_requirement_for === "ACCENTURE" &&
      centreData?.force_service === true
    ) {
      setCollectionDate(
        moment()
          .add(
            centreData.custom_user_requirement_for !== "EISAI" ? 3 : 1,
            "days"
          )
          .format("YYYY-MM-DD")
      );
    } else {
      setCollectionDate(moment().add(1, "days").format("YYYY-MM-DD"));
    }
  }, [type]);
  useEffect(() => {
    if (centreData?.id && clientType && (customerEmail || customerNumber)) {
      //@ts-ignore
      gtag("event", "pv_member_info", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        camp_start_date: centreData?.expire_start_time,
        camp_end_date: centreData?.expire_end_time,
        is_subscription: centreData?.is_subscription,
        payment_method: centreData?.payment_mode,
        user_pk: customerEmail || customerNumber,
        iframe_id: centreData?.id,
        is_package_selection: true,
        is_add_on_member: centreData?.can_add_additional_member,
        is_add_on_member_package_selection: true,
        pre_package_selected_main_member:
          empPackages.length == 1 ? true : false,
        is_user_verified: clientType === "NV" ? false : true,
        pre_package_selected_child_member:
          empPackages.length == 1 ? true : false,
      });
    }
  }, [centreData]);

  const getLatLng = () => {
    setAddrLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      setCustomerAddress("");
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);

      dispatch(
        getValidLocation({
          url: `?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}`,
          key: partnerKey?.center_key,
          noErr:
            ((centreData.custom_user_requirement_for === "ACCENTURE" ||
              centreData.custom_user_requirement_for === "BOB" ||
              centreData.custom_user_requirement_for === "HITACHI" ||
              centreData.custom_user_requirement_for === "EISAI" ||
              centreData.custom_user_requirement_for === "RECI" ||
              centreData.custom_user_requirement_for === "AON" ||
              centreData.custom_user_requirement_for === "EVAHEL") &&
              centreData?.force_service === true) ||
            type === "rad" ||
            centreData.custom_user_requirement_for === "RSTAR"
              ? true
              : false,
        })
      );
      dispatch(
        getZone({
          url: `?longitude=${position.coords.longitude}&latitude=${position.coords.latitude}`,
          key: partnerKey?.center_key,
        })
      );
    });
  };
  useEffect(() => {
    if (centreData?.center) {
      dispatch(getPartnerKey(centreData?.center));
      setClientType(centreData?.otp_mode);
      setIsAdditional(centreData?.can_add_additional_member);
    }
  }, [centreData]);

  useEffect(() => {
    if (locationOpen) {
      dispatch(getMapMyIndiaAccessToken());
    }
  }, [locationOpen]);
  useEffect(() => {
    if (partnerKey?.center_key && trackId) {
      dispatch(
        getBooking({
          url: `?booking_id=${trackId}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [partnerKey, trackId]);
  const PostOtp = (data: any) => {
    dispatch(
      postMobileForOtp({
        phone_number: data,
      })
    );
  };
  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  const setLocalData = () => {
    if (
      (centreData.custom_user_requirement_for === "ACCENTURE" ||
        centreData.custom_user_requirement_for === "BOB" ||
        centreData.custom_user_requirement_for === "HITACHI" ||
        centreData.custom_user_requirement_for === "EISAI" ||
        centreData.custom_user_requirement_for === "RECI" ||
        centreData.custom_user_requirement_for === "AON" ||
        centreData.custom_user_requirement_for === "EVAHEL") &&
      customerEmail &&
      verifiedOtpEmail.token
    ) {
      let data = SecureStorage.getItem("tokenIframe")
        ? JSON.parse(SecureStorage.getItem("tokenIframe"))
        : "";

      const tokenIframe = {
        email: customerEmail,
        time: data?.time ? data?.time : moment().format("YYYY-MM-DDTHH:mm:ss"),
        name: fullName,
        number: customerNumber,
        verifiedOtptoken: verifiedOtpEmail?.token,
      };
      SecureStorage.setItem("tokenIframe", JSON.stringify(tokenIframe));
    }
  };
  useEffect(() => {
    if (
      String(customerNumber).length === 10 &&
      clientType &&
      clientType !== "NV"
    ) {
      PostOtp(customerNumber);
      //@ts-ignore
      gtag("event", "trigger_otp", {
        is_mobile_valid: true,
        mobile_number: customerNumber,
        user_pk: customerNumber || customerEmail,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
      });
      setSecond(30);
    }
    verifyMobile();
    if (String(customerNumber).length === 10 && partnerKey?.center_key) {
      dispatch(
        getPrevBooking({
          url: `?phone=${customerNumber}`,
          key: partnerKey?.center_key,
        })
      );
      dispatch(
        getPrevLead({
          url: `?mobile=${customerNumber}`,
          key: partnerKey?.center_key,
        })
      );
    }

    setLocalData();
  }, [clientType, customerNumber, partnerKey]);
  useEffect(() => {
    if (String(otp).length === 6) {
      dispatch(
        postVerifyOtp({
          phone_number: customerNumber,
          otp: otp,
        })
      );
    }
  }, [otp]);

  useEffect(() => {
    if (
      verifiedOtp?.verified === true &&
      centreData.tab_type !== "RADI" &&
      centreData.tab_type !== "BOTH"
    ) {
      handleClose();
      dropOffLead();

      //@ts-ignore
      gtag("event", "trigger_otp_verified", {
        is_verified: true,
        user_pk: customerNumber,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
      });
    }
  }, [verifiedOtp]);
  useEffect(() => {
    if (locationOpen) {
      //@ts-ignore
      gtag("event", "pv_location_setup", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        camp_start_date: centreData?.expire_start_time,
        camp_end_date: centreData?.expire_end_time,
        is_subscription: centreData?.is_subscription,
        payment_method: centreData?.payment_mode,
        user_pk: customerNumber || customerEmail,
        iframe_id: centreData?.id,
      });
    }
  }, [locationOpen]);

  const handleClose = () => {
    setOtpOpen(false);
    if (
      clientType === "PA" &&
      (!centreData.custom_user_requirement_for ||
        centreData.custom_user_requirement_for === "REDCLIFFELABS" ||
        centreData.custom_user_requirement_for === "RSTAR")
    ) {
      setLocationOpen(true);
    }
  };
  const handleCloseLocation = () => {
    setLocationOpen(false);
    if (clientType === "AA") {
      setOtpOpen(true);
    }
  };
  const addMember = () => {
    setShowMain(true);
    let arr = [...customerDetails];
    if (arr.length > 0) {
      arr[Number(arr.length) - 1]["isShow"] = true;
    }
    arr.push({
      customerName: "",
      nameTrue: true,
      customerAge: "",
      customerGender: "",
      designation: "",
      packageCode:
        empPackages.length === 1 ? empPackages.map((val: any) => val.code) : [],
      packages: empPackages.length === 1 ? empPackages : [],
      isShow: false,
      book_for: "",
    });
    setCustomerDetails(arr);
    //@ts-ignore
    gtag("event", "add_on_member_btn", {
      member_count: arr.length + 1,
      // relationship: "relationship",
      user_pk: customerEmail || customerNumber,
    });
  };
  const removeMember = (index: any) => {
    let arr = [...customerDetails];
    arr.splice(index, 1);
    arr.length === 0 && setShowMain(false);
    setCustomerDetails(arr);
    //@ts-ignore
    gtag("event", "remove_member_btn", {
      member_count_changed: index + 1,
      user_pk: customerEmail || customerNumber,
    });
  };
  const updateMember = (index: any, val: any, key: any) => {
    const list = [...customerDetails];
    list[index][key] = val;
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (key === "customerName" && val && !nameRegex.test(val.toString())) {
      list[index]["nameTrue"] = false;
    } else if (key === "customerName") {
      list[index]["nameTrue"] = true;
    } else if ((key = "isShow")) {
      setShowMain(true);
      list.map((val: any, index1: any) => {
        if (index !== index1) {
          list[index1][key] = true;
        }
      });
    } else if (key === "customerGender") {
      list[index]["designation"] =
        val === "male" ? "Mr" : val === "female" ? "Ms" : "";
    }
    setCustomerDetails(list);
  };
  useEffect(() => {
    if (!showMain) {
      let list = [...customerDetails];
      list.map((val: any, index: any) => {
        list[index]["isShow"] = true;
      });
      setCustomerDetails(list);
    }
  }, [showMain]);

  const handlePackage = (index: any) => {
    if (index !== "" && customerDetails[index].customerGender !== "") {
      setAddIndex(index);
      setGenderValidation(true);
      setOpenPackageModal(true);
      //@ts-ignore
      gtag("event", "select_package_btn", {
        gender: customerDetails[index].customerGender,
        relationship: customerDetails[index].book_for,
        user_pk: customerEmail || customerNumber,
        age: customerDetails[index].customerAge,
      });
    } else if (index === "" && customerGender !== "") {
      setAddIndex("");
      setGenderValidation(true);
      setOpenPackageModal(true);
      //@ts-ignore
      gtag("event", "select_package_btn", {
        gender: customerGender,
        relationship: relation,
        user_pk: customerEmail || customerNumber,
        age: customerAge,
      });
    } else {
      setGenderValidation(false);
    }
  };
  const handlePackageAcc = (index: any) => {
    if (index !== "" && customerDetails[index].customerGender !== "") {
      setAddIndex(index);
      setGenderValidation(true);
      setAccOpenPackageModal(true);
      //@ts-ignore
      gtag("event", "select_package_btn", {
        gender: customerDetails[index].customerGender,
        relationship: customerDetails[index].book_for,
        user_pk: customerEmail || customerNumber,
        age: customerDetails[index].customerAge,
      });
    } else {
      setGenderValidation(false);
    }
  };
  const removePackage = (val: any, index: any, indexA: any) => {
    if (indexA === "" && val === "") {
      let arr = [...customerPackages];
      arr.splice(index, 1);
      setCustomerPackages(arr);
    } else {
      let arr = [...customerDetails];
      arr[indexA].packages.splice(index, 1);
      arr[indexA].packageCode.splice(index, 1);
      setCustomerDetails(arr);
    }
  };
  useEffect(() => {
    let addnarr = [];
    if (customerDetails.length > 0) {
      customerDetails.map((val: any) => {
        if (val.packages.length === 0) {
          addnarr.push(false);
        }
      });
    }
    if (addnarr.length > 0) {
      setIsAddnPkg(false);
    } else {
      setIsAddnPkg(true);
    }
  }, [customerDetails]);
  //address
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken !== undefined) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);
  useEffect(() => {
    if (zone.results && zone.results.length > 0) {
      setCustomerZone(zone?.results[0].id);
    }
  }, [zone]);
  useEffect(() => {
    if (geoFencesMapMyIndia?.status === true) {
      setLatitude(geoFencesMapMyIndia?.latitude);
      setLongitude(geoFencesMapMyIndia?.longitude);
      setCustomerZone(geoFencesMapMyIndia?.customer_zone);

      setCollectionDate(
        moment()
          .add(
            type === "rad" &&
              (centreData.tab_type === "RADI" || centreData.tab_type === "BOTH")
              ? 2
              : type === "rad" &&
                centreData.custom_user_requirement_for !== "EISAI"
              ? 3
              : 1,
            "days"
          )
          .format("YYYY-MM-DD")
      );
      setAreaServicable(true);
      //@ts-ignore
      gtag("event", "manual_location_btn", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        user_pk: customerNumber || customerEmail,
        is_location_enable: false,
        is_serviceable: true,
        locality_name: addressInput,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
        pincode: pincode,
        search_key: addressInput,
        // location_index: "location_index",
        // search_key_count: "search_key_count",
      });

      type !== "rad" && handleCloseLocation();
    } else if (geoFencesMapMyIndia?.status === false) {
      //@ts-ignore
      gtag("event", "manual_location_btn", {
        partner_type: centreData?.otp_mode,
        partner_name: centreData?.center_name,
        user_pk: customerNumber || customerEmail,
        is_location_enable: false,
        is_serviceable: false,
        locality_name: addressInput,
        device_name: getSubstring(navigator.userAgent, "(", ";"),
        pincode: pincode,
        search_key: addressInput,
        // location_index: "location_index",
        // search_key_count: "search_key_count",
      });
      if (
        (centreData.custom_user_requirement_for === "ACCENTURE" ||
          centreData.custom_user_requirement_for === "BOB" ||
          centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData.custom_user_requirement_for === "AON" ||
          centreData.custom_user_requirement_for === "EVAHEL") &&
        centreData?.force_service === true
      ) {
        setLatitude(geoFencesMapMyIndia?.latitude);
        setLongitude(geoFencesMapMyIndia?.longitude);
        setCustomerZone(
          process.env.REACT_APP_ENV === "production" ? 1151 : 1188
        );

        setCollectionDate(
          moment()
            .add(
              type === "rad" &&
                (centreData.tab_type === "RADI" ||
                  centreData.tab_type === "BOTH")
                ? 2
                : centreData.custom_user_requirement_for !== "EISAI"
                ? 3
                : 1,
              "days"
            )
            .format("YYYY-MM-DD")
        );
        setPincode("201301");
        type !== "rad" && handleCloseLocation();
      } else {
        setLatitude("");
        setLongitude("");
      }

      setAreaServicable(false);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (customerAddress && customerAddress.placeAddress) {
      setAddressInput(
        customerAddress.placeName + ", " + customerAddress.placeAddress
      );
      setCentreName({
        center: { name: "" },
        address: "",
      });
    }
    setCustomerCity(customerAddress?.addressTokens?.city);
    if (customerAddress?.addressTokens?.city === "") {
      setCityName(false);
    }
    if (customerAddress?.eLoc) {
      dispatch(
        checkGeoFenchMapMyIndia({
          eloc: customerAddress?.eLoc,
          noErr:
            ((centreData.custom_user_requirement_for === "ACCENTURE" ||
              centreData.custom_user_requirement_for === "BOB" ||
              centreData.custom_user_requirement_for === "HITACHI" ||
              centreData.custom_user_requirement_for === "EISAI" ||
              centreData.custom_user_requirement_for === "RECI" ||
              centreData.custom_user_requirement_for === "AON" ||
              centreData.custom_user_requirement_for === "EVAHEL") &&
              centreData?.force_service === true) ||
            type == "rad" ||
            centreData.custom_user_requirement_for === "RSTAR"
              ? true
              : false,
        })
      );
    }
  }, [customerAddress]);
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        //@ts-ignore
        window[callbackName] = (data: any) => {
          //@ts-ignore
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  const selectAddress = (option: any) => {
    setCustomerCity("");
    setCustomerAddress(option);
  };

  const verifyName = () => {
    const nameRegex = /^([a-zA-Z ]){2,30}$/;
    if (customerName && !nameRegex.test(customerName.toString())) {
      setNameValidation(false);
    }
  };

  const verifyAge = () => {
    if (customerAge < 5) {
      setAgeValidation(false);
    }
  };
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E", "."].includes(e.key) && e.preventDefault();
  useEffect(() => {
    if (latitude && accessToken && longitude && customerAddress === "") {
      dispatch(
        getLocationrRevGeoCode({
          token: accessToken,
          lng: longitude,
          lat: latitude,
        })
      );
    }
  }, [accessToken, latitude]);
  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]/;

    if (customerNumber && !mobileRegEx.test(customerNumber.toString())) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };
  const submitBooking = async (credit: any, payMode: any) => {
    let packages: any = [];
    customerPackages.length > 0 &&
      customerPackages.map((val: any) => {
        packages.push(val.code);
      });
    let packagesId: any = [];
    customerPackages.length > 0 &&
      customerPackages.map((val: any) => {
        packagesId.push(val.id);
      });

    const data = {
      booking_date: moment().format("YYYY-MM-DD"),
      collection_date: collectionDate,
      collection_slot: collectionSlot.id || "",
      customer_name: customerName,
      customer_age: customerAge,
      customer_gender: customerGender,
      customer_email: customerEmail
        ? customerEmail
        : "customer.reports@redcliffelabs.com",
      customer_phonenumber: customerNumber,
      customer_altphonenumber: customerNumber,
      customer_whatsapppnumber: customerNumber,
      customer_address: `${
        customerHouseNo ? "House/Flat/Floor No:" + customerHouseNo + "," : ""
      }${customerRoad ? "Road/Apt./Area:" + customerRoad + "," : ""}`,
      customer_landmark: direction,
      landmark: addressInput,
      pincode: customerAddress?.addressTokens?.pincode
        ? customerAddress.addressTokens.pincode
        : pincode
        ? pincode
        : "201301",
      package_code: packages,
      is_credit: credit,
      additional_member: customerDetails,
      customer_longitude: longitude,
      customer_latitude: latitude,
      reference_data:
        centreData.custom_user_requirement_for === "ACCENTURE" &&
        !areaServicable
          ? "iframe2non_serviceable"
          : "iframe2",
      coupon_applied: coupon,
      center_3p_config_id: centreData.id,
      payment_mode: payMode,
      book_for: relation,
    };
    const dataRad = {
      booking_date: moment().format("YYYY-MM-DD"),
      collection_date: collectionDate,
      collection_slot: collectionSlot.id || "",
      customer_name: customerName,
      customer_age: customerAge,
      customer_gender: customerGender,
      customer_email: customerEmail
        ? customerEmail
        : "customer.reports@redcliffelabs.com",
      customer_phonenumber: customerNumber,
      customer_altphonenumber: customerNumber,
      customer_whatsapppnumber: customerNumber,
      customer_landmark: addressInput,
      packages: packagesId,
      additional_member:
        centreData.tab_type === "RADI" || centreData.tab_type === "BOTH"
          ? customerDetails
          : [],
      customer_longitude: longitude,
      customer_latitude: latitude,
      reference_data:
        (centreData.custom_user_requirement_for === "ACCENTURE" ||
          centreData.custom_user_requirement_for === "BOB" ||
          centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RECI" ||
          centreData.custom_user_requirement_for === "AON" ||
          centreData.custom_user_requirement_for === "EVAHEL") &&
        !areaServicable
          ? "iframe2non_serviceable"
          : "iframe2",
      center_3p_config_id: centreData.id,
      center_address: centreName?.id,
      customer_city: aliasCities?.results[0]?.city?.id || "",
      payment_mode: credit ? "credit" : "online",
      cityid: aliasCities?.results[0]?.city?.id || "",
      display_name: aliasCities?.results[0]?.city?.display_name || "",
      center: centreData?.center,
      book_for: relation,
      is_credit: credit,
    };
    if (relation === "") {
      delete data.book_for;
    }
    if (relation === "") {
      delete dataRad.book_for;
    }
    if (direction === "") {
      delete data.customer_landmark;
    }
    if (coupon === "") {
      delete data.coupon_applied;
    }
    if (payMode === "") {
      delete data.payment_mode;
    }
    if (type === "rad") {
      await dispatch(
        createBookingRad({
          data: dataRad,
          key: partnerKey?.center_key,
          url: "",
        })
      );
      if(Number(centreData?.booking_limit_count)===1){
        SecureStorage.removeItem("tokenIframe");
      }
    } else {
      await dispatch(
        createBooking({
          data: data,
          key: partnerKey?.center_key,
          url:
            centreData.custom_user_requirement_for === "ACCENTURE" &&
            !areaServicable
              ? "?force=true"
              : "",
        })
      );
    }
  };
  const cancelBooking = async () => {
    const data = {
      booking_status: "cancelled",
      booking_id:
        bookingData && bookingData.length > 0 ? bookingData[0].pk : undefined,
      remark: cancelRemarks,
    };
    const data2 = {
      iframe_event: "CAN",
      panel_user: "12363",
      center: centreData?.center,
    };
    await dispatch(updateBooking({ data: data, key: partnerKey?.center_key }));
    await dispatch(
      updateLead({
        id:
          bookingData && bookingData.length > 0
            ? bookingData[0].lead
            : undefined,
        data: data2,
        key: partnerKey?.center_key,
      })
    );
  };
  const bookingUpdated = useSelector(
    (state: any) => state.webAppSlice.bookingUpdated
  );
  useEffect(() => {
    if (
      bookingData &&
      bookingData.length > 0 &&
      bookingData[0]?.customer_areapincode &&
      bookingData[0]?.customer_areapincode?.zone
    ) {
      if (
        (process.env.REACT_APP_ENV === "production" &&
          bookingData[0]?.customer_areapincode.zone === 1151) ||
        (process.env.REACT_APP_ENV === "development" &&
          bookingData[0]?.customer_areapincode.zone === 1188)
      ) {
        setAreaServicable(false);
      }
    }
  }, [bookingData]);
  useEffect(() => {
    if (bookingUpdated?.booking_id && cancel) {
      if (bookingUpdated?.booking_status === "rescheduled") {
        setCancel(false);
        setReschedule(true);
      }
      setOpenCancel(false);
      setOpenConfirmedModal(true);
      dispatch(resetState());
    }
  }, [bookingUpdated, cancel]);
  useEffect(() => {
    if (prevLeadData?.results && prevLeadData?.results.length > 0) {
      setLeadId(prevLeadData?.results[0].id);
    } else {
      setLeadId("");
    }
  }, [prevLeadData]);
  const dropOffLead = async () => {
    const data = {
      mobile: customerNumber,
      iframe_event: "DROP_OFF",
      panel_user: "12363",
      lead_type: "b2b",
    };
    if (leadId) {
      await dispatch(
        updateLead({
          id: leadId,
          data: data,
          key: partnerKey?.center_key,
        })
      );
    } else {
      //@ts-ignore
      window.dataLayer.push({
        mobile: customerNumber,
      });
      await dispatch(createLead({ data: data, key: partnerKey?.center_key }));
    }
  };
  const locationLead = async () => {
    const data = {
      mobile: customerNumber,
      patient_name: fullName,
      iframe_event: "NSA",
      city_name: addressInput,
      panel_user: "12363",
      center: centreData?.center,
      lead_type: "b2b",
    };
    if (leadId) {
      await dispatch(
        updateLead({
          id: leadId,
          data: data,
          key: partnerKey?.center_key,
        })
      );
    } else {
      await dispatch(createLead({ data: data, key: partnerKey?.center_key }));
    }
  };
  useEffect(() => {
    if (areaServicable === false && customerNumber) {
      locationLead();
    }
  }, [areaServicable]);

  const handleCheck = (checked: any, value: any) => {
    if (checked) {
      if (value === "Other") {
        setCancelRemarks("");
        setIsOther(true);
      } else {
        setIsOther(false);
        setCancelRemarks(value);
      }
    } else if (!checked) {
      if (value === "Other") {
        setCancelRemarks("");
        setIsOther(false);
      } else {
        setCancelRemarks("");
      }
    }
  };
  const [file, setFile] = useState<any>("");
  const submitPrescription = async (id: any) => {
    const formData = new FormData();
    formData.append("name", customerName);
    formData.append("lead", id);
    formData.append("age", customerAge);
    formData.append(
      "gender",
      customerGender === "male" ? "M" : customerGender === "female" ? "F" : "O"
    );
    formData.append("prescription", file);
    await dispatch(
      uploadPrescription({ data: formData, key: partnerKey?.center_key })
    );
  };
  useEffect(() => {
    if (relation === "self" && !customerName) {
      setCustomerName(fullName);
    }
  }, [relation]);

  return (
    <ThemeProvider theme={theme}>
      <div className="containerMain">
        {!clientType && loading ? (
          <Loader />
        ) : pageLoaded && type === "path" ? (
          <>
            <Header
              isFilter={false}
              addressInput={addressInput}
              setLocationOpen={setLocationOpen}
            />
            <Box sx={{ flexGrow: 1 }}>
              {prevBookingData && prevBookingData.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    background: "rgba(0, 150, 136, 0.1)",
                    borderRadius: "5px",
                    margin: 15,
                    padding: 15,
                  }}
                >
                  <div style={{ width: "10%" }}>
                    <div
                      style={{
                        background: "#FFF",
                        borderRadius: "50%",
                        textAlign: "center",
                        width: "25px",
                        height: "25px",
                        padding: "2px",
                      }}
                    >
                      <img
                        style={{ width: "6px", marginBottom: "-3px" }}
                        src={infoImg}
                      />
                    </div>
                  </div>
                  <div style={{ width: "90%" }}>
                    Track previous bookings.{" "}
                    <a
                      onClick={() =>
                        window.open(
                          window.location.href + `&tid=${prevBookingData[prevBookingData.length-1].pk}`
                        )
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Click Here
                    </a>
                  </div>
                </div>
              )}

              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid
                  container
                  spacing={2}
                  className={expand ? "dialogContent" : "dialogContentMain"}
                >
                  <Grid item xs={10}>
                    <Typography component="p">YOUR DETAILS</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right" }}>
                    {expand ? (
                      <ExpandLessIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpand(false)}
                      />
                    ) : (
                      <ExpandMoreIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpand(true)}
                      />
                    )}
                  </Grid>
                  {expand ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          className="input"
                          id="outlined-required"
                          label="Full Name"
                          color="primary"
                          value={fullName}
                          onBlur={() => setLocalData()}
                          onChange={(e) =>
                            String(e.target.value).length <= 30
                              ? setFullName(
                                  e.target.value
                                    .replace(/[^a-zA-Z ]/g, "")
                                    .trimStart()
                                )
                              : ""
                          }
                          required
                          disabled={
                            clientType !== "NV" ||
                            employeeDetails?.data?.customer_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          key={
                            String(customerNumber)?.length === 10 ? "0" : "1"
                          }
                          type="number"
                          label="Mobile Number"
                          value={customerNumber}
                          variant="outlined"
                          onWheel={(e: any) => e.target.blur()}
                          onInput={(e: any) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          disabled={
                            fullName === "" ||
                            clientType !== "NV" ||
                            employeeDetails?.data?.phonenumber
                          }
                          fullWidth
                          required
                          onChange={(e) => setCustomerNumber(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                        />
                        <sub style={{ color: "red" }}>
                          {!mobileValidation
                            ? "Please enter valid mobile number"
                            : ""}
                        </sub>
                      </Grid>
                      {centreData.custom_user_requirement_for === "ACCENTURE" ||
                      centreData.custom_user_requirement_for === "BOB" ? (
                        <Grid item xs={12}>
                          <TextField
                            type="email"
                            label="Email Address"
                            value={customerEmail}
                            disabled
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Card>
              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid container spacing={2} className="dialogContent">
                  <Grid item xs={12}>
                    <Typography component="p">MEMBER INFORMATION</Typography>
                  </Grid>
                  {showMain ? (
                    <PatientInfo
                      name={customerName}
                      age={customerAge}
                      gender={customerGender}
                      packages={customerPackages}
                      variant={"outlined"}
                      member={1}
                      onClick={() => setShowMain(false)}
                    />
                  ) : (
                    <>
                      <Grid item xs={8}>
                        Member 1
                      </Grid>
                      <Grid item xs={4} textAlign="right">
                        {!centreData?.has_partner_user_detail &&
                        centreData.custom_user_requirement_for !==
                          "ACCENTURE" &&
                        centreData.custom_user_requirement_for !== "BOB" &&
                        (myself === false ||
                          (myself === true && fullName === customerName)) ? (
                          <FormControlLabel
                            style={{ margin: "0px" }}
                            control={
                              <Checkbox
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                                checked={
                                  myself === true && fullName === customerName
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  e.target.checked
                                    ? (setCustomerName(fullName),
                                      setMyself(true))
                                    : (setCustomerName(""), setMyself(false))
                                }
                              />
                            }
                            label="Myself"
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                      {(centreData.custom_user_requirement_for ===
                        "ACCENTURE" ||
                        centreData.custom_user_requirement_for === "BOB") && (
                        <Grid item xs={12}>
                          <TextField
                            select
                            fullWidth
                            color="primary"
                            className="input"
                            label="Relationship"
                            required
                            key={relation}
                            onChange={(e) => setRelation(e.target.value)}
                            value={relation}
                          >
                            <MenuItem
                              value="self"
                              disabled={
                                customerDetails.length > 0 &&
                                customerDetails.find(
                                  (val: any) => val.book_for === "self"
                                ) !== undefined
                              }
                            >
                              Self
                            </MenuItem>
                            <MenuItem
                              value="spouse"
                              disabled={
                                customerDetails.length > 0 &&
                                customerDetails.find(
                                  (val: any) => val.book_for === "spouse"
                                ) !== undefined
                              }
                            >
                              Spouse
                            </MenuItem>
                            <MenuItem value="son_in_law">Son</MenuItem>
                            <MenuItem value="daughter_in_law">
                              Daughter
                            </MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {centreData?.has_addtional_member_data &&
                        allEmpDetails.length > 0 ? (
                          <TextField
                            select
                            fullWidth
                            color="primary"
                            className="input"
                            label="Name"
                            required
                            key={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                            value={customerName}
                          >
                            {allEmpDetails.length > 0 &&
                              allEmpDetails.map((val: any) => (
                                <MenuItem
                                  value={val.customer_name}
                                  disabled={
                                    customerDetails.length > 0 &&
                                    customerDetails.find(
                                      (val1: any) =>
                                        val1.customerName === val.customer_name
                                    )
                                  }
                                  onClick={() => {
                                    setCustomerAge(val.customer_age);
                                    setCustomerGender(val.customer_gender);
                                  }}
                                >
                                  {val?.customer_name}
                                </MenuItem>
                              ))}
                          </TextField>
                        ) : (
                          <TextField
                            fullWidth
                            color="primary"
                            className="input"
                            id="outlined-required"
                            label="Name"
                            value={customerName}
                            required
                            disabled={employeeDetails?.data?.customer_name&&centreData.custom_user_requirement_for !== "RSTAR"?true:false}
                            onBlur={() => verifyName()}
                            onFocus={() => {
                              setNameValidation(true);
                            }}
                            onChange={(e) =>
                              setCustomerName(
                                String(e.target.value).trimStart()
                              )
                            }
                          />
                        )}

                        <sub style={{ color: "red" }}>
                          {!nameValidation
                            ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                            : ""}
                        </sub>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          color="primary"
                          className="input"
                          id="outlined-required"
                          label="Age"
                          type="number"
                          disabled={employeeDetails?.data?.customer_age&&centreData.custom_user_requirement_for !== "RSTAR"?true:false}
                          value={customerAge}
                          onBlur={() => verifyAge()}
                          onFocus={() => {
                            setAgeValidation(true);
                          }}
                          onKeyDown={(e) => blockInvalidChar(e)}
                          variant="outlined"
                          required
                          onChange={(e) =>
                            Number(e.target.value) < 110
                              ? setCustomerAge(e.target.value)
                              : ""
                          }
                        />
                        <sub style={{ color: "red" }}>
                          {!ageValidation
                            ? "Please enter valid age, age should be 4+ Years"
                            : ""}
                        </sub>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          color="primary"
                          className="input"
                          id="outlined-required"
                          disabled={employeeDetails?.data?.customer_gender&&centreData.custom_user_requirement_for !== "RSTAR"?true:false}
                          label="Gender"
                          key={customerGender}
                          required
                          select
                          value={customerGender}
                          onChange={(e) => setCustomerGender(e.target.value)}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </TextField>
                        <sub style={{ color: "red" }}>
                          {!genderValidation && !customerGender
                            ? "Please select gender"
                            : ""}
                        </sub>
                      </Grid>

                      {customerPackages && customerPackages.length > 0 ? (
                        <Grid item xs={12}>
                          {" "}
                          Package Selected
                        </Grid>
                      ) : (
                        ""
                      )}
                      {customerPackages &&
                        customerPackages.length > 0 &&
                        customerPackages.map((val: any, index: any) => {
                          return (
                            <Grid item xs={12}>
                              <div
                                style={{
                                  background: "rgba(209, 64, 72, 0.2)",
                                  borderRadius: "5px",
                                  color: "#E06358",
                                  padding: "5px 0px 1px 14px",
                                  display: "flex",
                                }}
                              >
                                <div style={{ width: "90%" }}>{val?.name}</div>
                                <div style={{ width: "10%" }}>
                                  {empPackages.length == 1 ||
                                  centreData.custom_user_requirement_for ===
                                    "RSTAR" ? (
                                    ""
                                  ) : centreData.custom_user_requirement_for ===
                                      "ACCENTURE" ||
                                    centreData.custom_user_requirement_for ===
                                      "BOB" ? (
                                    ""
                                  ) : (
                                    <CloseIcon
                                      onClick={() =>
                                        removePackage("", index, "")
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {(centreData.custom_user_requirement_for ===
                                "ACCENTURE" ||
                                centreData.custom_user_requirement_for ===
                                  "BOB") &&
                              customerGender &&
                              val.recommended_gender &&
                              val.recommended_gender !== customerGender &&
                              customerGender !== "other" &&
                              val.recommended_gender !== "both" ? (
                                <sub style={{ color: "red" }}>
                                  Selected package is not for {customerGender}.
                                  Please change package.
                                </sub>
                              ) : (
                                ""
                              )}
                            </Grid>
                          );
                        })}
                      <Grid item xs={12}>
                        {centreData.custom_user_requirement_for === "RSTAR" ? (
                          <StyledButton
                            onClick={() => {
                              handlePackage("");
                            }}
                          >
                            SELECT
                            {customerPackages && customerPackages.length > 0
                              ? " MORE"
                              : ""}{" "}
                            PACKAGES
                          </StyledButton>
                        ) : empPackages.length !== 1 &&
                          centreData.custom_user_requirement_for !==
                            "ACCENTURE" &&
                          centreData.custom_user_requirement_for !== "BOB" &&
                          centreData?.tab_type !== "RADI" &&
                          centreData?.tab_type !== "BOTH" ? (
                          <StyledButton
                            onClick={() => {
                              handlePackage("");
                            }}
                            disabled={empPackages.length === 1}
                          >
                            SELECT
                            {customerPackages && customerPackages.length > 0
                              ? " MORE"
                              : ""}{" "}
                            PACKAGES
                          </StyledButton>
                        ) : (
                          <StyledButton
                            onClick={() => {
                              setAddIndex("");
                              setAccOpenPackageModal(true);
                              //@ts-ignore
                              gtag("event", "select_package_btn", {
                                gender: customerGender,
                                relationship: relation,
                                user_pk: customerEmail || customerNumber,
                                age: customerAge,
                              });
                            }}
                          >
                            CHANGE PACKAGE
                          </StyledButton>
                        )}
                      </Grid>
                    </>
                  )}

                  {customerDetails &&
                    customerDetails.length > 0 &&
                    customerDetails.map((val: any, index: any) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  width: "calc(100% - 72px)",
                                  marginTop: 15,
                                }}
                              >
                                <Divider />
                              </div>
                              <div
                                style={{
                                  width: "72px",
                                  textAlign: "right",
                                }}
                              >
                                <Button
                                  style={{
                                    textTransform: "none",
                                    color: "rgba(38, 119, 240, 1)",
                                  }}
                                  onClick={() => removeMember(index)}
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          {val.isShow ? (
                            <PatientInfo
                              name={val.customerName}
                              age={val.customerAge}
                              gender={val.customerGender}
                              packages={val.packages}
                              variant={"outlined"}
                              member={index + 2}
                              onClick={() =>
                                updateMember(index, false, "isShow")
                              }
                            />
                          ) : (
                            <Grid container style={{ marginTop: "-15px" }}>
                              <Grid item xs={9}>
                                Member {index + 2}
                              </Grid>
                              <Grid item xs={3} textAlign="right">
                                {(centreData.custom_user_requirement_for !==
                                  "ACCENTURE" &&
                                  centreData.custom_user_requirement_for !==
                                    "BOB" &&
                                  myself === false) ||
                                (centreData.custom_user_requirement_for !==
                                  "ACCENTURE" &&
                                  centreData.custom_user_requirement_for !==
                                    "BOB" &&
                                  myself &&
                                  fullName === val.customerName) ? (
                                  <FormControlLabel
                                    style={{ margin: "0px" }}
                                    control={
                                      <Checkbox
                                        icon={<RadioButtonUncheckedIcon />}
                                        checkedIcon={<RadioButtonCheckedIcon />}
                                        checked={
                                          myself &&
                                          fullName === val.customerName
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          e.target.checked
                                            ? (updateMember(
                                                index,
                                                fullName,
                                                "customerName"
                                              ),
                                              setMyself(true))
                                            : (updateMember(
                                                index,
                                                "",
                                                "customerName"
                                              ),
                                              setMyself(false))
                                        }
                                      />
                                    }
                                    label="Myself"
                                  />
                                ) : (
                                  ""
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                {(centreData.custom_user_requirement_for ===
                                  "ACCENTURE" ||
                                  centreData.custom_user_requirement_for ===
                                    "BOB") && (
                                  <TextField
                                    select
                                    fullWidth
                                    color="primary"
                                    className="input"
                                    key={val?.book_for}
                                    label="Relationship"
                                    required
                                    onChange={(e) => {
                                      updateMember(
                                        index,
                                        String(e.target.value),
                                        "book_for"
                                      );
                                      e.target.value === "self" &&
                                        val.customerName === "" &&
                                        updateMember(
                                          index,
                                          fullName,
                                          "customerName"
                                        );
                                    }}
                                    value={val?.book_for}
                                  >
                                    <MenuItem
                                      value="self"
                                      disabled={
                                        relation === "self" ||
                                        customerDetails.find(
                                          (val: any) => val.book_for === "self"
                                        ) !== undefined
                                      }
                                    >
                                      Self
                                    </MenuItem>
                                    <MenuItem
                                      value="spouse"
                                      disabled={
                                        relation === "spouse" ||
                                        customerDetails.find(
                                          (val: any) =>
                                            val.book_for === "spouse"
                                        ) !== undefined
                                      }
                                    >
                                      Spouse
                                    </MenuItem>
                                    <MenuItem value="son_in_law">Son</MenuItem>
                                    <MenuItem value="daughter_in_law">
                                      Daughter
                                    </MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                  </TextField>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                {centreData?.has_addtional_member_data &&
                                allEmpDetails.length > 0 ? (
                                  <TextField
                                    select
                                    fullWidth
                                    color="primary"
                                    className="input"
                                    label="Name"
                                    required
                                    key={val.customerName}
                                    onChange={(e) =>
                                      updateMember(
                                        index,
                                        String(e.target.value).trimStart(),
                                        "customerName"
                                      )
                                    }
                                    value={val.customerName}
                                  >
                                    {allEmpDetails.length > 0 &&
                                      allEmpDetails.map((val: any) => (
                                        <MenuItem
                                          value={val.customer_name}
                                          disabled={
                                            val.customer_name ===
                                              customerName ||
                                            customerDetails.find(
                                              (val1: any) =>
                                                val1.customerName ===
                                                val.customer_name
                                            )
                                          }
                                          onClick={() => {
                                            updateMember(
                                              index,
                                              val.customer_age,
                                              "customerAge"
                                            );
                                            updateMember(
                                              index,
                                              val.customer_gender,
                                              "customerGender"
                                            );
                                          }}
                                        >
                                          {val?.customer_name}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                ) : (
                                  <TextField
                                    fullWidth
                                    color="primary"
                                    className="input"
                                    id="outlined-required"
                                    label="Name"
                                    value={val.customerName}
                                    variant="outlined"
                                    required
                                    onChange={(e) =>
                                      updateMember(
                                        index,
                                        String(e.target.value).trimStart(),
                                        "customerName"
                                      )
                                    }
                                  />
                                )}
                                <sub style={{ color: "red" }}>
                                  {/^([a-zA-Z ]){2,30}$/.test(
                                    val.customerName
                                  ) === false && val.customerName !== ""
                                    ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                                    : ""}
                                </sub>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  color="primary"
                                  className="input"
                                  id="outlined-required"
                                  label="Age"
                                  type="number"
                                  value={val.customerAge}
                                  variant="outlined"
                                  onKeyDown={(e) => blockInvalidChar(e)}
                                  required
                                  onChange={(e) =>
                                    Number(e.target.value) < 110
                                      ? updateMember(
                                          index,
                                          e.target.value,
                                          "customerAge"
                                        )
                                      : ""
                                  }
                                />
                                <sub style={{ color: "red" }}>
                                  {Number(val?.customerAge) < 5 &&
                                  val?.customerAge !== ""
                                    ? "Please enter valid age, age should be 4+ Years"
                                    : ""}
                                </sub>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  color="primary"
                                  className="input"
                                  id="outlined-required"
                                  label="Gender"
                                  select
                                  value={val.customerGender}
                                  key={val.customerGender}
                                  variant="outlined"
                                  required
                                  onChange={(e) =>
                                    updateMember(
                                      index,
                                      e.target.value,
                                      "customerGender"
                                    )
                                  }
                                >
                                  <MenuItem value="male">Male</MenuItem>
                                  <MenuItem value="female">Female</MenuItem>
                                  <MenuItem value="other">Other</MenuItem>
                                </TextField>
                                <sub style={{ color: "red" }}>
                                  {!genderValidation &&
                                  val?.customerGender === ""
                                    ? "Please select gender"
                                    : ""}
                                </sub>
                              </Grid>

                              {val.packages && val.packages.length > 0 ? (
                                <Grid item xs={12}>
                                  {" "}
                                  Package Selected
                                </Grid>
                              ) : (
                                ""
                              )}
                              {val.packages &&
                                val.packages.length > 0 &&
                                val.packages.map((data: any, index1: any) => {
                                  return (
                                    <Grid item xs={12}>
                                      <div
                                        style={{
                                          background: "rgba(209, 64, 72, 0.2)",
                                          borderRadius: "5px",
                                          color: "#E06358",
                                          padding: "5px 0px 1px 14px",
                                          display: "flex",
                                        }}
                                      >
                                        <div style={{ width: "90%" }}>
                                          {data?.name}
                                        </div>
                                        <div style={{ width: "10%" }}>
                                          {empPackages.length == 1 ? (
                                            ""
                                          ) : centreData.custom_user_requirement_for ===
                                              "ACCENTURE" ||
                                            centreData.custom_user_requirement_for ===
                                              "RSTAR" ||
                                            centreData.custom_user_requirement_for ===
                                              "BOB" ? (
                                            ""
                                          ) : (
                                            <CloseIcon
                                              onClick={() =>
                                                removePackage(
                                                  data,
                                                  index1,
                                                  index
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {(centreData.custom_user_requirement_for ===
                                        "ACCENTURE" ||
                                        centreData.custom_user_requirement_for ===
                                          "BOB") &&
                                      val?.customerGender &&
                                      data.recommended_gender &&
                                      data.recommended_gender !==
                                        val?.customerGender &&
                                      val?.customerGender !== "other" &&
                                      data.recommended_gender !== "both" ? (
                                        <sub style={{ color: "red" }}>
                                          Selected package is not for{" "}
                                          {val?.customerGender}. Please change
                                          package.
                                        </sub>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  );
                                })}
                              <Grid item xs={12}>
                                {empPackages.length !== 1 &&
                                centreData.custom_user_requirement_for !==
                                  "ACCENTURE" &&
                                centreData.custom_user_requirement_for !==
                                  "BOB" &&
                                centreData?.tab_type !== "RADI" &&
                                centreData?.tab_type !== "BOTH" ? (
                                  <StyledButton
                                    onClick={() => handlePackage(index)}
                                    disabled={empPackages.length === 1}
                                  >
                                    SELECT
                                    {val.packages && val.packages.length > 0
                                      ? " MORE"
                                      : ""}{" "}
                                    PACKAGES
                                  </StyledButton>
                                ) : centreData.custom_user_requirement_for ===
                                    "ACCENTURE" ||
                                  centreData.custom_user_requirement_for ===
                                    "BOB" ||
                                  centreData?.tab_type === "RADI" ||
                                  centreData?.tab_type === "BOTH" ? (
                                  <StyledButton
                                    onClick={() => handlePackageAcc(index)}
                                  >
                                    {val.packages && val.packages.length > 0
                                      ? "CHANGE "
                                      : "SELECT "}
                                    PACKAGE
                                  </StyledButton>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </>
                      );
                    })}
                </Grid>
              </Card>
              {customerDetails.length <
              (centreData.custom_user_requirement_for !== "ACCENTURE" &&
              centreData.custom_user_requirement_for !== "BOB"
                ? 4
                : 3)
                ? isAdditional && (
                    <div style={{ margin: "0 15px" }}>
                      <StyledButtonLight
                        onClick={() => addMember()}
                        disabled={
                          customerPackages?.length === 0 ||
                          addressInput === "" ||
                          !mobileValidation ||
                          !nameValidation ||
                          !ageValidation ||
                          customerGender === "" ||
                          (customerDetails.length > 0 && !isAddnPkg) ||
                          !isAdditional
                        }
                      >
                        <AddCircleOutlineIcon
                          style={{
                            fontSize: 19,
                            marginRight: 5,
                            marginBottom: "-4px",
                          }}
                        />
                        <span>ADD MORE MEMBER</span>
                      </StyledButtonLight>
                    </div>
                  )
                : ""}
              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid container spacing={2} className="dialogContent">
                  <Grid item xs={12}>
                    <Typography component="p">ADDRESS</Typography>
                  </Grid>
                  {addressInput ? (
                    <>
                      <Grid item xs={1}>
                        <PlaceOutlinedIcon color="primary" />
                      </Grid>
                      <Grid item xs={7}>
                        <Typography component="p">{addressInput}</Typography>
                      </Grid>
                      <Grid item xs={4} textAlign="right">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => setLocationOpen(true)}
                        >
                          Change
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid item xs={12}>
                    <div
                      style={{
                        padding: "6px 14px",
                        borderRadius: 5,
                        background: "rgba(209, 64, 72, 0.2)",
                        color: "#D14048",
                        fontSize: "12px",
                      }}
                    >
                      i. A detailed address will help our phlebotimist reach
                      your doorstep easily.
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      color="primary"
                      className="input"
                      id="outlined-required"
                      label="House / Flat / Floor No."
                      value={customerHouseNo}
                      onChange={(e) =>
                        String(e.target.value).length >= 50
                          ? ""
                          : setCustomerHouseNo(
                              e.target.value
                                .replace(/[^a-zA-Z0-9\/\\\,\-\#\.\" "]/, "")
                                .trimStart()
                            )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="primary"
                      className="input"
                      id="outlined-required"
                      label="Road/ Apartment/ Area"
                      value={customerRoad}
                      onChange={(e) =>
                        String(e.target.value).length >= 50
                          ? ""
                          : setCustomerRoad(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      color="primary"
                      className="input"
                      id="outlined-required"
                      label="Directions To Reach"
                      required
                      value={direction}
                      onChange={(e) =>
                        String(e.target.value).length >= 50
                          ? ""
                          : setDirection(e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Card>
              <div style={{ margin: "0 15px 15px" }}>
                <StyledButton
                  onClick={() => {
                    setOpenSlotModal(true);
                    //@ts-ignore
                    gtag("event", "member_info_next_btn", {
                      camp_start_date: centreData?.expire_start_time,
                      camp_end_date: centreData?.expire_end_time,
                      user_pk: customerNumber || customerEmail,
                      cart_value: subTotal,
                      member_count: customerDetails.length + 1,
                      partner_name: centreData?.center_name,
                      is_subscription: centreData?.is_subscription,
                      package_selected: pkgTotal,
                    });
                  }}
                  disabled={
                    customerPackages?.length === 0 ||
                    addressInput === "" ||
                    direction === "" ||
                    !mobileValidation ||
                    customerNumber.length !== 10 ||
                    !isAddnPkg ||
                    !nameValidation ||
                    !ageValidation ||
                    customerGender === "" ||
                    customerHouseNo === "" ||
                    !customerName ||
                    !fullName ||
                    ((centreData.custom_user_requirement_for === "ACCENTURE" ||
                      centreData.custom_user_requirement_for === "BOB") &&
                      !relation) ||
                    (customerDetails.length > 0 &&
                      (centreData.custom_user_requirement_for === "ACCENTURE" ||
                        centreData.custom_user_requirement_for === "BOB") &&
                      customerDetails.filter((val: any) => val.book_for === "")
                        .length > 0)
                  }
                >
                  NEXT
                </StyledButton>
              </div>
              {centreData.custom_user_requirement_for === "ACCENTURE" ||
              centreData.custom_user_requirement_for === "BOB" ? (
                <Grid
                  item
                  xs={12}
                  textAlign={"center"}
                  style={{ marginBottom: "10px" }}
                >
                  <hr style={{ border: "1px solid #ececec" }} />
                  <sup>
                    For any queries/concerns, please write us at
                    <a href="mailto:appointments@redcliffelabs.com">
                      <b> appointments@redcliffelabs.com</b>
                    </a>
                  </sup>
                </Grid>
              ) : (
                ""
              )}
            </Box>
          </>
        ) : pageLoaded &&
          type === "rad" &&
          (centreData.tab_type === "RADI" || centreData.tab_type === "BOTH") ? (
          <RadiologyBooking
            setCustomerName={setCustomerName}
            customerName={customerName}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setCustomerAge={setCustomerAge}
            customerAge={customerAge}
            setCustomerGender={setCustomerGender}
            customerGender={customerGender}
            setCustomerNumber={setCustomerNumber}
            customerNumber={customerNumber}
            setCustomerPackages={setCustomerPackages}
            customerPackages={customerPackages}
            setFullName={setFullName}
            fullName={fullName}
            partnerKey={partnerKey}
            setOpenSlotModal={setOpenSlotModal}
            setCentreName={setCentreName}
            centreName={centreName}
            setRemarks={setRemarks}
            remarks={remarks}
            setFile={setFile}
            file={file}
            setAddIndex={setAddIndex}
            setAccOpenPackageModal={setAccOpenPackageModal}
            latitude={latitude}
            longitude={longitude}
            cityName={cityName}
            setRelation={setRelation}
            relation={relation}
            addressInput={addressInput}
            setLocationOpen={setLocationOpen}
            customerDetails={customerDetails}
            updateMember={updateMember}
            removeMember={removeMember}
            showMain={showMain}
            setShowMain={setShowMain}
            myself={myself}
            setMyself={setMyself}
            removePackage={removePackage}
            isAdditional={isAdditional}
            addMember={addMember}
            handlePackageAcc={handlePackageAcc}
            isAddnPkg={isAddnPkg}
            allEmpDetails={allEmpDetails}
          />
        ) : pageLoaded && type === "rad" ? (
          <Radiology
            setCustomerName={setCustomerName}
            customerName={customerName}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setCustomerAge={setCustomerAge}
            customerAge={customerAge}
            setCustomerGender={setCustomerGender}
            customerGender={customerGender}
            setCustomerNumber={setCustomerNumber}
            customerNumber={customerNumber}
            setCustomerPackages={setCustomerPackages}
            customerPackages={customerPackages}
            setFullName={setFullName}
            fullName={fullName}
            partnerKey={partnerKey}
            setOpenSlotModal={setOpenSlotModal}
            setCentreName={setCentreName}
            centreName={centreName}
            setRemarks={setRemarks}
            remarks={remarks}
            setFile={setFile}
            file={file}
            setAddIndex={setAddIndex}
            setAccOpenPackageModal={setAccOpenPackageModal}
            latitude={latitude}
            longitude={longitude}
            cityName={cityName}
            setRelation={setRelation}
            relation={relation}
            addressInput={addressInput}
            setLocationOpen={setLocationOpen}
          />
        ) : (
          ""
        )}

        <Dialog disableEscapeKeyDown={true}
          open={otpOpen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p" fontWeight={600}>
                Your Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="input"
                id="outlined-required"
                label="Full Name"
                color="primary"
                value={fullName}
                onChange={(e) =>
                  String(e.target.value).length <= 30
                    ? setFullName(
                        e.target.value.replace(/[^a-zA-Z ]/g, "").trimStart()
                      )
                    : ""
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  key={String(customerNumber)?.length === 10 ? "0" : "1"}
                  type="number"
                  label="Mobile Number"
                  value={customerNumber}
                  variant="outlined"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  disabled={fullName === ""}
                  fullWidth
                  required
                  onChange={(e) => setCustomerNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
                <sub style={{ color: "red" }}>
                  {!mobileValidation ? "Please enter valid mobile number" : ""}
                </sub>
              </Grid>
            </Grid>
            {String(customerNumber).length === 10 ? (
              <>
                <Grid item xs={12}>
                  <Typography component="p" color="green">
                    OTP has been shared on your mobile.{" "}
                  </Typography>
                  <Typography component="p">Enter OTP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <OtpInput
                    value={otp}
                    isInputNum={true}
                    numInputs={6}
                    onChange={(e: any) => setOtp(e)}
                    inputStyle={{
                      width: "2rem",
                      height: "2rem",
                      margin: "0 0.4rem",
                      fontSize: "14px",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)",
                      background: "#e7e7e7",
                      color: "#000",
                    }}
                  />
                </Grid>
                {verifiedOtp?.errors ? (
                  <Grid item xs={12}>
                    <Typography component="p" color="red">
                      <CancelOutlinedIcon
                        style={{
                          color: "red",
                          fontSize: 18,
                          marginBottom: "-3px",
                        }}
                      />{" "}
                      Incorrect OTP!
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12}>
                  {seconds !== 0 ? (
                    <p>
                      Resend OTP in
                      <span style={{ color: "#5866E0" }}>
                        {" "}
                        00:{seconds < 10 ? "0" + seconds : seconds}
                      </span>
                    </p>
                  ) : (
                    <p>
                      <span
                        style={{ color: "#5866E0", cursor: "pointer" }}
                        onClick={() => {
                          PostOtp(customerNumber);
                          setSecond(30);
                          //@ts-ignore
                          gtag("event", "trigger_resend_otp", {
                            is_mobile_valid: true,
                            mobile_number: customerNumber,
                            user_pk: customerNumber,
                            device_name: getSubstring(
                              navigator.userAgent,
                              "(",
                              ";"
                            ),
                          });
                        }}
                      >
                        Resend OTP
                      </span>
                    </p>
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Dialog>
        {/* //location */}
        <Dialog disableEscapeKeyDown={true}
          open={locationOpen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleCloseLocation}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          {type === "rad" && cityName === false ? (
            <Grid container spacing={2} className="dialogContent">
              <Grid item xs={12} textAlign={"left"}>
                <Button
                  onClick={() => {
                    setCityName("");
                    setAddressInput("");
                  }}
                >{`<< Back`}</Button>
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <Typography
                  component="p"
                  fontWeight={600}
                  style={{ color: "red" }}
                >
                  Sorry! Currently we don’t have any center at this location.
                  Our team will reach you out to suggest nearest center.
                </Typography>
                <img src={cityImg} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} className="dialogContent">
              <Grid item xs={12}>
                <Typography component="p" fontWeight={600}>
                  Setup Location
                </Typography>
              </Grid>
              <Grid
                className="pointerHover"
                item
                container
                spacing={2}
                onClick={() => getLatLng()}
              >
                <Grid item xs={1}>
                  <GpsFixedIcon />
                </Grid>
                <Grid item xs={9}>
                  <Typography component="p">
                    Use Current Location
                    <br />
                    <span style={{ color: "grey", marginBottom: 25 }}>or</span>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {addrLoading ? <CircularProgress /> : ""}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  color="primary"
                  value={addressInput}
                  type="text"
                  label="Enter your Location Manually"
                  className="input"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {<SearchTwoToneIcon style={{ color: "grey" }} />}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      locationAutoCompleteSearch(e.target.value, e);
                    }, 500);
                    setAddressInput(e.target.value);
                  }}
                  variant="outlined"
                  fullWidth
                  required
                />

                <Grid item xs={12}>
                  {addressInput&&!areaServicable &&
                  centreData.custom_user_requirement_for === "RSTAR" ? (
                    <sub style={{ color: "red" }}>
                      The entered address is non-serviceable please contact{" "}
                      <a href="mailto:appointments@redcliffelabs.com">
                        appointments@redcliffelabs.com
                      </a>
                    </sub>
                  ) : (
                    ""
                  )}

                  <hr />
                </Grid>
                <Grid style={{ maxHeight: "55vh", overflow: "auto" }}>
                  {suggestedAddress.length > 0 &&
                    suggestedAddress.map((option: any) => {
                      return (
                        <Grid container onClick={() => selectAddress(option)}>
                          <Grid container className="locationSelect">
                            <Grid item xs={2} className="centerContainer">
                              <PlaceOutlinedIcon className="centerItem" />
                            </Grid>
                            <Grid item xs={10}>
                              {option?.placeName}, {option?.placeAddress}
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <hr />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          )}
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          open={openPackageModal}
          fullScreen
          onClose={handleCloseLocation}
          aria-describedby="alert-dialog-slide-description"
        >
          <Package
            setConfirmType={setConfirmType}
            confirmType={confirmType}
            setOpenConfirmedModal={setOpenConfirmedModal}
            setOpenPackageModal={setOpenPackageModal}
            openPackageModal={openPackageModal}
            updateMember={updateMember}
            setCustomerPackages={setCustomerPackages}
            addIndex={addIndex}
            partnerKey={partnerKey}
            customerPackages={customerPackages}
            couponPackage={couponPackage}
            customerDetails={customerDetails}
            customerGender={customerGender}
            mainMember={{
              customerName: customerName,
              customerAge: customerAge,
              customerNumber: customerNumber,
              customerGender: customerGender,
            }}
            loading={loading}
            centreData={centreData}
            empPackages={empPackages}
            leadId={leadId}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          open={accOpenPackageModal}
          fullScreen
          aria-describedby="alert-dialog-package"
        >
          {centreData.tab_type === "RADI" || centreData.tab_type === "BOTH" ? (
            <RadiologyPackage
              setAccOpenPackageModal={setAccOpenPackageModal}
              setCustomerPackages={setCustomerPackages}
              partnerKey={partnerKey}
              loading={loading}
              centreData={centreData}
              updateMember={updateMember}
              addIndex={addIndex}
              type={type}
              setType={setType}
              setCentreName={setCentreName}
              setLocationOpen={setLocationOpen}
              addressInput={addressInput}
              packages={packages}
              refData={refData}
            />
          ) : (
            <AccPackage
              setAccOpenPackageModal={setAccOpenPackageModal}
              setCustomerPackages={setCustomerPackages}
              partnerKey={partnerKey}
              loading={loading}
              centreData={centreData}
              updateMember={updateMember}
              addIndex={addIndex}
              type={type}
              setType={setType}
              setCentreName={setCentreName}
              setLocationOpen={setLocationOpen}
              addressInput={addressInput}
              refData={refData}
            />
          )}
        </Dialog>

        <Dialog disableEscapeKeyDown={true}
          fullScreen={!cancel}
          open={openSlotModal}
          // onClose={() => setOpenSlotModal(false)}
          keepMounted={cancel}
          aria-describedby="alert-dialog-slide-description"
          className={cancel ? "dialogBottom" : ""}
        >
          <CollectionSlotPage
            setOpenSlotModal={setOpenSlotModal}
            customerEmail={customerEmail}
            customerNumber={customerNumber}
            setCollectionDate={setCollectionDate}
            collectionDate={collectionDate}
            setCollectionSlot={setCollectionSlot}
            collectionSlot={collectionSlot}
            partnerKey={partnerKey}
            latitude={latitude}
            longitude={longitude}
            setOpenCouponModal={setOpenCouponModal}
            customerPackages={customerPackages}
            reschedule={reschedule}
            setCancel={setCancel}
            cancel={cancel}
            loading={loading}
            setOpenTracking={setOpenTracking}
            setOpenCancel={setOpenCancel}
            areaServicable={areaServicable}
            pincode={pincode}
            customerDetails={customerDetails}
            type={type}
            bookingData={
              bookingData && bookingData.length > 0 ? bookingData[0] : ""
            }
            customerZone={customerZone}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={openCouponModal}
          onClose={() => setOpenCouponModal(false)}
        >
          <OrderSummary
            bookingCreated={bookingCreated}
            setOpenSlotModal={setOpenSlotModal}
            openSlotModal={openSlotModal}
            setOpenConfirmedModal={setOpenConfirmedModal}
            collectionDate={collectionDate}
            partnerKey={partnerKey}
            setOpenCouponModal={setOpenCouponModal}
            customerGender={customerGender}
            customerAge={customerAge}
            customerName={customerName}
            customerPackages={customerPackages}
            customerZone={customerZone}
            setCoupon={setCoupon}
            coupon={coupon}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            customerNumber={customerNumber}
            customerDetails={customerDetails}
            addressInput={addressInput}
            setLocationOpen={setLocationOpen}
            collectionSlot={collectionSlot}
            submitBooking={submitBooking}
            setReschedule={setReschedule}
            setOpenTracking={setOpenTracking}
            reschedule={reschedule}
            bookingData={
              bookingData && bookingData.length > 0 ? bookingData[0] : ""
            }
            loading={loading}
            setViewOrderSummary={setViewOrderSummary}
            viewOrderSummary={viewOrderSummary}
            areaServicable={areaServicable}
            type={type}
            centreName={centreName}
            pincode={pincode}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={openConfirmedModal}
          onClose={() => setOpenConfirmedModal(false)}
        >
          <ConfirmationPage
            setOpenConfirmedModal={setOpenConfirmedModal}
            setConfirmType={setConfirmType}
            confirmType={confirmType}
            setOpenTracking={setOpenTracking}
            setReschedule={setReschedule}
            reschedule={reschedule}
            cancel={cancel}
            type={type}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={landingPage}
          onClose={() => setLandingPage(false)}
        >
          <LandingPage
            setLandingPage={setLandingPage}
            setEmpId={setEmpId}
            empId={empId}
            setAgentId={setAgentId}
            agentId={agentId}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setOtpEmail={setOtpEmail}
            setOtpEmailVerified={setOtpEmailVerified}
            otpEmail={otpEmail}
            setCustomerNumber={setCustomerNumber}
            customerNumber={customerNumber}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={accLandingPage}
          onClose={() => setAccLandingPage(false)}
        >
          <AccLandingPage
            setAccLandingPage={setAccLandingPage}
            setAccOpenPackageModal={setAccOpenPackageModal}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setOtpEmail={setOtpEmail}
            setOtpEmailVerified={setOtpEmailVerified}
            otpEmail={otpEmail}
            setLocationOpen={setLocationOpen}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={radLandingPage}
          onClose={() => setRadLandingPage(false)}
        >
          <RadLandingPage
            setRadLandingPage={setRadLandingPage}
            setAccOpenPackageModal={setAccOpenPackageModal}
            setCustomerEmail={setCustomerEmail}
            customerEmail={customerEmail}
            setOtpEmail={setOtpEmail}
            setOtpEmailVerified={setOtpEmailVerified}
            otpEmail={otpEmail}
            setLocationOpen={setLocationOpen}
            customerNumber={customerNumber}
            setCustomerNumber={setCustomerNumber}
            mobileValidation={mobileValidation}
            otp={otp}
            setOtp={setOtp}
            PostOtp={PostOtp}
            otpEmailVerified={otpEmailVerified}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          fullScreen
          open={openTracking}
          onClose={() => setOpenTracking(false)}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <TrackStatus
            setOpenTracking={setOpenTracking}
            setReschedule={setReschedule}
            reschedule={reschedule}
            bookingData={
              bookingData && bookingData.length > 0 ? bookingData[0] : ""
            }
            loading={loading}
            setOpenSlotModal={setOpenSlotModal}
            openSlotModal={openSlotModal}
            setCollectionDate={setCollectionDate}
            setCollectionSlot={setCollectionSlot}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setCancel={setCancel}
            partnerKey={partnerKey}
            setViewOrderSummary={setViewOrderSummary}
            setOpenCouponModal={setOpenCouponModal}
            areaServicable={areaServicable}
            setOpenCancel={setOpenCancel}
          />
        </Dialog>
        <Dialog disableEscapeKeyDown={true}
          open={openCancel}
          TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="dialogBottom"
        >
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Typography component="p" fontWeight={600}>
                For completing order cancellation. Please choose the reason for
                cancellation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                {cancelReason.map((val: any) => {
                  return (
                    <FormControlLabel
                      value={val.value}
                      control={
                        <Checkbox
                          checked={val.value === cancelRemarks}
                          onChange={(e) =>
                            handleCheck(e.target.checked, e.target.value)
                          }
                        />
                      }
                      label={val.value}
                      labelPlacement="end"
                    />
                  );
                })}

                <FormControlLabel
                  key={isOther}
                  value="Other"
                  control={
                    <Checkbox
                      checked={isOther}
                      onChange={(e) => handleCheck(e.target.checked, "Other")}
                    />
                  }
                  label="Other"
                  labelPlacement="end"
                />
                {isOther ? (
                  <>
                    <textarea
                      required
                      rows={4}
                      placeholder="Start typing here"
                      style={{ width: "100%" }}
                      value={cancelRemarks}
                      onChange={(e) => setCancelRemarks(e.target.value)}
                    />
                    <sub style={{ color: "red" }}>
                      Ensure this field has no more than 160 characters.
                    </sub>
                  </>
                ) : (
                  ""
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                onClick={() => cancelBooking()}
                disabled={loading || !cancelRemarks}
              >
                Cancel Booking
              </StyledButton>
            </Grid>
          </Grid>
        </Dialog>
        <SnackBar val={snackData} />
      </div>
    </ThemeProvider>
  );
}
