import React, { useState } from "react";
import "./index.sass";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../storage/store";
import Loader from "../../components/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import StyledButton from "../../components/Button/StyledButton";
import ClearIcon from "@mui/icons-material/Clear";
import { getRadPackagesData, getParams } from "../../services/actionSlice";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {
  setAccOpenPackageModal: any;
  updateMember: any;
  setCustomerPackages: any;
  addIndex: any;
  partnerKey: any;
  loading: any;
  centreData: any;
  type: any;
  setType: any;
  setCentreName: any;
  setLocationOpen: any;
  addressInput: any;
  packages:any,
  refData:any
}
const AccPackagePage: React.FC<Props> = ({
  setAccOpenPackageModal,
  updateMember,
  setCustomerPackages,
  addIndex,
  partnerKey,
  loading,
  centreData,
  type,
  setType,
  setCentreName,
  setLocationOpen,
  addressInput,
  packages,
  refData
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);

  const [view, setView] = useState<any>("");

  const [isSelected, setIsSelected] = useState<string>("");

  const [openParam, setOpenParam] = useState<boolean>(false);
  const [openParamNew, setOpenParamNew] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<any>("");
  const params = useSelector((state: any) => state.webAppSlice.params);
  const radPackages = useSelector(
    (state: any) => state.webAppSlice.radPackages
  );

  // console.log(file, "file.prev ");
  useEffect(() => {
    if (partnerKey?.center_key !== undefined) {
      dispatch(
        getRadPackagesData({
          url: `?org_type=imaging&center=${centreData.center}&config_id=${refData}`,
          key: partnerKey?.center_key,
        })
      );
    }
  }, [partnerKey]);

  const handleSelected = (val: any, checked: any) => {
    //@ts-ignore done
    gtag("event", "selected_package", {
      package_name: centreData?.package_details?.name,
      member_type: "additional",
      user_id: "customerNumber",
    });
    let arr: any = [];
    arr.push(val);

    if (addIndex !== "") {
      updateMember(addIndex, arr, "packages");
      let arr1: any = [];
      arr.length > 0 &&
        arr.map((val: any) => {
          arr1.push(val.code);
        });
      updateMember(addIndex, arr1, "packageCode");
      // updateMember(addIndex, true, "isShow");
    } else {
      setCustomerPackages(arr);
    }
    setCentreName({
      center: { name: "" },
      address: "",
    });
    if(!addressInput){
      setLocationOpen(true)
    }
    setAccOpenPackageModal(false);
  };

  function handleView(val: any): void {
    setOpenParam(true);
    setView(val);
  }
  function handleViewParams(val: any): void {
    setOpenParamNew(true);

    dispatch(
      getParams({
        url: `?code=${val.code}`,
        key: partnerKey?.center_key,
      })
    );
  }
  useEffect(() => {
    if (params.status === "success" && !loading) {
      setView(params);
    }
  }, [params]);
  const handleSearch = (val: any) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (partnerKey?.center_key) {
        dispatch(
          getRadPackagesData({
            url: `?org_type=imaging&center=${centreData.center}&search=${val}&config_id=${refData}`,
            key: partnerKey?.center_key,
          })
        );
      }
    }, 700);
    setSearchInput(val);
  };
  const company = centreData.custom_user_requirement_for;
  return (
    <Grid className="content">
      <Header isFilter={false} />
      <Grid container className="accPkgContainer">
      {centreData.tab_type === "RADI" ? (
          ""
        ) : (
          <>
            <Grid item xs={6}>
              {type === "path" ? (
                <StyledButton
                  style={{ padding: "13px 4px" }}
                  onClick={() => setType("path")}
                >
                  {" "}
                  Pathology Packages
                </StyledButton>
              ) : (
                <StyledButtonLight
                  style={{ padding: "13px 4px" }}
                  onClick={() => setType("path")}
                  disabled={addIndex !== ""}
                >
                  Pathology Packages
                </StyledButtonLight>
              )}
            </Grid>
            <Grid item xs={6}>
              {type === "rad" ? (
                <StyledButton
                  style={{ padding: "13px 4px" }}
                  onClick={() => setType("rad")}
                
                >
                  {" "}
                  Radiology Packages
                </StyledButton>
              ) : (
                <StyledButtonLight
                  style={{ padding: "13px 4px" }}
                  onClick={() => setType("rad")}
                  disabled={addIndex !== ""}
                >
                  Radiology Packages
                </StyledButtonLight>
              )}
            </Grid>
          </>
        )}
        {
          centreData?.show_package_list===true?
          <Grid item xs={12} style={{ marginTop: 20 }}>
          <TextField
            type="text"
            label="Search Packages"
            value={searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {searchInput ? (
                    <ClearIcon
                      style={{ color: "grey" }}
                      onClick={() => handleSearch("")}
                    />
                  ) : (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon color="primary" />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearch(e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
          />
        </Grid>:""
        }
     
        <Grid
          container
          style={{
            marginBottom: "20px",
            marginTop: "10px",
            padding: "10px 0px",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 4px 0px inset",
            borderRadius: "10px",
          }}
        >
          {loading ? (
            <Grid item xs={12} textAlign={"center"}>
              <Loader />
            </Grid>
          ) : type === "path" ? (
            <Grid item xs={12}>
              {packages.data &&
                packages.data.length > 0 &&
                packages.data.map((val: any, index: any) => {
                  return (
                    <div className="pkgCard" key={val.id}>
                      <div>
                        <p style={{ fontSize: "18px", margin: 0 }}>
                          {val?.name}
                        </p>
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          color: "#A2A2A2",
                        }}
                      >
                        <span>
                          Fasting:{" "}
                          {val?.fasting_time
                            ? `Yes, ${val?.fasting_time}`
                            : "No"}
                        </span>
                        <br />
                        <span>includes: {val.parameter || 0} parameters</span>
                        <br />
                        {val.description &&
                          val.description.split(",").length > 0 &&
                          String(
                            val.description
                              .split(",")
                              .map((data: any, index: any) => {
                                return index < 2 ? `${data},` : "";
                              })
                          ).substring(0, 30)}
                        {val?.parameter > 1 ? (
                          <a
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => handleViewParams(val)}
                          >
                            view more
                          </a>
                        ) : (
                          ""
                        )}

                        {val?.tat_time ? (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "8%" }}>
                              <CheckCircleIcon style={{ fontSize: "15px" }} />
                            </div>

                            <div style={{ width: "92%", fontSize: "12px" }}>
                              Within {val?.tat_time}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <Divider style={{ margin: "10px 0px" }} />
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            textAlign: "left",
                            width: "50%",
                            fontWeight: "bold",
                          }}
                        >
                          ₹
                          {val?.package_center_prices?.city_offer_price
                            ? val?.package_center_prices?.city_offer_price
                            : val?.package_center_prices?.offer_price}
                          /-
                          <br />
                          <span
                            style={{
                              textDecoration: "line-through",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "gray",
                            }}
                          >
                            MOP: ₹{val?.package_center_prices?.package_price}
                            /-
                          </span>
                        </div>
                        <div style={{ width: "50%" }}>
                          <StyledButton
                            onClick={() => handleSelected(val, true)}
                          >
                            Book
                          </StyledButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Grid>
          ) : (
            <Grid item xs={12}>
              {radPackages.results &&
                radPackages.results.length > 0 &&
                radPackages.results.map((val: any, index: any) => {
                  return (
                    <div className="pkgCard" key={val.id}>
                      <div>
                        <p style={{ fontSize: "18px", margin: 0 }}>
                          {val?.name}
                        </p>
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          color: "#A2A2A2",
                        }}
                      >
                        <span>
                          Fasting:{" "}
                          {val?.fasting_time
                            ? `Yes, ${val?.fasting_time}`
                            : "No"}
                        </span>
                        <br />
                        <span>includes: {val.parameter || 0} parameters</span>
                        <br />
                        {val.description &&
                          val.description.split(",").length > 0 &&
                          String(
                            val.description
                              .split(",")
                              .map((data: any, index: any) => {
                                return index < 2 ? `${data},` : "";
                              })
                          ).substring(0, 30)}
                        {val?.parameter > 1 ? (
                          <a
                            style={{
                              color: "blue",
                              cursor: "pointer",
                              marginLeft: 5,
                            }}
                            onClick={() => handleView(val)}
                          >
                            view more
                          </a>
                        ) : (
                          ""
                        )}

                        {val?.tat_time ? (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              display: "flex",
                            }}
                          >
                            <div style={{ width: "8%" }}>
                              <CheckCircleIcon style={{ fontSize: "15px" }} />
                            </div>

                            <div style={{ width: "92%", fontSize: "12px" }}>
                              Within {val?.tat_time}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <Divider style={{ margin: "10px 0px" }} />
                      <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            textAlign: "left",
                            width: "50%",
                            fontWeight: "bold",
                          }}
                        >
                          {centreData.show_package_golive ===
                            false ? (
                            ""
                          ) : (
                            <>
                              ₹{val?.offer_price}
                              /-
                             
                            </>
                          )}{" "}
                           <br />
                              {centreData.show_package_mrp ===
                              false ? (
                                ""
                              ) : (
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  MOP: ₹{val?.package_price}
                                  /-
                                </span>
                              )}
                        </div>
                        <div style={{ width: "50%" }}>
                          <StyledButton
                            onClick={() => handleSelected(val, true)}
                          >
                            Book
                          </StyledButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Grid>
          )}
        </Grid>
        {centreData?.package_additional_text ? (
          <Grid
            item
            xs={12}
            textAlign={"center"}
            style={{ marginBottom: "50px" }}
          >
            <hr style={{ border: "1px solid #ececec" }} />
            <sup>
            {centreData?.package_additional_text}
            </sup>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <Dialog disableEscapeKeyDown={true}
        open={openParam}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottomPkg"
      >
        <Grid container spacing={2} className="dialogContent">
          <Grid item xs={11}>
            <Typography
              component="p"
              style={{ fontSize: "16px", fontWeight: "bold", color: "#E06358" }}
            >
              {view && view?.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Close">
              <CloseIcon onClick={() => setOpenParam(false)} />
            </Tooltip>
          </Grid>
          <Grid item xs={12} style={{ overflow: "auto", maxHeight: "80vh" }}>
            {view?.package_details &&
              view?.package_details.length > 0 &&
              view.package_details.map((data: any) => {
                return (
                  <Grid container>
                    <Grid item xs={10}>
                      {data.name}
                      <span style={{ color: "#7B7C7E" }}>
                        (
                        {`${
                          data?.package_detail?.length > 0
                            ? data.package_detail.length + " tests"
                            : "1" + " test"
                        }`}
                        )
                      </span>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                      {isSelected === data.name ? (
                        <ExpandLessOutlinedIcon
                          onClick={() => setIsSelected("")}
                        />
                      ) : (
                        <ExpandMoreOutlinedIcon
                          onClick={() => setIsSelected(data.name)}
                        />
                      )}
                    </Grid>
                    {isSelected === data.name ? (
                      <Grid
                        item
                        xs={12}
                        style={{ overflow: "auto", maxHeight: "400px" }}
                      >
                        <ol
                          style={{
                            color: "#7B7C7E",
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                        >
                          {data?.package_detail &&
                            data?.package_detail.length > 0 &&
                            data?.package_detail.map((val: any) => {
                              return <li>{val?.name}</li>;
                            })}
                          {data?.package_detail &&
                            data?.package_detail.length === 0 && (
                              <li>{data.name}</li>
                            )}
                        </ol>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item xs={12}>
                      {" "}
                      <hr />
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Dialog>
      <Dialog disableEscapeKeyDown={true}
        open={openParamNew}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBottomPkg"
      >
        <Grid container spacing={2} className="dialogContent">
          <Grid item xs={11}>
            <Typography
              component="p"
              style={{ fontSize: "16px", fontWeight: "bold", color: "#E06358" }}
            >
              {view && view?.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Close">
              <CloseIcon onClick={() => setOpenParamNew(false)} />
            </Tooltip>
          </Grid>
          {loading ? (
            <Grid item xs={12} textAlign={"center"}>
              <Loader />
            </Grid>
          ) : (
            <Grid item xs={12} style={{ overflow: "auto", maxHeight: "80vh" }}>
              {view?.data &&
                view?.data.length > 0 &&
                view.data.map((data: any) => {
                  return (
                    <Grid container>
                      <Grid item xs={10}>
                        {data.name}
                        <span style={{ color: "#7B7C7E" }}>
                          (
                          {`${
                            data?.package_detail?.length > 0
                              ? data.package_detail.length + " tests"
                              : "1" + " test"
                          }`}
                          )
                        </span>
                      </Grid>
                      <Grid item xs={2} textAlign="right">
                        {isSelected === data.name ? (
                          <ExpandLessOutlinedIcon
                            onClick={() => setIsSelected("")}
                          />
                        ) : (
                          <ExpandMoreOutlinedIcon
                            onClick={() => setIsSelected(data.name)}
                          />
                        )}
                      </Grid>
                      {isSelected === data.name ? (
                        <Grid
                          item
                          xs={12}
                          style={{ overflow: "auto", maxHeight: "400px" }}
                        >
                          <ol
                            style={{
                              color: "#7B7C7E",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            {data?.package_detail &&
                              data?.package_detail.length > 0 &&
                              data?.package_detail.map((val: any) => {
                                return <li>{val?.name}</li>;
                              })}
                            {data?.package_detail &&
                              data?.package_detail.length === 0 && (
                                <li>{data.name}</li>
                              )}
                          </ol>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid item xs={12}>
                        {" "}
                        <hr />
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default AccPackagePage;
