import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SecureStorage from "../../config/SecureStorage";
import { Grid, TextField, Typography, InputAdornment } from "@mui/material";
import StyledButton from "../../components/Button/StyledButton";
import {
  getEmailOtp,
  getEmpDetails,
  postOtpForEmail,
} from "../../services/actionSlice";
import { AppDispatch } from "../../storage/store";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import OtpInput from "react-otp-input";
import moment from "moment";
// import ReactGA from "react-ga";
interface Props {
  setAccOpenPackageModal: any;
  setAccLandingPage: any;
  setCustomerEmail: any;
  customerEmail: any;
  setOtpEmail: any;
  otpEmail: any;
  setOtpEmailVerified: any;
  setLocationOpen: any;
}

const LandingPage: React.FC<Props> = ({
  setAccLandingPage,
  setCustomerEmail,
  customerEmail,
  setOtpEmail,
  otpEmail,
  setOtpEmailVerified,
  setAccOpenPackageModal,
  setLocationOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const company = centreData.custom_user_requirement_for;
  function getSubstring(str: any, start: any, end: any) {
    let char1 = str.indexOf(start) + 1;
    let char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  }
  const emailSent = useSelector((state: any) => state.webAppSlice.emailOtp);
  const verifiedOtpEmail = useSelector(
    (state: any) => state.webAppSlice.emailOtpVerified
  );

  const [email, setEmail] = useState<any>("");
  const [sentEmail, setSentEmail] = useState<any>("");
  const [seconds, setSecond] = useState<any>("");
  const [key, setKey] = useState<any>("1");
  const verifyEmail = () => {
    setSentEmail(email);
    //@ts-ignore done
    gtag("event", "verify_email_btn", {
      user_id: customerEmail,
      customer_email: centreData?.collection_date,
      device_name: getSubstring(navigator.userAgent, "(", ";"),
    });
    dispatch(
      getEmailOtp(
        `?email=${customerEmail}&emp_id=accn${
          company !== "EVAHEL" && company !== "RECI" ? "&force=true" : ""
        }`
      )
    );
  };
  useEffect(() => {
    if (String(otpEmail).length === 6) {
      dispatch(postOtpForEmail(`?otp=${otpEmail}&email=${customerEmail}`));
    }
  }, [otpEmail]);
  useEffect(() => {
    if (verifiedOtpEmail.token) {
      if (
        company === "EVAHEL" ||
        company === "RECI" ||
        centreData?.has_addtional_member_data
      ) {
        dispatch(getEmpDetails(verifiedOtpEmail.token));
      }
      const tokenIframe = {
        email: customerEmail,
        time: moment().format("YYYY-MM-DDTHH:mm:ss"),
        name: "",
        number: "",
        verifiedOtptoken: verifiedOtpEmail.token,
      };
      //@ts-ignore
      window.dataLayer.push({
        email: customerEmail,
        verifiedOtptoken: verifiedOtpEmail?.token,
      });
      SecureStorage.setItem("tokenIframe", JSON.stringify(tokenIframe));
      setOtpEmailVerified(true);
      setAccLandingPage(false);
      setLocationOpen(false);
      setAccOpenPackageModal(true);
    }
    if (verifiedOtpEmail.errors) {
      setKey(otpEmail);
    }
  }, [verifiedOtpEmail]);

  useEffect(() => {
    let timeout: any;
    if (seconds > 0) {
      timeout = setTimeout(
        () => setSecond((previousCount: number) => previousCount - 1),
        1000
      );
    } else {
      setSecond(0);
    }
    return () => clearTimeout(timeout);
  });
  return (
    <Grid className="content" id="collectionSlot">
      <Grid container spacing={2} className="dialogContent">
        <Grid item xs={12} textAlign="center">
          <img
            src={centreData?.logo_image}
            alt="LOGO"
            style={{
              padding: "5px 0px 0px",
              margin: "70px auto",
              width: "250px",
              height: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <TextField
            className="input"
            variant="outlined"
            label="Email Address"
            placeholder="Enter your email address"
            required
            type="text"
            fullWidth
            value={email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {`${
                    (process.env.REACT_APP_ENV === "production" &&
                      (email === "RCL2847" ||
                        email === "RCL1424" ||
                        email === "RCL3284" ||
                        email === "RCL542" ||
                        email === "RCL265" ||
                        email === "RCL703" ||
                        email === "RCL2173")) ||
                    process.env.REACT_APP_ENV === "development"
                      ? "@redcliffelabs.com"
                      : `${
                          company === "ACCENTURE"
                            ? "@accenture.com"
                            : company === "HITACHI"
                            ? "@hitachirail.com"
                            : company === "EISAI"
                            ? "@eisaiindia.com"
                            : company === "RECI"
                            ? "@rbbn.com"
                            : company === "EVAHEL"
                            ? "@onebillsoftware.com"
                            : company === "AON"
                            ? "@upfield.com"
                            : company === "BOB"
                            ? "@bankofbaroda.com"
                            : ""
                        }`
                  }`}
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setEmail(String(e.target.value).replace("@", "").trim());
              setCustomerEmail(
                `${
                  process.env.REACT_APP_ENV === "production" &&
                  String(e.target.value) === "RCL2847"
                    ? "shefali.saini@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL1424"
                    ? "jayraj@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL2173"
                    ? "sachin.sharma@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL3284"
                    ? "abhishek@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL542"
                    ? "suraj.das@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL265"
                    ? "deepak.c@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production" &&
                      String(e.target.value) === "RCL703"
                    ? "manoj.kumar@redcliffelabs.com"
                    : process.env.REACT_APP_ENV === "production"
                    ? String(e.target.value).replace("@", "").trim() +
                      `${
                        company === "ACCENTURE"
                          ? "@accenture.com"
                          : company === "HITACHI"
                          ? "@hitachirail.com"
                          : company === "EISAI"
                          ? "@eisaiindia.com"
                          : company === "RECI"
                          ? "@rbbn.com"
                          : company === "EVAHEL"
                          ? "@onebillsoftware.com"
                          : company === "AON"
                          ? "@upfield.com"
                          : company === "BOB"
                          ? "@bankofbaroda.com"
                          : ""
                      }`
                    : String(e.target.value).replace("@", "").trim() +
                      "@redcliffelabs.com"
                }`
              );
            }}
          />
        </Grid>
        <Grid item xs={12} textAlign="left">
          {emailSent?.message === "Otp sent!" &&
          sentEmail &&
          sentEmail === email ? (
            <>
              <Grid item xs={12}>
                <Typography component="p" color="green">
                  OTP has been shared on your Email.{" "}
                </Typography>
                <Typography component="p">Enter OTP</Typography>
              </Grid>
              <Grid item xs={12}>
                <OtpInput
                  value={otpEmail}
                  isInputNum={true}
                  key={key}
                  numInputs={6}
                  onChange={(e: any) => {
                    setOtpEmail(e);
                  }}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 0.4rem",
                    fontSize: "14px",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                    background: "#e7e7e7",
                    color: "#000",
                  }}
                />
              </Grid>
              {verifiedOtpEmail?.errors && key === otpEmail ? (
                <Grid item xs={12}>
                  <Typography component="p" color="red">
                    <CancelOutlinedIcon
                      style={{
                        color: "red",
                        fontSize: 18,
                        marginBottom: "-3px",
                      }}
                    />{" "}
                    Incorrect OTP!
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                {seconds !== 0 ? (
                  <p>
                    Resend OTP in
                    <span style={{ color: "#5866E0" }}>
                      {" "}
                      00:{seconds < 10 ? "0" + seconds : seconds}
                    </span>
                  </p>
                ) : (
                  <p>
                    <span
                      style={{ color: "#5866E0", cursor: "pointer" }}
                      onClick={() => {
                        verifyEmail();
                        setSecond(30);
                        setOtpEmail("");
                        setKey("0");
                      }}
                    >
                      Resend OTP
                    </span>
                  </p>
                )}
              </Grid>
            </>
          ) : (
            <StyledButton
              id="verifyEmail"
              disabled={!email}
              onClick={() => {
                verifyEmail();
                setSecond(30);
                //@ts-ignore
                // window.dataLayer.push({
                //   email: customerEmail,
                // });
              }}
            >
              Verify Email
            </StyledButton>
          )}
        </Grid>
        <Grid item textAlign={"center"}>
          <hr style={{ border: "1px solid #ececec" }} />
          {company === "ACCENTURE" || company === "BOB" ? (
            <sub>
              For any queries/concerns, please write us at{" "}
              <a href="mailto:appointments@redcliffelabs.com">
                <b>appointments@redcliffelabs.com</b>
              </a>
            </sub>
          ) : company === "EVAHEL" || company === "AON" ? (
            <sub>
              For any queries/concerns. please write us at{" "}
              <a href="mailto:imagingsupport1@redcliffelabs.com">
                <b>radiology@redcliffelabs.com</b>
              </a>
            </sub>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
