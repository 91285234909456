import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Card,
  Box,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "./index.sass";
import {
  getBooking,
  getCoupon,
  getCouponCalc,
  resetState,
  updateBooking,
  updateBookingCredit,
  sendPaymentId,
  sendConfirmBooking,
} from "../../services/actionSlice";
import moment from "moment";
import Header from "../../components/Header";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import { AppDispatch } from "../../storage/store";
import StyledButton from "../../components/Button/StyledButton";
import PatientInfo from "../../components/PatientInfo";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
import useRazorpay from "react-razorpay";
import { type } from "os";
import { CancelOutlined } from "@mui/icons-material";

interface Props {
  setOpenSlotModal: any;
  openSlotModal: any;
  setOpenConfirmedModal: any;
  collectionDate: any;
  partnerKey: any;
  setOpenCouponModal: any;
  customerGender: any;
  customerAge: any;
  customerName: any;
  customerPackages: any;
  customerNumber: any;
  customerDetails: any;
  addressInput: any;
  setLocationOpen: any;
  collectionSlot: any;
  submitBooking: any;
  bookingCreated: any;
  loading: any;
  setReschedule: any;
  reschedule: any;
  bookingData: any;
  setOpenTracking: any;
  setCoupon: any;
  coupon: any;
  setCouponCode: any;
  couponCode: any;
  customerZone: any;
  setViewOrderSummary: any;
  viewOrderSummary: any;
  areaServicable: any;
  type: any;
  centreName: any;
  pincode: any;
}

const OrderSummary: React.FC<Props> = ({
  setOpenSlotModal,
  openSlotModal,
  setOpenConfirmedModal,
  collectionDate,
  partnerKey,
  setOpenCouponModal,
  customerGender,
  customerAge,
  customerName,
  customerPackages,
  customerNumber,
  customerDetails,
  addressInput,
  setLocationOpen,
  collectionSlot,
  submitBooking,
  bookingCreated,
  loading,
  bookingData,
  setReschedule,
  reschedule,
  setOpenTracking,
  setCoupon,
  coupon,
  setCouponCode,
  couponCode,
  customerZone,
  setViewOrderSummary,
  viewOrderSummary,
  areaServicable,
  centreName,
  type,
  pincode,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const sendPayment = async (pid: any) => {
    let data = JSON.stringify({
      paymentid: pid,
      bookingid: bookingCreated?.pk,
    });
    await dispatch(
      sendPaymentId({ data: data, url: type === "rad" ? "?type=imaging" : "" })
    );
    //@ts-ignore
    gtag("event", "pay_now_btn", {
      booking_id: bookingCreated?.pk,
      user_pk: customerNumber,
      amount_to_collect: subTotal - discount,
      payment_link: pid,
      pg_partner: "pg_partner",
    });
  };
  const confirmBooking = async () => {
    const data = {
      is_confirmed: true,
      booking_id: bookingCreated?.pk,
    };

    await dispatch(
      sendConfirmBooking({ data: data, key: partnerKey?.center_key })
    );
    //@ts-ignore
    gtag("event", "pv_order_status", {
      booking_id: bookingCreated?.pk,
      status: "booked",
      partner_name: centreData?.center_name,
    });
  };

  const Razorpay = useRazorpay();
  const handlePayment = async (data: any) => {
    const options: any = {
      key:
        process.env.REACT_APP_ENV === "production"
          ? "rzp_live_sDovuuBgCu9dw3"
          : "rzp_test_kBEHIT9OH5OYyo",
      amount: data?.discounted_price?.final_total_price * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Redcliffe Labs",
      description: "iframe transaction",
      image:
        "https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/a0f82e2b-5cee-4694-a972-2514e9bd3694.svg",
      handler: function (response: any) {
        console.log(response?.razorpay_payment_id, "Payment Success");
        sendPayment(response?.razorpay_payment_id);
        confirmBooking();
        setOnlinePayFailed(false);
        setOpenConfirmedModal(true);
        setOpenCouponModal(false);
      },
      prefill: {
        name: data.customer_name,
        email: data.customer_email,
        contact: data.customer_phonenumber,
      },
      notes: {
        bookingid: data?.pk,
      },
      theme: {
        color: "#E06358",
      },
      // callback_url : `${Prefix.api}/payment/order_payment_link_status/`,
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response: any) {
      // console.log(response?.error?.metadata?.payment_id, "Payment Failed");
      sendPayment(response?.error?.metadata?.payment_id);
      if (centreData?.tab_type === "RADI" && type === "rad") {
        setOnlinePayFailed(true);
      } else {
        alert(response.error.reason);
      }
    });

    rzp1.open();
  };
  //payment
  const bookingUpdated = useSelector(
    (state: any) => state.webAppSlice.bookingUpdated
  );
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);
  const couponCalc = useSelector((state: any) => state.webAppSlice.couponCalc);
  const couponData = useSelector((state: any) => state.webAppSlice.coupon);
  const company = centreData.custom_user_requirement_for;

  const [onlinePay, setOnlinePay] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(true);
  const [onlinePayFailed, setOnlinePayFailed] = useState<boolean>(false);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [couponMinAmt, setCouponMinAmt] = useState<number>(0);
  const [allPackages, setAllPackages] = useState<any>([]);
  const [allPackagesId, setAllPackagesId] = useState<any>([]);
  const [allAddnPackages, setAllAddnPackages] = useState<any>([]);
  const [allAddnPackagesId, setAllAddnPackagesId] = useState<any>([]);
  const [addnPackages, setAddnPackages] = useState<any>([]);
  // console.log(bookingData, "bookingData");
  const rescheduleBookingUpdate = async () => {
    const data = {
      booking_status: "rescheduled",
      booking_id: bookingData?.pk,
      remark: "rescheduled",
      collection_date: collectionDate,
      collection_slot: collectionSlot.id || "",
    };

    await dispatch(
      updateBooking({
        data: data,
        key: partnerKey?.center_key,
        force:
          centreData?.custom_user_requirement_for === "ACCENTURE" &&
          !areaServicable
            ? true
            : false,
      })
    );
  };
  useEffect(() => {
    let total = 0;
    let radtotal = 0;
    customerPackages.length > 0 &&
      customerPackages.map(
        (val: any) =>
          (total =
            total +
            (val?.package_center_prices?.city_offer_price
              ? val?.package_center_prices?.city_offer_price
              : val?.package_center_prices?.offer_price))
      );

    customerDetails.length > 0 &&
      customerDetails.map(
        (data: any) =>
          data.packages.length > 0 &&
          data.packages.map(
            (val: any) =>
              (total =
                total +
                (val?.package_center_prices?.city_offer_price
                  ? val?.package_center_prices?.city_offer_price
                  : val?.package_center_prices?.offer_price))
          )
      );
    if (type === "rad") {
      customerPackages.length > 0 &&
        customerPackages.map(
          (val: any) => (radtotal = radtotal + val?.offer_price)
        );
      centreData?.tab_type !== "PATA" &&
        customerDetails.length > 0 &&
        customerDetails.map(
          (data: any) =>
            data.packages.length > 0 &&
            data.packages.map(
              (val: any) => (radtotal = radtotal + val?.offer_price)
            )
        );
      setSubTotal(radtotal);
    } else {
      setSubTotal(total);
    }
  }, [customerPackages, customerDetails, coupon]);
  useEffect(() => {
    if (bookingCreated?.pk && !onlinePay) {
      confirmBooking();
      setOpenConfirmedModal(true);
      setOpenCouponModal(false);
      //@ts-ignore
      gtag("event", "pay_doorstep_btn", {
        booking_id: bookingCreated?.pk,
        user_pk: customerNumber,
      });
    } else if (bookingCreated?.pk && onlinePay) {
      handlePayment(bookingCreated);
    }
  }, [bookingCreated?.pk]);
  useEffect(() => {
    if (bookingUpdated?.booking_id && reschedule) {
      setOpenConfirmedModal(true);
      setOpenCouponModal(false);
      dispatch(resetState());
      dispatch(
        getBooking({
          url: `?booking_id=${bookingUpdated?.booking_id}`,
          key: `${partnerKey?.center_key}`,
        })
      );
    }
  }, [bookingUpdated?.booking_id, reschedule]);
  useEffect(() => {
    if (customerDetails.length > 0) {
      let ap: any = [];
      customerDetails.map((val: any) => {
        val.packages.length > 0 &&
          val.packages.map((val1: any, index: any) => {
            ap.push(val1);
          });
      });
      setAddnPackages(ap);
    }
  }, [customerDetails]);
  useEffect(() => {
    let arr3: any =
      (addnPackages.length > 0 && addnPackages.map((val: any) => val.code)) ||
      [];
    let arr4: any =
      (addnPackages.length > 0 && addnPackages.map((val: any) => val.id)) || [];
    setAllAddnPackages(arr3);
    setAllAddnPackagesId(arr4);
    let arr1: any =
      (customerPackages.length > 0 &&
        customerPackages.map((val: any) => val.code)) ||
      [];
    let arr2: any =
      (customerPackages.length > 0 &&
        customerPackages.map((val: any) => val.id)) ||
      [];
    setAllPackages(arr1);
    setAllPackagesId(arr2);
  }, [customerPackages, addnPackages]);

  useEffect(() => {
    if (allPackages.length > 0 && partnerKey?.center_key && coupon) {
      dispatch(
        getCouponCalc({
          key: partnerKey?.center_key,
          url: `?packages=${allPackages}${
            allAddnPackages.length > 0
              ? `&additional_packages=${allAddnPackages}`
              : ""
          }&coupon_id=${coupon}&customer_zone=${customerZone}`,
        })
      );
    }
  }, [allPackages, allAddnPackages, coupon, partnerKey, customerZone]);
  const handleApply = () => {
    if (partnerKey?.center_key && couponCode) {
      dispatch(
        getCoupon({
          url: `?code=${couponCode}&packages=${allPackagesId.concat(
            allAddnPackagesId
          )}&center=${centreData.center}`,
          key: partnerKey?.center_key,
        })
      );
    }
  };
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code && centreData.can_create_coupon) {
      handleApply();
    }
  }, [window.location.search]);
  const handleRemove = () => {
    setCoupon("");
    setCouponCode("");
    setDiscount(0);
  };
  useEffect(() => {
    if (couponData && couponData.length > 0) {
      setCoupon(couponData[0].id);
      setCouponMinAmt(couponData[0].minimum_price);
    } else if (couponData.length === 0) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const code = params.get("code");
      //@ts-ignore
      gtag("event", "applied_coupon_btn", {
        is_valid_coupon: false,
        user_pk: customerNumber,
        coupon_name: "",
        discounted_value: "",
        coupon_discount: "",
        is_coupon_auto_apply:
          code && centreData.can_create_coupon ? true : false,
      });
    }
  }, [couponData]);
  useEffect(() => {
    if (couponCalc?.total_price_package) {
      // setSubTotal(couponCalc?.total_price_package);
      setDiscount(couponCalc?.counpon_discount);
      if (couponCalc?.counpon_discount === 0) {
        setCoupon("");
      }
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const code = params.get("code");
      //@ts-ignore
      gtag("event", "applied_coupon_btn", {
        is_valid_coupon: subTotal >= couponData[0].minimum_price,
        coupon_name: couponData[0].code,
        user_pk: customerNumber,
        discounted_value: couponCalc?.counpon_discount,
        coupon_discount: couponData[0].discount,
        is_coupon_auto_apply:
          code && centreData.can_create_coupon ? true : false,
      });
    } else if (couponCalc.remove_coupon) {
      setDiscount(0);
      setCoupon("");
    }
  }, [couponCalc]);
  const OpenTracking = () => {
    setOpenCouponModal(false);
    setViewOrderSummary(false);
    setOpenTracking(true);
  };
  const bookingUpdatedCredit = useSelector(
    (state: any) => state.webAppSlice.bookingUpdatedCredit
  );
  const updateCredit = async (id: any) => {
    const data = {
      booking_id: id,
      is_credit: false,
      remark: "payment mode updated to cod",
    };
    await dispatch(
      updateBookingCredit({ data: data, key: partnerKey?.center_key })
    );
  };
  useEffect(() => {
    if (bookingUpdatedCredit?.status) {
      setOpenConfirmedModal(true);
      setOpenCouponModal(false);
    }
  }, [bookingUpdatedCredit]);
  useEffect(() => {
    if (
      centreData?.is_subscription === true &&
      centreData?.tab_type === "PATA"
    ) {
      setDiscount(subTotal);
    }
    if (centreData?.center) {
      let slot =
        (collectionSlot?.format_12_hrs?.start_time
          ? collectionSlot?.format_12_hrs?.start_time
          : collectionSlot?.start_time_12hr) +
        "-" +
        (collectionSlot?.format_12_hrs?.end_time
          ? collectionSlot?.format_12_hrs?.end_time
          : collectionSlot?.end_time_12hr);
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const code = params.get("code");
      if (subTotal === 0) {
        //@ts-ignore
        gtag("event", "pv_order_view", {
          collection_date: collectionDate,
          collection_slot: slot,
          user_pk: customerNumber,
          cart_value: subTotal - discount,
          member_count: customerDetails.length + 1,
          is_coupon_enable: centreData?.can_create_coupon,
          pincode: pincode,
          is_coupon_auto_apply:
            code && centreData.can_create_coupon ? true : false,
          partner_type: centreData?.otp_mode,
          partner_name: centreData?.center_name,
          iframe_id: centreData?.id,
        });
      }
    }
  }, [centreData, subTotal]);
  return (
    <Grid className="content" id="collectionSlot">
      <Header
        isFilter={false}
        setOpenCouponModal={setOpenCouponModal}
        setOpenSlotModal={setOpenSlotModal}
        showHome={
          bookingCreated?.pk || reschedule || viewOrderSummary ? false : true
        }
      />
      {reschedule || viewOrderSummary ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={viewOrderSummary ? 12 : 10}>
              <Typography component="p">
                Collection Slot:
                <span style={{ color: "#E06358" }}>
                  {" "}
                  {moment(collectionDate).format("DD-MMM-YYYY")} |{" "}
                  {collectionSlot?.id ? collectionSlot?.slot : ""}
                </span>
              </Typography>
            </Grid>
            {viewOrderSummary ? (
              ""
            ) : (
              <Grid
                item
                xs={2}
                style={{ color: "rgba(38, 119, 240, 0.77)", cursor: "pointer" }}
                textAlign="right"
                onClick={() => {
                  setOpenCouponModal(false);
                  setOpenSlotModal(true);
                }}
              >
                edit
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography component="p">
                Address:
                <span style={{ color: "#E06358" }}>
                  {bookingData?.customer_address}
                  <br /> {bookingData?.customer_landmark}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <PatientInfo
            name={bookingData?.customer_name}
            age={bookingData?.customer_age}
            gender={bookingData?.customer_gender}
            packages={bookingData?.packages}
            variant={"card"}
            member={0}
            onClick={""}
          />
          {bookingData?.additional_members &&
            bookingData?.additional_members.length > 0 &&
            bookingData?.additional_members.map((val: any, index: any) => {
              return (
                <PatientInfo
                  name={val?.customer_name}
                  age={val?.customer_age}
                  gender={val?.customer_gender}
                  packages={val?.packages}
                  variant={"card"}
                  member={0}
                  onClick={""}
                />
              );
            })}
          {centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RSTAR" ||
          centreData.custom_user_requirement_for === "RECI" ? (
            ""
          ) : (
            <Card sx={{ borderRadius: "16px", m: 1 }}>
              <Grid container spacing={2} className="dialogContent">
                <Grid item xs={9}>
                  <Typography component="p">Sub Total</Typography>
                </Grid>
                <Grid item xs={3} textAlign="right">
                  {`₹ ${bookingData?.discounted_price?.total_price_package}`}
                </Grid>
                <Grid item xs={9}>
                  <Typography component="p">Discount</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography component="p" textAlign="right">
                    {`₹ ${
                      centreData?.has_partner_user_detail &&
                      centreData?.payment_mode === "PRE"
                        ? bookingData?.discounted_price?.total_price_package
                        : bookingData?.discounted_price?.counpon_discount
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={9} style={{ color: "#5866E0" }}>
                  <Typography component="p">Total Amount</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    component="p"
                    textAlign="right"
                    style={{ color: "#5866E0", fontWeight: "bolder" }}
                  >
                    {`₹ ${
                      centreData?.has_partner_user_detail &&
                      centreData?.payment_mode === "PRE"
                        ? 0
                        : bookingData?.discounted_price?.final_total_price
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              {viewOrderSummary ? (
                <StyledButton onClick={() => OpenTracking()}>
                  Go back
                </StyledButton>
              ) : (
                <StyledButton
                  onClick={() => {
                    rescheduleBookingUpdate();
                  }}
                  disabled={loading}
                >
                  Reschedule Booking
                </StyledButton>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : onlinePayFailed ? (
        <Box sx={{ flexGrow: 1 }}>
          <Card sx={{ borderRadius: "16px", m: 1 }}>
            <Grid item xs={12} textAlign="center">
              <ReportProblemIcon
                color="warning"
                style={{ fontSize: "60px", marginTop: "20px" }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="h5" variant="h5">
                Payment unsuccessful
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="p">
                Oops! your payment was unsuccessful.
                <br /> please retry the payemnt to avoid cancelling your
                booking.
              </Typography>

              <p style={{ color: "red" }}>
                <b>Note:</b> Booking will auto-cancel in 10 mins if not paid.
              </p>
            </Grid>
          </Card>

          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={6} textAlign="center">
              <StyledButtonLight onClick={() => window.location.reload()}>
                Exit
              </StyledButtonLight>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <StyledButton
                onClick={() => {
                  handlePayment(bookingCreated);
                }}
                disabled={loading}
              >
                Retry
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={10}>
              <Typography component="p">
                Collection Slot:
                <span style={{ color: "#E06358" }}>
                  {" "}
                  {moment(collectionDate).format("DD-MMM-YYYY")} |{" "}
                  {collectionSlot?.id
                    ? `${
                        collectionSlot?.format_12_hrs?.start_time
                          ? collectionSlot?.format_12_hrs?.start_time
                          : collectionSlot?.start_time_12hr
                      } - ${
                        collectionSlot?.format_12_hrs?.end_time
                          ? collectionSlot?.format_12_hrs?.end_time
                          : collectionSlot?.end_time_12hr
                      }`
                    : ""}
                </span>
              </Typography>
            </Grid>
            {!bookingCreated?.pk && (
              <Grid
                item
                xs={2}
                style={{ color: "rgba(38, 119, 240, 0.77)", cursor: "pointer" }}
                textAlign="right"
                onClick={() => {
                  setOpenCouponModal(false);
                  setOpenSlotModal(true);
                }}
              >
                edit
              </Grid>
            )}
            <Grid item xs={10}>
              {type === "rad" ? (
                <>
                  {" "}
                  <Typography component="p">
                    Centre Name:
                    <span style={{ color: "#E06358" }}>
                      {" "}
                      {centreName?.center?.name}
                    </span>
                  </Typography>
                  <Typography component="p">
                    Centre Address:
                    <span style={{ color: "#E06358" }}>
                      {" "}
                      {centreName?.address}
                    </span>
                  </Typography>
                </>
              ) : (
                <Typography component="p">
                  Address:
                  <span style={{ color: "#E06358" }}>{addressInput}</span>
                </Typography>
              )}
            </Grid>
            {!bookingCreated?.pk && (
              <Grid
                item
                xs={2}
                style={{ color: "rgba(38, 119, 240, 0.77)", cursor: "pointer" }}
                textAlign="right"
                onClick={() => {
                  setOpenCouponModal(false);
                  type !== "rad" && setLocationOpen(true);
                }}
              >
                edit
              </Grid>
            )}
          </Grid>
          <PatientInfo
            name={customerName}
            age={customerAge}
            gender={customerGender}
            packages={customerPackages}
            variant={"card"}
            member={0}
            onClick={""}
          />
          {customerDetails &&
            customerDetails.length > 0 &&
            customerDetails.map((val: any, index: any) => {
              return (
                <PatientInfo
                  name={val.customerName}
                  age={val.customerAge}
                  gender={val.customerGender}
                  packages={val.packages}
                  variant={"card"}
                  member={0}
                  onClick={""}
                />
              );
            })}

          {centreData.can_create_coupon && (
            <>
              <Grid container paddingLeft={"15px"}>
                <Grid item xs={12}>
                  <Typography component="p">Got Discount Code?</Typography>
                </Grid>
              </Grid>
              <Card sx={{ borderRadius: "16px", m: 1 }}>
                <Grid container spacing={2} className="dialogContent">
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      placeholder="Enter Coupon"
                      variant="standard"
                      value={couponCode}
                      onChange={(e) =>
                        setCouponCode(String(e.target.value).trim())
                      }
                      disabled={coupon !== ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {coupon === "" && couponCode && (
                              <CancelOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => setCouponCode("")}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {coupon ? (
                      <StyledButton
                        onClick={handleRemove}
                        disabled={bookingCreated?.pk}
                      >
                        Remove
                      </StyledButton>
                    ) : (
                      <StyledButton onClick={handleApply}>Apply</StyledButton>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {couponMinAmt > subTotal &&
                      couponCode ===
                        (couponData &&
                          couponData.length > 0 &&
                          couponData[0].code) && (
                        <div
                          style={{
                            fontSize: "14px",
                            border: "1px solid red",
                            padding: "5px",
                            borderRadius: "5px",
                            background: "#ff00000f",
                          }}
                        >
                          Add <b>₹{couponMinAmt - subTotal}</b> value of
                          test/package(s) to avail{" "}
                          {couponData[0]?.discount_type === "percentage"
                            ? `${couponData[0]?.discount}%`
                            : `₹${couponData[0]?.discount}`}{" "}
                          Off on the booking amount.
                        </div>
                      )}
                    {/* {
                        couponCode&&couponData &&
                        couponData.length === 0 ? <div
                        style={{
                          fontSize: "14px",
                          border: "1px solid red",
                          padding: "5px",
                          borderRadius: "5px",
                          background: "#ff00000f",
                        }}
                      >Not a valid coupon</div>:""
                      } */}
                  </Grid>
                </Grid>
              </Card>
            </>
          )}
          {centreData.custom_user_requirement_for === "HITACHI" ||
          centreData.custom_user_requirement_for === "EISAI" ||
          centreData.custom_user_requirement_for === "RSTAR" ||
          centreData.custom_user_requirement_for === "RECI" ? (
            ""
          ) : (
            <Card
              sx={{ borderRadius: "16px", m: 1 }}
              key={couponCalc?.final_total_price}
            >
              <Grid container spacing={2} className="dialogContent">
                {
                centreData?.show_golive_summary_price ? (
                  <>
                    {" "}
                    <Grid item xs={9}>
                      <Typography component="p">Sub Total</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      {`₹ ${subTotal}`}
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                <Grid item xs={9}>
                  <Typography component="p">Collection Charges</Typography>
                </Grid>
                <Grid item xs={3} textAlign="right">
                  {type !== "rad" &&
                  partnerKey.ced_minimum_order_charges > subTotal
                    ? `₹ ${partnerKey.ced_collection_charges}`
                    : "₹ 0"}
                </Grid>
                <Grid item xs={9}>
                  <Typography component="p">Discount</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography component="p" textAlign="right">
                    {`₹ ${
                      centreData?.has_partner_user_detail &&
                      centreData?.payment_mode === "PRE" &&
                      centreData?.tab_type === "PATA"
                        ? subTotal
                        : discount
                    }`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                {centreData?.show_golive_summary_price ? (
                  <>
                    {" "}
                    <Grid item xs={9} style={{ color: "#5866E0" }}>
                      <Typography component="p">Total Amount to pay</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        component="p"
                        textAlign="right"
                        style={{ color: "#5866E0", fontWeight: "bolder" }}
                      >
                        {`₹ ${
                          subTotal -
                          (centreData?.has_partner_user_detail &&
                          centreData?.payment_mode === "PRE"
                            ? subTotal +
                              (type !== "rad" &&
                              partnerKey.ced_minimum_order_charges > subTotal
                                ? partnerKey.ced_collection_charges
                                : 0)
                            : discount) +
                          (type !== "rad" &&
                          partnerKey.ced_minimum_order_charges > subTotal
                            ? partnerKey.ced_collection_charges
                            : 0)
                        }`}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                <Grid item xs={12}>
                  {centreData.custom_user_requirement_for === "AON" ? (
                    <sub style={{ color: "green" }}>
                      *Inclusive of All Taxes
                    </sub>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Card>
          )}
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <FormControlLabel
                className="agreed"
                control={<Checkbox name="agree" checked={agree} />}
                onChange={() => setAgree(!agree)}
                label="You hereby affirm & authorize Redcliffe Labs to process the personal data as per the Privacy Policy."
              />
            </Grid>
            {agree && (
              <>
                {centreData?.is_subscription === true ||
                company === "HITACHI" ||
                company === "EISAI" ||
                company === "RECI" ||
                company === "EVAHEL" ||
                Number(subTotal) - Number(discount) < 1 ? (
                  <Grid item xs={12}>
                    <StyledButton
                      onClick={() => {
                        submitBooking(true, "");
                      }}
                      disabled={loading}
                    >
                      CONFIRM BOOKING
                    </StyledButton>
                  </Grid>
                ) : (
                  <>
                    {(centreData.payment_mode !== "POST" ||
                      company === "AON") && (
                      <Grid item xs={12}>
                        {bookingCreated?.pk && onlinePay ? (
                          <StyledButton
                            onClick={() => {
                              handlePayment(bookingCreated);
                            }}
                            disabled={loading}
                          >
                            PAY NOW
                          </StyledButton>
                        ) : (
                          <StyledButton
                            onClick={() => {
                              submitBooking(false, "online");
                              setOnlinePay(true);
                            }}
                            disabled={loading}
                          >
                            PAY NOW
                          </StyledButton>
                        )}
                      </Grid>
                    )}

                    {centreData.payment_mode !== "PRE" && type !== "rad" && (
                      <Grid item xs={12}>
                        {bookingCreated?.pk ? (
                          <StyledButtonLight
                            onClick={() => {
                              updateCredit(bookingCreated?.pk);
                            }}
                            disabled={loading}
                          >
                            PAY AT DOORSTEP
                          </StyledButtonLight>
                        ) : (
                          <StyledButtonLight
                            onClick={() => {
                              submitBooking(false, "");
                            }}
                            disabled={loading}
                          >
                            PAY AT DOORSTEP
                          </StyledButtonLight>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      )}
      {centreData.custom_user_requirement_for === "ACCENTURE" ||
      centreData.custom_user_requirement_for === "BOB" ? (
        <Grid
          item
          xs={12}
          textAlign={"center"}
          style={{ marginBottom: "10px" }}
        >
          <hr style={{ border: "1px solid #ececec" }} />
          <sup>
            For any queries/concerns, please write us at
            <a href="mailto:appointments@redcliffelabs.com">
              <b> appointments@redcliffelabs.com</b>
            </a>
          </sup>
        </Grid>
      ) : (
        ""
      )}
      {centreData?.additional_summary_text ? (
        <Grid
          item
          xs={12}
          textAlign={"center"}
          style={{ marginBottom: "50px" }}
        >
          <hr style={{ border: "1px solid #ececec" }} />
          <sup>{centreData?.additional_summary_text}</sup>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default OrderSummary;
