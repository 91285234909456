import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import WebAppComponent from "../container/Web-app-Component";

function WebAppRoutes(props: any) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<WebAppComponent />} />
      </Routes>
    </BrowserRouter>
  );
}
export default connect(null, {})(WebAppRoutes);
