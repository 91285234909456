import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
interface PropsBill {
  bookingOnline: any;
  isDownload:any;
  setIsDownload:any;
}

const BillPdf: React.FC<PropsBill> = ({ bookingOnline,isDownload,setIsDownload}) => {
  const [data, setData] = useState<any>({
    bId: "",
    iDate: "",
    cDate: "",
    cName: "",
    cAge: "",
    cPhone: "",
    packages: [],
    totalPrice: "",
    totalDiscount: "",
    redcash_discounted_amount: "",
    giftcard_discounted_amount: "",
    centre: "",
    center_address: "",
    center_phone: "",
    center_gst:"",
    payment: [],
  });
  const [addPkg, setAddPkg] = useState<any>([]);
  const ref = useRef<any>(null);
  const price_in_words = (price: any) => {
    let sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt: number, prevDgt: number) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    let str = "";
    let digitIdx: number = 0;
    let digit: number;
    let nxtDigit: number = 0;
    let words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
          ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  };

  useEffect(() => {
    if (Object.keys(bookingOnline).length !== 0) {
      setData({
        bId:
          bookingOnline?.id === undefined
            ? bookingOnline?.pk
            : bookingOnline?.id,
        iDate: moment(bookingOnline?.created_at).format("DD MMM,YYYY"),

        cAge: bookingOnline?.customer_age,
        cName: bookingOnline?.designation + " " + bookingOnline?.customer_name,
        cPhone: bookingOnline?.customer_phonenumber,
        packages: bookingOnline?.packages,
        centre: bookingOnline?.center?.name,
        center_address: bookingOnline?.center?.address,
        center_phone: bookingOnline?.center?.mobile,
        center_gst: bookingOnline?.center?.gstin,
        totalPrice:
          bookingOnline.discounted_price &&
          bookingOnline.discounted_price.final_total_price &&
          Math.floor(bookingOnline.discounted_price.final_total_price) -
            Math.floor(bookingOnline.redcash_discounted_amount) -
            Math.floor(bookingOnline?.giftcard_discounted_amount),
        totalDiscount: bookingOnline?.discounted_price?.counpon_discount,
        redcash_discounted_amount: bookingOnline?.redcash_discounted_amount,
        giftcard_discounted_amount: bookingOnline?.giftcard_discounted_amount,
        paymentMode: bookingOnline?.is_credit?"Prepaid":"COD",
      });
    }
  }, [bookingOnline]);
  useEffect(() => {
    if (bookingOnline && bookingOnline?.additional_members?.length > 0) {
      const array: any = [];
      bookingOnline?.additional_members.map((data: any) => {
        data.packages.map((val: any) => {
          array.push(val);
        });
      });
      setAddPkg(array);
    }
  }, [bookingOnline]);

  const generatePDF = () => {
    const input = ref.current;
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "px", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };
useEffect(() => {
  if(isDownload){
    generatePDF()
    setIsDownload(false)
  }
}, [isDownload])

  return (
    <div
      ref={ref}
      className="invoiceContainer"
      style={{
        fontFamily: "sans-serif",
        padding: 15,
        marginTop: 0,
        width: "794px",
        height: "1123px",
      }}
    >
      <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
        <hr
          className="headerLine"
          style={{
            border: "5px solid #47cacc",
            fontFamily: "sans-serif",
            width: "100%",
          }}
        />
        <div
          className="logoContainer"
          style={{
            display: "flex",
            padding: "1rem",
          }}
        >
          <div style={{ width: "50%" }}>
            <span
              className="invoiceHeading"
              style={{
                float: "left",
                fontFamily: "sans-serif",
                fontSize: "14px",
              }}
            >
              <strong style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                {data?.centre}
              </strong>
              <br />
              Ph : {data?.center_phone}
              <br />
              Address : {data?.center_address}
              <br />
              GST No : {data?.center_gst}
            </span>
          </div>
          <div style={{ width: "50%" }}>
            <strong style={{ fontSize: "32px", float: "right" }}>
              RECEIPT
            </strong>
          </div>
        </div>
        <hr style={{ fontFamily: "sans-serif" }} />
        <div
          className="invoiceGrid"
          style={{ marginLeft: "15px", display: "flex" }}
        >
          <div style={{ width: "75%" }}>
            <span>
              Bill to: <b>{data?.cName}</b>
            </span>
            <br />
            <span>Ph: {data?.cPhone}</span>
            <br />
          </div>
          <div style={{ width: "25%" }}>
            <span>Bill Date: {data?.iDate}</span>
            <br />
            <span>Receipt No: {data?.bId}</span>
            <br />
          </div>
        </div>
        <hr style={{ clear: "left", fontFamily: "sans-serif" }} />

        <div style={{ fontFamily: "sans-serif" }}>
          <div
            className="invoiceTestDescHead"
            style={{
              backgroundColor: "#f4f4f4",
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: 600,

              padding: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <div
              style={{
                float: "left",
                fontFamily: "sans-serif",

                width: "70%",
              }}
            >
              TEST NAME
            </div>
            <div
              style={{
                float: "right",
                textAlign: "right",
                fontFamily: "sans-serif",
                width: "13%",
              }}
            >
              AMOUNT
            </div>
          </div>
          {data?.packages.length > 0 &&
            data?.packages.map((val: any) => (
              <div
                className="billDetailsTests"
                style={{
                  color: "black !important",
                  fontFamily: "sans-serif",
                  fontSize: "16px",
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    float: "left",
                    fontFamily: "sans-serif",
                    width: "70%",
                  }}
                >
                  <strong style={{ fontFamily: "sans-serif" }}>
                    {val?.name}
                  </strong>
                </div>
                <div
                  style={{
                    float: "right",
                    textAlign: "right",
                    fontFamily: "sans-serif",
                    width: "13%",
                  }}
                >
                  {bookingOnline &&
                  bookingOnline?.center &&
                  bookingOnline?.center?.center_type === "corporate" ? (
                    <strong style={{ fontFamily: "sans-serif" }}>
                      ₹{val.price}
                    </strong>
                  ) : (
                    <strong style={{ fontFamily: "sans-serif" }}>
                      ₹
                      {val.package_city_prices
                        ? val.package_city_prices.offer_price
                        : val.offer_price}
                    </strong>
                  )}
                </div>
              </div>
            ))}
          {addPkg.length > 0 &&
            addPkg.map((val: any) => (
              <div
                className="billDetailsTests"
                style={{
                  color: "black !important",
                  fontFamily: "sans-serif",
                  fontSize: "16px",
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    float: "left",
                    fontFamily: "sans-serif",
                    width: "70%",
                  }}
                >
                  <strong style={{ fontFamily: "sans-serif" }}>
                    {val?.name}
                  </strong>
                </div>
                <div
                  style={{
                    float: "right",
                    textAlign: "right",
                    fontFamily: "sans-serif",
                    width: "13%",
                  }}
                >
                  {
                    <strong style={{ fontFamily: "sans-serif" }}>
                      ₹
                      {val.package_city_prices
                        ? val.package_city_prices.offer_price
                        : val.offer_price}
                    </strong>
                  }
                </div>
              </div>
            ))}
          <div>
            <div
              className="invoiceTestDescTotal"
              style={{
                backgroundColor: "#fbfbfb",
                fontFamily: "sans-serif",
                fontSize: "16px",
                padding: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                Discount
              </div>
              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                ₹{data?.totalDiscount}
              </div>
            </div>
            <div
              className="invoiceTestDescTotal"
              style={{
                backgroundColor: "#fbfbfb",
                fontFamily: "sans-serif",
                fontSize: "16px",
                padding: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                Gift Card
              </div>
              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                ₹{data?.giftcard_discounted_amount}
              </div>
            </div>
            <div
              className="invoiceTestDescTotal"
              style={{
                backgroundColor: "#fbfbfb",
                fontFamily: "sans-serif",
                fontSize: "16px",
                padding: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                Red Cash
              </div>
              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                ₹{data?.redcash_discounted_amount}
              </div>
            </div>
            <div
              className="invoiceTestDescTotal"
              style={{
                backgroundColor: "#fbfbfb",
                fontFamily: "sans-serif",
                fontSize: "16px",
                padding: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div style={{ float: "left", fontFamily: "sans-serif" }}>
                Total Amount
              </div>
              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                ₹{data?.totalPrice}
              </div>
            </div>
          </div>
          <hr style={{ fontFamily: "sans-serif" }} />

          <div
            className="invoiceTestDescTotal2"
            style={{
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: 600,
              padding: "1rem",
            }}
          >
            <div style={{ float: "left", fontFamily: "sans-serif" }}>
              Paid Amount
            </div>
            <div style={{ float: "right", fontFamily: "sans-serif" }}>
              ₹{data?.totalPrice}
            </div>
          </div>
        </div>
        <div
          className="invoiceSummary"
          style={{
            fontFamily: "sans-serif",
            fontSize: "13px",
            lineHeight: "1.4",
            marginTop: "3rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginBottom: "11rem",
          }}
        >
          <div
            style={{
              float: "left",
              fontFamily: "sans-serif",
              width: "50%",
            }}
          >
            <strong style={{ fontFamily: "sans-serif" }}>
              Amount Chargable (in words)
            </strong>
            <div style={{ fontFamily: "sans-serif" }}>
              <i style={{ fontFamily: "sans-serif" }}>
                Rupees
                {price_in_words(data?.totalPrice)}
                Only
              </i>
            </div>
            {/* <div
              className="blueColor"
              style={{
                color: "#11406b",
                fontFamily: "sans-serif",
                fontSize: "15px",
              }}
            >
              Company's PAN No.{" "}
              <span style={{ fontFamily: "sans-serif" }}>AAKCR7631M</span>
            </div> */}
          </div>
          <div
            className="paymentSummary"
            style={{
              float: "right",
              fontFamily: "sans-serif",
              textAlign: "end",
              width: "50%",
            }}
          >
            <strong style={{ fontFamily: "sans-serif" }}>
              Payment Mode Summary
            </strong>
            <table
              className="invoiceSummaryTable"
              style={{
                borderCollapse: "collapse",
                fontFamily: "sans-serif",
                fontSize: "12px",
                marginTop: "1rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              <tbody>
                <tr
                  style={{
                    backgroundColor: "#f1f1f1",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                      paddingRight: "1.6rem",
                    }}
                  >
                    Payment Date
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                      paddingRight: "1.6rem",
                    }}
                  >
                    Payment Mode
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                    }}
                  >
                    Amount
                  </td>
                </tr>

                <tr
                  style={{
                    backgroundColor: "#f9f9f9",
                    fontFamily: "sans-serif",
                  }}
                >
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                      paddingRight: "1.6rem",
                    }}
                  >
                    {moment(data?.iDate).format("DD MMM,YYYY")}
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                      paddingRight: "1.6rem",
                    }}
                  >
                    {data?.paymentMode}
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      padding: ".6rem",
                    }}
                  >
                    ₹{data?.totalPrice}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ fontFamily: "sans-serif" }} />
          </div>
        </div>

        <div
          className="invoiceFooter"
          style={{
            backgroundColor: "#47cacc",
            color: "#0a2d4e",
            fontFamily: "sans-serif",
            fontSize: "16px",
            paddingBottom: "2.9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default BillPdf;
