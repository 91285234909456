import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Card, Box, Typography, Container } from "@mui/material";
import "./index.sass";
import moment from "moment";
import Header from "../../components/Header";
import StyledButton from "../../components/Button/StyledButton";
import confirm from "../../images/confirm.png";
import cancelled from "../../images/cancel.svg";
import StyledButtonLight from "../../components/Button/StyledButtonLight";
interface Props {
  setOpenConfirmedModal: any;
  setConfirmType: any;
  confirmType: any;
  setOpenTracking: any;
  setReschedule: any;
  reschedule: any;
  cancel: any;
  type: any;
}

const ConfirmationPage: React.FC<Props> = ({
  setOpenConfirmedModal,
  setConfirmType,
  confirmType,
  setOpenTracking,
  setReschedule,
  reschedule,
  cancel,
  type,
}) => {
  const bookingCreated = useSelector(
    (state: any) => state.webAppSlice.bookingCreated
  );
  const bookAgain = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    params.delete("tid");
    let finalUrl = "?" + String(params);
    window.open(finalUrl, "_self");
  };

  return (
    <Grid className="content" id="collectionSlot">
      <Header isFilter={false} />
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12} textAlign="center">
              <img src={cancel ? cancelled : confirm} />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="h5" variant="h5">
                {confirmType === "uploadPrescription"
                  ? "Your Prescription have been uploaded"
                  : confirmType === "requestCallback"
                  ? "Call Back have been Scheduled"
                  : cancel
                  ? "Booking Cancelled"
                  : reschedule
                  ? "Order Rescheduled Successfully"
                  : "Order Booked Successful"}
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography component="p">
                {type === "rad"
                  ? "Your appointment have been booked successfully. Our team will reach out to you for sharing confirmation of center visit."
                  : confirmType === "uploadPrescription"
                  ? "You will receive a callback from us within 24 hours."
                  : confirmType === "requestCallback"
                  ? "Your request has been recorded. One of our executives will reach back to you within next 24 hours."
                  : cancel
                  ? "Your booking has been cancelled."
                  : reschedule
                  ? "Your order have been rescheduled successfully. Our phlebotomist will be at your doorsteps on selected date & time."
                  : "Your order have been booked successfully. Our phlebotomist will be at your doorsteps on selected date & time."}
              </Typography>
              {cancel ? (
                <p>
                  <b>Note:</b> If any amount has been paid against your booking
                  id, it will be refunded back within next 24-48 hours.
                </p>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Card>
        {confirmType === "uploadPrescription" ||
        confirmType === "requestCallback" ? (
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12} textAlign="center">
              <StyledButton onClick={() => window.location.reload()}>
                Take me to Booking Page
              </StyledButton>
            </Grid>
          </Grid>
        ) : cancel ? (
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12} textAlign="center">
              <StyledButton onClick={() => bookAgain()}>
                Book Again
              </StyledButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} className="dialogContent">
            {/* <Grid item xs={6} textAlign="center">
              <StyledButtonLight onClick={() => setOpenConfirmedModal(false)}>
                Unlock Rewards
              </StyledButtonLight>
            </Grid> */}
            <Grid item xs={12} textAlign="center">
              {type !== "rad" && (
                <StyledButton
                  onClick={() => {
                    reschedule
                      ? window.location.reload()
                      : window.open(
                          window.location.href +
                            `&tid=${bookingCreated.pk || bookingCreated.id}`,
                          "_self"
                        );
                    //@ts-ignore
                    gtag("event", "track_status_btn", {
                      booking_id: bookingCreated.pk || bookingCreated.id,
                      user_pk: bookingCreated.pk || bookingCreated.id,
                    });
                  }}
                >
                  Track Status
                </StyledButton>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default ConfirmationPage;
