import React, { useEffect, useRef, useState } from "react";
import "./index.sass";
import StyledButton from "../../components/Button/StyledButton";
import Header from "../../components/Header";
import { getSubCenterInfo } from "../../services/actionSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  InputAdornment,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { AppDispatch } from "../../storage/store";
import Loc from "../../images/loc.svg";
import Distance from "../../images/distance.svg";

interface Props {
  setCustomerName: any;
  customerName: any;
  setCustomerEmail: any;
  customerEmail: any;
  setCustomerAge: any;
  customerAge: any;
  setCustomerGender: any;
  customerGender: any;
  setCustomerNumber: any;
  customerNumber: any;
  setCustomerPackages: any;
  customerPackages: any;
  setFullName: any;
  fullName: any;
  partnerKey: any;
  setOpenSlotModal: any;
  setCentreName: any;
  centreName: any;
  setRemarks: any;
  remarks: any;
  setFile: any;
  file: any;
  setAddIndex: any;
  setAccOpenPackageModal: any;
  latitude: any;
  longitude: any;
  cityName: any;
  setRelation: any;
  relation: any;
  addressInput: any;
  setLocationOpen: any;
}
const RadiologyBooking: React.FC<Props> = ({
  setCustomerName,
  customerName,
  setCustomerEmail,
  customerEmail,
  setCustomerAge,
  customerAge,
  setCustomerGender,
  customerGender,
  setCustomerNumber,
  customerNumber,
  setCustomerPackages,
  customerPackages,
  setFullName,
  fullName,
  partnerKey,
  setOpenSlotModal,
  setCentreName,
  centreName,
  setRemarks,
  remarks,
  setFile,
  file,
  setAddIndex,
  setAccOpenPackageModal,
  latitude,
  longitude,
  cityName,
  setRelation,
  relation,
  addressInput,
  setLocationOpen,
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const timer = useRef<any>(0);
  const subCenterData = useSelector(
    (state: any) => state.webAppSlice.subCenterData
  );
  const centreData = useSelector((state: any) => state.webAppSlice.centreByKey);

  const company = centreData.custom_user_requirement_for;

  const [mobileValidation, setMobileValidation] = useState<any>(true);
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [expand, setExpand] = useState<any>(true);
  const [ageValidation, setAgeValidation] = useState<any>(true);
  const [genderValidation, setGenderValidation] = useState<any>(true);

  const [preview, setPreview] = useState<any>("");

  const [emailValidation, setEmailValidation] = useState<any>(true);

  //link queries

  const verifyEmail = () => {
    const nameRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (customerEmail && !nameRegex.test(customerEmail.toString())) {
      setEmailValidation(false);
    }
  };

  const handlePreview = () => {
    if (file?.type === "application/pdf") {
      let link = URL.createObjectURL(file);
      window.open(link, "_blank");
    } else {
      setPreview(URL.createObjectURL(file));
    }
  };

  const verifyName = () => {
    const nameRegex = /^([a-zA-Z ]){2,30}$/;
    if (customerName && !nameRegex.test(customerName.toString())) {
      setNameValidation(false);
    }
  };

  const verifyAge = () => {
    if (customerAge < 5) {
      setAgeValidation(false);
    }
  };
  const blockInvalidChar = (e: React.KeyboardEvent<HTMLDivElement>) =>
    ["e", "E", "."].includes(e.key) && e.preventDefault();

  const verifyMobile = () => {
    const mobileRegEx = /^[6-9]/;

    if (
      (customerNumber &&
        customerNumber.length > 1 &&
        customerNumber.length < 10) ||
      (customerNumber && !mobileRegEx.test(customerNumber.toString()))
    ) {
      setMobileValidation(false);
    } else {
      setMobileValidation(true);
    }
  };
  useEffect(() => {
    if (latitude && longitude && customerPackages.length > 0 && cityName) {
      dispatch(
        getSubCenterInfo({
          url: `?imaging=true&customer_latitude=${latitude}&customer_longitude=${longitude}&package=${
            customerPackages && customerPackages.length > 0
              ? customerPackages.map((val: any) => val.id)
              : ""
          }&multiple_city=${cityName ? cityName : ""}`,
          key: partnerKey?.center_key,
        })
      );
    }
    if(  customerPackages?.length > 0 &&
      customerPackages[0].recommended_gender !== null &&
      customerPackages[0].recommended_gender !== "both"){
        setCustomerGender( customerPackages[0].recommended_gender)
      }
  }, [latitude, customerPackages, cityName]);

  return (
    <>
      <Header
        isFilter={false}
        addressInput={addressInput}
        setLocationOpen={setLocationOpen}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={8}>
              <Typography component="p">YOUR DETAILS</Typography>
            </Grid>

            <>
              {company === "ACCENTURE" || company === "BOB" ? (
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    color="primary"
                    className="input"
                    label="Relationship"
                    key={relation}
                    onChange={(e) => setRelation(e.target.value)}
                    value={relation}
                  >
                    <MenuItem value="self">Self</MenuItem>
                    <MenuItem value="spouse">Spouse</MenuItem>
                    <MenuItem value="son_in_law">Son</MenuItem>
                    <MenuItem value="daughter_in_law">Daughter</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>
              ) : company === "HITACHI" ||
                company === "EISAI" ||
                company === "EVAHEL" ||
                company === "AON" ? (
                ""
              ) : (
                ""
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  color="primary"
                  className="input"
                  id="outlined-required"
                  label="Name"
                  value={customerName}
                  required
                  disabled={company === "RECI"}
                  onBlur={() => verifyName()}
                  onFocus={() => {
                    setNameValidation(true);
                  }}
                  onChange={(e) =>
                    setCustomerName(String(e.target.value).trimStart())
                  }
                />
                <sub style={{ color: "red" }}>
                  {!nameValidation
                    ? "Please enter valid name. Remove any special character & number. Name should have 2 to 30 alphabetical characters"
                    : ""}
                </sub>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Mobile Number"
                  value={customerNumber}
                  variant="outlined"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onBlur={() => verifyMobile()}
                  onFocus={() => {
                    setMobileValidation(true);
                  }}
                  fullWidth
                  required
                  onChange={(e) => setCustomerNumber(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
                <sub style={{ color: "red" }}>
                  {!mobileValidation ? "Please enter valid mobile number" : ""}
                </sub>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  label="Email Address"
                  value={customerEmail}
                  disabled
                  onBlur={() => verifyEmail()}
                  onFocus={() => {
                    setEmailValidation(true);
                  }}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <sub style={{ color: "red" }}>
                  {!emailValidation ? "Please enter valid e-mail id" : ""}
                </sub>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  color="primary"
                  className="input"
                  id="outlined-required"
                  label="Age"
                  type="number"
                  value={customerAge}
                  onBlur={() => verifyAge()}
                  onFocus={() => {
                    setAgeValidation(true);
                  }}
                  onKeyDown={(e) => blockInvalidChar(e)}
                  variant="outlined"
                  required
                  onChange={(e) =>
                    Number(e.target.value) < 110
                      ? setCustomerAge(e.target.value)
                      : ""
                  }
                />
                <sub style={{ color: "red" }}>
                  {!ageValidation
                    ? "Please enter valid age, age should be 4+ Years"
                    : ""}
                </sub>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  color="primary"
                  className="input"
                  id="outlined-required"
                  label="Gender"
                  disabled={
                    customerPackages?.length > 0 &&
                    customerPackages[0].recommended_gender !== null &&
                    customerPackages[0].recommended_gender !== "both"
                  }
                  required
                  select
                  key={customerGender}
                  value={customerGender}
                  onChange={(e) => setCustomerGender(e.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
                <sub style={{ color: "red" }}>
                  {!genderValidation && !customerGender
                    ? "Please select gender"
                    : ""}
                </sub>
              </Grid>

              {customerPackages &&
                customerPackages.length > 0 &&
                customerPackages.map((val: any, index: any) => {
                  return (
                    <Grid item xs={12}>
                      <div
                        style={{
                          background: "rgba(209, 64, 72, 0.2)",
                          borderRadius: "5px",
                          color: "#E06358",
                          padding: "5px 0px 1px 14px",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "90%" }}>{val?.name}</div>
                        <div style={{ width: "10%" }}></div>
                      </div>
                    </Grid>
                  );
                })}
              <Grid item xs={12}>
                <StyledButton
                  onClick={() => {
                    setAddIndex("");
                    setAccOpenPackageModal(true);
                  }}
                >
                  CHANGE PACKAGE
                </StyledButton>
              </Grid>
            </>
          </Grid>
        </Card>
        <Card sx={{ borderRadius: "16px", m: 1 }}>
          <Grid container spacing={2} className="dialogContent">
            <Grid item xs={12}>
              <Autocomplete
                id="sub_center"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCentreName(obj);
                  }
                }}
                options={subCenterData.length > 0 ? subCenterData : []}
                value={centreName}
                style={{ width: "100%" }}
                aria-required
                getOptionLabel={(option: any) =>
                  option?.center?.name
                    ? `Center: ${option?.center?.name}, Address: ${option?.address}`
                    : ""
                }
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      padding: 10,
                      borderBottom: "0.6px solid #F3F3F3",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "10%" }}>
                      <img src={Loc} style={{ marginTop: 3, width: "16px" }} />
                    </div>
                    <div style={{ width: "60%", lineHeight: "12px" }}>
                      {" "}
                      <p style={{ margin: 0 }}>{option?.center?.name}</p>
                      <sub style={{ fontSize: 11 }}>{option?.address}</sub>
                      <br />
                      <sub style={{ color: "green" }}>
                        {option?.additional_detail &&
                        centreData?.id === "3p_G9sNcunPgbRRj8pTYEKD"
                          ? "*" + option?.additional_detail
                          : ""}
                      </sub>
                    </div>

                    <div style={{ width: "30%", textAlign: "right" }}>
                      <img src={Distance} width="25px" />
                      <sup>
                        {option?.distance
                          ? `${Math.trunc(option?.distance)} KM`
                          : ""}
                      </sup>{" "}
                    </div>
                  </li>
                )}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    const searchTerm = "Address:";
                    const indexOfFirst = newInputValue.indexOf(searchTerm);
                    let str = newInputValue.substring(7, indexOfFirst);
                    dispatch(
                      getSubCenterInfo({
                        url: `?imaging=true&name=${newInputValue}&customer_latitude=${latitude}&customer_longitude=${longitude}&package=${
                          customerPackages && customerPackages.length > 0
                            ? customerPackages.map((val: any) => val.id)
                            : ""
                        }&multiple_city=${cityName ? cityName : ""}`,
                        key: partnerKey?.center_key,
                      })
                    );
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Center"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
                disabled={customerPackages.length === 0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Additional Remarks (If any)"
                fullWidth
                onChange={(e) => setRemarks(String(e.target.value).trimStart())}
                value={remarks}
              />
            </Grid>

            {/* {file !== "" ? (
              <>
                {preview !== "" ? (
                  <>
                    <Grid item xs={8}>
                      Preview
                    </Grid>
                    <Grid item xs={4}>
                      <StyledButtonLight onClick={() => setPreview("")}>
                        Back
                      </StyledButtonLight>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <img src={preview} style={{ width: "100%" }} />
                      <span>{file?.name}</span>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      Uploaded Files
                    </Grid>
                    <Grid item xs={9}>
                      <div
                        style={{
                          background: "#20B0384F",
                          padding: 5,
                          display: "flex",
                          height: "25px",
                        }}
                      >
                        <span style={{ width: "90%" }}>
                          {String(file?.name).substring(0, 22)}...
                        </span>
                        <span
                          style={{
                            width: "10%",
                            color: "#20B038",
                            cursor: "pointer",
                          }}
                          onClick={() => setFile("")}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={3} textAlign={"right"}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handlePreview()}
                        fullWidth
                        style={{ height: 35 }}
                      >
                        Preview
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <div
                  style={{
                    borderRadius: 6,
                    background: "#E0635820",
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  Upload your prescription
                  <StyledButtonLight style={{ marginTop: 5 }}>
                    <input
                      style={{ display: "none" }}
                      id={"fileUp"}
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, application/pdf"
                      onChange={(e) =>
                        setFile(
                          e.target.files && e.target.files.length > 0
                            ? e.target.files[0]
                            : ""
                        )
                      }
                    />
                    <label
                      htmlFor="fileUp"
                      style={{ display: "flex", cursor: "pointer" }}
                    >
                      <div style={{ width: "40%", textAlign: "right" }}>
                        <FileUploadOutlinedIcon style={{ marginTop: "-3px" }} />
                      </div>
                      <div style={{ width: "60%", textAlign: "left" }}>
                        UPLOAD
                      </div>
                    </label>
                  </StyledButtonLight>
                </div>
              </Grid>
            )} */}
          </Grid>
        </Card>
        <div style={{ margin: "0 15px 15px" }}>
          <StyledButton
            onClick={() => setOpenSlotModal(true)}
            disabled={
              !mobileValidation ||
              !nameValidation ||
              !ageValidation ||
              customerGender === "" ||
              !customerName ||
              customerPackages.length === 0 ||
              customerNumber.length !== 10 ||
              !centreName.id
            }
          >
            NEXT
          </StyledButton>
        </div>
        {centreData.custom_user_requirement_for === "ACCENTURE" ||
        centreData.custom_user_requirement_for === "BOB" ? (
          <Grid
            item
            xs={12}
            textAlign={"center"}
            style={{ marginBottom: "10px" }}
          >
            <hr style={{ border: "1px solid #ececec" }} />
            <sup>
              For any queries/concerns, please write us at
              <a href="mailto:appointments@redcliffelabs.com">
                <b> appointments@redcliffelabs.com</b>
              </a>
            </sup>
          </Grid>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};
export default RadiologyBooking;
