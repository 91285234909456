import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


interface Props {
  val: any;
}

const SnackBar: React.FC<Props> = ({
  val,
}) => {
  const [message, setMessage] = useState<any>("");
  const [status, setStatus] = useState<any>(false);
  const [type, setType] = useState<any>("");
  useEffect(() => {
      setMessage(val?.message)
      setStatus(val?.status)
      setType(val?.type)
  }, [val])
  const handleClose = () => {
    setMessage("")
      setStatus(false)
      setType("")
  };
  return (
    <div>
      <Snackbar
        open={status}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:"bottom",horizontal:"center" }}
      >
        <Alert
          onClose={handleClose}
          severity={type || "info"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default SnackBar